import { cx } from "emotion";
import { useEffect } from "react";
import * as React from "react";
import Toast from "react-bootstrap/Toast";
import formatPhoneNumber from "../../../../../formatters/phoneFormatter";
import { CandidateEmail, CandidateInterface, candidateService } from "../../../../../screens/projects/CandidateService";
import { ButtonHTML } from "../../../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Dropdown } from "../../../../DiscoverNew/UI/Dropdown/Dropdown";
import { Icon } from "../../../../DiscoverNew/UI/IconComponent/Icon";
import { copyText } from "../../../../DiscoverNew/util/util";
import { EmailVerificationIcon } from "../../EmailVerificationIcon/EmailVerificationIcon";

import css from "./CandidateContacts.module.css";

type Props = {
  data: CandidateInterface
  style?: React.CSSProperties
}

export const CandidateContacts: React.FC<Props> = props => {
  const [openedToastId, setOpenedToastId] = React.useState('');
  const [validatedEmails, setValidatedEmails] = React.useState<Array<CandidateEmail>>([]);

  const sortedEmails = candidateService.getSortedEmails(validatedEmails.length ? validatedEmails : (props.data.emails || []));

  const onCopy = (e: any, text: string) => {
    e.stopPropagation();
    copyText(text);
    setOpenedToastId(text);
  };

  useEffect(() => {
    if (!props.data.emails?.length) {
      return;
    }
    if (props.data.isContactInfoViewable && !validatedEmails.length) {
      candidateService.getValidatedEmails(props.data.candidateId, props.data.emails).then(r => {
        setValidatedEmails(r);
      })
    }
  }, [props.data?.emails]);

  return (
    <div className={css.holder} style={props.style}>
      {sortedEmails.length ? (
        <Dropdown
          classNames={{ content : css.dropdown }}
          label={<ButtonHTML className={css.item}><Icon name='envelope-outline' /></ButtonHTML>}
        >
          {sortedEmails.map(item => {
            return <div className={css.dropdownItem} key={item.address}>
              <div>
                {props.data.isContactInfoViewable && <EmailVerificationIcon className={css.verificationIcon} status={item.status} />}
                {props.data.isContactInfoViewable ? (
                  <a href={`mailto:${item.address}`}>{item.address}</a>
                ) : item.address}
              </div>
              <div className={css.dropdownItemActions}>
                <ButtonHTML onClick={e => onCopy(e, item.address)}><Icon name='copy' /></ButtonHTML>
                  <div className={cx("contact-toast-container", css.toastContainer)}>
                    <Toast
                      onClose={() => setOpenedToastId('')}
                      show={openedToastId === item.address}
                      delay={500}
                      autohide
                      className="clipboard-success-toast-container"
                    >
                      <Toast.Body className="clipboard-success-toast-content">Copied!</Toast.Body>
                    </Toast>
                  </div>
              </div>
            </div>
          })}
        </Dropdown>
      ) : (
        <ButtonHTML disabled className={css.itemDisabled}><Icon name='envelope-outline' /></ButtonHTML>
      )}

      {props.data.phoneNumbers?.length ? (
        <Dropdown
          style={{ marginLeft: 32 }}
          classNames={{ content : css.dropdown }}
          label={<ButtonHTML className={css.item}><Icon name='phone-outline' /></ButtonHTML>}
        >
          {props.data.phoneNumbers.map(item => {
            const formattedNumber = formatPhoneNumber(item.e164);
            return <div className={css.dropdownItem} key={item.e164}>
              <div>
                {props.data.isContactInfoViewable ? (
                  <a href={`tel:${formattedNumber}`}>{formattedNumber}</a>
                ) : formattedNumber}
              </div>
              <div className={css.dropdownItemActions}>
                <ButtonHTML onClick={e => onCopy(e, formattedNumber)}><Icon name='copy' /></ButtonHTML>
                <div className={cx("contact-toast-container", css.toastContainer)}>
                  <Toast
                    onClose={() => setOpenedToastId('')}
                    show={openedToastId === formattedNumber}
                    delay={500}
                    autohide
                    className="clipboard-success-toast-container"
                  >
                    <Toast.Body className="clipboard-success-toast-content">Copied!</Toast.Body>
                  </Toast>
                </div>
              </div>
            </div>
          })}
        </Dropdown>
      ) : (
        <ButtonHTML disabled className={css.itemDisabled}><Icon name='phone-outline' /></ButtonHTML>
      )}

      {props.data.phoneNumbers?.length ? (
        <Dropdown
          style={{ marginLeft: 32 }}
          classNames={{ content : css.dropdown }}
          label={<ButtonHTML className={css.item}><Icon name='sms-outline' /></ButtonHTML>}
        >
          {props.data.phoneNumbers.map(item => {
            const formattedNumber = formatPhoneNumber(item.e164);
            return <div className={css.dropdownItem} key={item.e164}>
              <div>
                {props.data.isContactInfoViewable ? (
                  <a href={`sms:${formattedNumber}`}>{formattedNumber}</a>
                ) : formattedNumber}
              </div>
              <div className={css.dropdownItemActions}>
                <ButtonHTML onClick={e => onCopy(e, formattedNumber)}><Icon name='copy' /></ButtonHTML>
                <div className={cx("contact-toast-container", css.toastContainer)}>
                  <Toast
                    onClose={() => setOpenedToastId('')}
                    show={openedToastId === formattedNumber}
                    delay={500}
                    autohide
                    className="clipboard-success-toast-container"
                  >
                    <Toast.Body className="clipboard-success-toast-content">Copied!</Toast.Body>
                  </Toast>
                </div>
              </div>
            </div>
          })}
        </Dropdown>
      ) : (
        <ButtonHTML disabled className={css.itemDisabled}><Icon name='sms-outline' /></ButtonHTML>
      )}

    </div>
  );
};