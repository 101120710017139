import * as React from "react";
import { Link as CustomLink } from "../../../components/DiscoverNew/UI/Link/Link";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import Table from "../../../components/project/Table/Table";
import { PaginationFull } from "../../../components/DiscoverNew/UI/PaginationFull/PaginationFull";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { UrlHelper } from "../../../components/DiscoverNew/util/UrlHelper";
import { omit, pick } from "../../../components/DiscoverNew/util/util";
import { debounce } from "lodash";
import { projectService } from "../../projects/ProjectService";
import { userService } from "../../projects/UserService";
import { AdminUserListFilters } from "./AdminUserListFIlters/AdminUserListFilters";
import UserModal from "../../../components/modals/UserModal";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";

import css from "./AdminUserList.module.css";

const sortOptions = [
  { value: "REGISTERED_DESC", label: "Created (desc)", field: "created_at", dir: "DESC" },
  { value: "REGISTERED_ASC", label: "Created (asc)", field: "created_at", dir: "ASC" },
  // { value: "SUBSCRIPTION_DATE_ASC", label: "Subscription Exp. (asc)", field: "expires_at", dir: "DESC" },
  // { value: "SUBSCRIPTION_DATE_DESC", label: "Subscription Exp. (desc)", field: "expires_at", dir: "DESC" },
  // { value: "NAME_ASC", label: "Name (asc)", field: "name", dir: "ASC" },
  // { value: "NAME_DESC", label: "Name (desc)", field: "name", dir: "DESC" },
];

export class AdminUserList extends React.Component {
  cleanOverride = () => null;

  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: undefined,
      filters: this.getDefaultFilters(),
      pagination: this.getDefaultPagination(),
      usersData: undefined,
      selectedUser: undefined,
      selectedUsers: {},
    };
  }

  getDefaultFilters() {
    return {
      query: "",
      sort: sortOptions[0],
    };
  }

  getDefaultPagination() {
    return {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };
  }

  componentWillUnmount() {
    this.cleanOverride();
  }

  async componentDidMount() {
    await this.restoreSearchFromUrl();
    this.cleanOverride = injectStyleOverride();
    try {
      await this.fetchData();
      this.setState({ pageLoading: false });
    } catch (err) {
      console.error("couldn't load the page", err);
      this.setState({ pageLoading: false });
    }
  }

  restoreSearchFromUrl = () => {
    const data = UrlHelper.parseSearch(window.location.search);
    const { currentPage, pageSize, ...filters } = data || {};
    const defaultPagination = this.getDefaultPagination();
    const defaultFilters = this.getDefaultFilters();

    return new Promise((resolve) => {
      this.setState(
        {
          filters: {
            ...defaultFilters,
            ...pick(filters, Object.keys(defaultFilters)),
            sort: filters.sort
              ? sortOptions.find((item) => item.value === filters.sort) || defaultFilters.sort
              : defaultFilters.sort,
          },
          pagination: {
            currentPage: parseInt(currentPage || defaultPagination.currentPage),
            pageSize: parseInt(pageSize || defaultPagination.pageSize),
          },
        },
        resolve
      );
    });
  };

  saveSearchToUrl() {
    const {
      pagination: { pageSize, currentPage },
      filters,
    } = this.state;
    const activeFilters = pick(filters, Object.keys(filters));
    const filterString = UrlHelper.stringifyParams({
      ...omit(activeFilters, ["sort"]),
      sort: activeFilters.sort.value,
      pageSize,
      currentPage,
    });
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
  }

  debouncedSaveSearchToUrl = debounce(this.saveSearchToUrl, 200);

  fetchData = async () => {
    const { query, sort } = this.state.filters;
    const { pageSize, currentPage } = this.state.pagination;
    this.setState({ loading: true });
    try {
      const users = await userService.fetchAll({
        query,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
        orderBy: sort.field,
        sortBy: sort.dir.toLowerCase(),
      });
      this.setState({ users, loading: false });
    } catch (err) {
      console.error("Couldn't fetch the users", err);
      this.setState({
        error: err,
        loading: false,
      });
    }
  };

  debouncedFetchData = debounce(this.fetchData, 250);

  onChangeFilter = (filter) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filter,
        },
        pagination: {
          ...this.state.pagination,
          currentPage: 1,
        },
      },
      () => {
        if (filter.query) {
          this.debouncedFetchData();
          this.debouncedSaveSearchToUrl();
        } else {
          this.fetchData();
          this.saveSearchToUrl();
        }
      }
    );
  };

  onChangePagination = (pagination) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      },
      () => {
        this.saveSearchToUrl();
        this.fetchData();
      }
    );
  };

  onUserClick = (user) => {
    this.setState({ selectedUser: user });
  };

  onSelectUser = (user) => {
    const { selectedUsers } = this.state;
    if (selectedUsers[user.id]) {
      this.setState({ selectedUsers: omit(selectedUsers, [user.id]) });
    } else {
      this.setState({
        selectedUsers: {
          ...selectedUsers,
          [user.id]: user,
        },
      });
    }
  };

  isAllUsersSelected = () => {
    const { users, selectedUsers } = this.state;
    return !!users?.nodes?.length && Object.keys(selectedUsers).length === users?.nodes?.length;
  };

  onSelectAllUsers = () => {
    if (this.isAllUsersSelected()) {
      this.setState({ selectedUsers: {} });
    } else {
      this.setState({
        selectedUsers: this.state.users?.nodes?.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item.id,
          };
        }, {}),
      });
    }
  };

  deleteUsers = async () => {
    // @todo reset current page if all users from the page have been removed
    const { selectedUsers } = this.state;
    const { notifications, confirm } = this.props;
    const usersCount = Object.keys(selectedUsers).length;
    const usersText = usersCount > 1 ? `${usersCount} users` : Formatter.fullName(Object.values(selectedUsers)[0]);

    const isConfirmed = await confirm.open({
      destructive: true,
      content: <>Are you sure you want to delete {usersText}?</>,
      confirmButtonTitle: "Delete",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      for (const userId of Object.values(selectedUsers)) {
        await projectService.delete(userId);
      }
      notifications.showSuccess(`${usersText} ${usersCount > 1 ? "have" : "has"} been removed!`);
      this.setState({ selectedUsers: {} });
      this.fetchData();
    } catch (err) {
      notifications.showError(`Couldn't delete the ${usersText}!`);
    }
  };

  render() {
    const { users, selectedUsers, selectedUser, pageLoading, error } = this.state;
    const { pageSize, currentPage } = this.state.pagination;
    const { query, sort } = this.state.filters;

    return (
      <>
        <AdminUserListFilters
          users={users}
          onChangeQuery={(v) => this.onChangeFilter({ query: v })}
          onChangeSort={(v) => this.onChangeFilter({ sort: v })}
          query={query}
          sortOptions={sortOptions}
          sort={sort}
          selectedUsers={selectedUsers}
          onDeleteUsers={this.deleteUsers}
        />
        <div className={css.container}>
          <Table
            noData="No users found"
            // selectable={!!users?.nodes?.length}
            onSelectAll={this.onSelectAllUsers}
            isAllSelected={this.isAllUsersSelected()}
            selected={selectedUsers}
            onSelect={this.onSelectUser}
            dataObject={{
              loading: pageLoading,
              error,
              data: {
                users: users?.nodes,
              },
            }}
            columns={[
              {
                className: css.tdName,
                dataIndex: "name",
                style: { width: 150 },
                render: (v, item) => {
                  return (
                    <CustomLink className={css.userLink} to={`/admin/users/${item.id}`}>
                      {Formatter.fullName(item) || item.name?.trim() || item.email}
                    </CustomLink>
                  );
                },
              },
              {
                dataIndex: "email",
                style: { width: 250 },
                render: (v) => {
                  return (
                    <div
                      title={v}
                      style={{ maxWidth: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {v}
                    </div>
                  );
                },
              },
              { dataIndex: "organization", title: "Organization", render: (v) => v?.name || "–" },
              // { dataIndex: "projectsCount", title: "Projects" },
              {
                dataIndex: "latestSubscription",
                title: "Sub End",
                style: { width: 120 },
                render: (v) => {
                  if (!v) {
                    return "–";
                  }
                  return Formatter.fullDate(v.endDate);
                },
              },
              {
                dataIndex: 'monthlyUnlocksRedeemed',
                title: "Cont Used",
                render: (v) => (v || 0).toLocaleString(),
              },
              {
                title: "Cont Available",
                render: (v, user) => {
                  return ((user.monthlyUnlocksCount || 0) - (user.monthlyUnlocksRedeemed || 0)).toLocaleString();
                },
              },
              { dataIndex: "emailConfirmed", title: "Activated", render: Formatter.yesNo },
              {
                dataIndex: "latestSubscription",
                style: { width: 100 },
                title: "ChargeBee",
                render: (v, item) => {
                  if (!v) {
                    return "–";
                  }
                  return (
                    <div style={{ maxWidth: 100, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <a href={userService.getSubscriptionUrl(v.externalId)} rel="noopener noreferrer" target="_blank">
                        {v.externalId}
                      </a>
                    </div>
                  );
                },
              },
              { className: css.tdDate, dataIndex: "createdAt", title: "Created", render: Formatter.fullDate },
            ]}
          />
          {users?.pageInfo?.pageCount > 1 && (
            <PaginationFull
              style={{ marginTop: 20 }}
              onChangePageSize={(pageSize) => this.onChangePagination({ currentPage: 1, pageSize })}
              onChangeCurrentPage={(currentPage) => this.onChangePagination({ currentPage })}
              currentPage={currentPage}
              pageSize={pageSize}
              pageCount={users?.pageInfo?.pageCount}
            />
          )}
        </div>
        {selectedUser && (
          <UserModal
            onClose={() => this.setState({ selectedUser: undefined })}
            onSubmit={this.update}
            userId={selectedUser.id}
          />
        )}
      </>
    );
  }
}

AdminUserList = (function withUserReducer(WrappedComponent) {
  return function (props) {
    const notifications = useNotifications();
    return <WrappedComponent {...props} notifications={notifications} />;
  };
})(AdminUserList);

AdminUserList = withConfirm(AdminUserList);
