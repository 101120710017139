import * as React from "react";
import { Button } from "../../DiscoverNew/UI/Button/Button";

import css from "./SubscriptionRequired.module.css";

export const ChooseSubscriptionButton = () => {
  // const onClick = () => {
  //   // eslint-disable-next-line no-undef
  //   let cbInstance = Chargebee.getInstance();
  //   let cart = cbInstance.getCart();
  //   let product = cbInstance.initializeProduct("CW-BLR-DE-USD-Yearly");
  //   cart.replaceProduct(product);
  //   cart.proceedToCheckout();
  // };
  return (
    <Button
      data-cb-type="checkout"
      data-cb-item-0="50-Sourcing-Plan-USD-Yearly"
      // data-cb-item-0="Test-Plan-1-USD-Monthly"
      data-cb-item-0-quantity="1"
      className={css.submitButton}
    >
      Choose Subscription
    </Button>
  );
};

export const SubscriptionRequired = () => {
  React.useEffect(() => {
    // const existingScript = document.querySelector("#chargebee-api");
    // if (existingScript) {
    //   existingScript.remove();
    // }
    // const apiScript = document.createElement("script");
    // apiScript.setAttribute("id", "chargebee-api");
    // apiScript.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
    // apiScript.setAttribute("data-cb-site", "chatterworks");
    // apiScript.addEventListener("load", () => {
    //   Chargebee.registerAgain();
    // });
    // document.head.append(apiScript);
    // eslint-disable-next-line no-undef
    Chargebee.registerAgain();
  }, []);
  return (
    <div className={css.container}>
      <h2 className={css.title}>Welcome to ChatterWorks!</h2>
      <p className={css.text}>
        To start using ChatterWorks, you need an active subscription.
        <br />
        Click the button below to get started.
      </p>
      <ChooseSubscriptionButton />
    </div>
  );
};
