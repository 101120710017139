import * as React from "react";
import { useRef } from "react";
//@ts-ignore
import ReactSelect, { components } from "react-select";
//@ts-ignore
import ReactSelectAsync from "react-select/async";
//@ts-ignore
import ReactSelectCreatable from "react-select/creatable";
//@ts-ignore
import ReactSelectAsyncCreatable from "react-select/creatable";
import { AUTOCOMPLETE_OFF } from "../../../../constants/constants";
import { shortId } from "../../util/util";

const focusedBorder = "1px solid #408cf6";
const errorBorder = "1px solid #F9654D";
const defaultBorder = "1px solid #d6d6d6";

const SingleValue = ({ children, ...props }: any) => {
  const { formatDisplayedValue } = props.selectProps;
  let content = formatDisplayedValue ? formatDisplayedValue(props) : children;
  return <components.SingleValue {...props}>{content}</components.SingleValue>;
};

const Input = ({ autoComplete, ...props }: any) => {
  const name = useRef(shortId());
  return <components.Input {...props} autoComplete={AUTOCOMPLETE_OFF} name={name.current} />;
};

export type SelectOption = { label: string; value: string | number }
type SingleSelectValueType = string | number | SelectOption;
type SelectValueType = SingleSelectValueType | SingleSelectValueType[]

type Props = {
  options?: SelectOption[];
  value?: SelectValueType;
  placeholder?: string;
  noOptionsMessage?: (obj: { inputValue: string }) => string;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  defaultBorderStyle?: string;
  loadOptions?: (inputValue: string) => Promise<SelectOption[]>;
  onChange: (value: SelectValueType) => void;
  isMulti?: boolean;
  creatable?: boolean;
  isDisabled?: boolean;
  error?: boolean;
  styles?: {
    menu?: (styles: React.CSSProperties) => React.CSSProperties;
    menuPortal?: (styles: React.CSSProperties) => React.CSSProperties;
  }
  style?: React.CSSProperties
}

export function Select(props: Props) {
  let Component = ReactSelect;
  if (props.creatable) {
    Component = ReactSelectCreatable;
  }
  if (props.loadOptions) {
    Component = ReactSelectAsync;
  }
  if (props.creatable && props.loadOptions) {
    Component = ReactSelectAsyncCreatable;
  }

  return (
    <Component
      {...props}
      defaultOptions
      isDisabled={props.isDisabled}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      menuPlacement="auto"
      components={{
        SingleValue,
        Input,
        IndicatorSeparator: null,
      }}
      styles={{
        dropdownIndicator: (styles: React.CSSProperties) => {
          return {
            ...styles,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 3,
          };
        },
        option: (styles: React.CSSProperties, state: any) => {
          return {
            ...styles,
            color: state.value === null && !state.isSelected ? "#222222" : styles.color,
          };
        },
        menu: props.styles?.menu,
        menuPortal: props.styles?.menuPortal,
        control: (styles: React.CSSProperties, { isFocused }: any) => ({
          ...styles,
          borderRadius: 2,
          boxShadow: "none",
          minHeight: 30,
          border: isFocused ? focusedBorder : props.error ? errorBorder : props.defaultBorderStyle || defaultBorder,
          ":hover": {
            border: isFocused ? focusedBorder : props.error ? errorBorder : props.defaultBorderStyle || defaultBorder,
          },
        }),
        input: (styles: React.CSSProperties) => ({
          ...styles,
          paddingTop: 1,
          paddingBottom: 0,
        }),
        container: (styles: React.CSSProperties) => ({
          ...styles,
          ...(props.style || {}),
          width: "100%",
        }),
      }}
      loadOptions={props.loadOptions}
    />
  );
}