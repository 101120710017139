import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import ModalButtonContainer from "../button/ModalButtonContainer";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";
import { useNotifications } from "../DiscoverNew/contexts/Notification/notifications";
import { InputDate } from "../input/InputDate/InputDate";
import { DateTime } from "luxon";
import ModalInputField from "../input/ModalInputField";
import { userService } from "../../screens/projects/UserService";

export function SubscriptionModal({ onClose, data, onSuccess }) {
  const notifications = useNotifications();
  const [submitting, setSubmitting] = useState(false);

  const [errors, setErrors] = useState();
  const [values, setValues] = useState({
    name: data.name || '',
    externalId: data.externalId || '',
    startDate: DateTime.fromISO(data.startDate || new Date().toISOString()).toJSDate(),
    endDate: data.endDate ? DateTime.fromISO(data.endDate).toJSDate() :  DateTime.fromISO(new Date().toISOString()).plus({ month: 1 }).toJSDate(),
  });

  const onChange = (value) => {
    const fieldName = Object.keys(value)[0];
    setErrors({
      ...errors,
      [fieldName]: null,
    });
    const nextValues = {
      ...values,
      ...value,
    };
    setValues(nextValues);
  };

  async function handleSubmit() {
    setSubmitting(true);
    if (data.id) {
      try {
        await userService.updateOrganizationSubscription(data.organizationId, data.id, values);
        notifications.showSuccess(`Subscription has been updated!`);
        onSuccess();
      } catch (err) {
        notifications.showError(`Couldn't update the subscription!`);
      }
    } else {
      try {
        await userService.createOrganizationSubscription(data.organizationId, values);
        notifications.showSuccess(`Subscription has been created!`);
        onSuccess();
      } catch (err) {
        notifications.showError(`Couldn't create the subscription!`);
      }
    }
    setSubmitting(false);
    onClose();
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>{data.id ? 'Edit Subscription' : 'Create Subscription'}</div>
        <div className={modalStyles.modalFormContainer}>
          <ModalInputField
            label={<div className={modalStyles.modalInputLabel}>Name</div>}
            placeholder="Enter Name"
            onChange={(e) => onChange({ name: e.target.value })}
            value={values.name}
          ></ModalInputField>
          <ModalInputField
            label={<div className={modalStyles.modalInputLabel}>ChargeBee ID</div>}
            placeholder="Enter ChargeBee ID"
            onChange={(e) => onChange({ externalId: e.target.value })}
            value={values.externalId}
          ></ModalInputField>
          <div style={{ marginBottom: 20, marginTop: -8 }}>
            <InputDate
              label={<div className={modalStyles.modalInputLabel}>Start Date</div>}
              onChange={(v) => onChange({ startDate: v })}
              value={values.startDate}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <InputDate
              label={<div className={modalStyles.modalInputLabel}>End Date</div>}
              onChange={(v) => onChange({ endDate: v })}
              value={values.endDate}
            />
          </div>
        </div>
        <ModalButtonContainer title={data.id ? `Update Subscription` : 'Create Subscription'} onClick={handleSubmit} isWaiting={submitting} />
      </div>
    </Modal>
  );
}
