import React, { useState, useEffect, useCallback } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Button from "react-bootstrap/Button";
import { css, cx } from "emotion/macro";

// candidate avatars
import CaretLeft from "../../icons/CaretLeft";

import capitalizeWords from "../../formatters/capitalizeWords";
import generateInitials from "../../formatters/generateInitials";
import constructLocation from "../../formatters/locationFormatter";

import * as api from "../../api/apiService";

// Candidate Information
import CandidateProfileImg from "../candidate/CandidateProfileImg";
import CandidateDetailSection from "../candidate/CandidateDetailSection";
import CandidateInfo from "../candidate/CandidateInfo";
import CandidateCompanyAddRemove from "../candidate/CandidateCompanyAddRemove";
import ContactIcons from "../icons/contactIcons";
import SocialIcons from "../icons/socialIcons";

// Notes
import NotesDrawer from "../note/NotesDrawer";

// Navigator
import NavigatorWrapper from "../navigator/NavigatorWrapper";
import NavigatorTabContainer from "../navigator/NavigationTabContainer";

// Mobile Components
import MobileExperience from "../mobile/experience/MobileExperience";
import MobileSkills from "../mobile/skills/MobileSkills";
import MobileNoteFooter from "../mobile/notes/MobileNoteFooter";

import "./candidateDetails.css";
import { md, lg, lgU } from "../../css/mediaQueries";

import { clone } from "lodash";

// Contexts
import { useUserData } from "../../contexts/user";
import { useCandidates } from "../../contexts/candidates";
import { useModalNavigator } from "../../contexts/modalNavigator";

import { canUserViewInsights } from "../../utils/candidateFeatures";
import { calculateRankedExperiences, calculateRankedSkills } from "../DiscoverNew/util/SearchHighlight";
import { candidateService } from "../../screens/projects/CandidateService";

function CandidateDetailModalBody({
  candidate: incomingCandidate,
  isNavView,
  uiSettings,
  onCandidateUpdated,
  initialPage,
  searchHighlights
}) {
  const {
    state: { user },
  } = useUserData();
  const { dispatch } = useCandidates();
  const { dispatch: modalNavigatorDispatch } = useModalNavigator();

  const [candidate, setCandidate] = useState(null);

  const fetchCompaniesAndProjects = useCallback(async () => {
    try {
      const { data: companiesProjectsResponse } = await api.GetAllCompaniesAndProjects();
      setCompaniesProjects(companiesProjectsResponse);
    } catch (e) {
      console.error("could not fetch companies and projects");
    }
  }, []);

  const calculateSearchRankings = useCallback((candidate, searchHighlights) => {
    if (!candidate || !searchHighlights) return;

    const rankedExperiences = calculateRankedExperiences(candidate.experiences, searchHighlights);
    const rankedSkills = calculateRankedSkills(candidate.skills, searchHighlights);

    return {
      rankedExperiences: rankedExperiences,
      rankedSkills: rankedSkills
    }
  }, []);

  const fetchCandidate = useCallback(async (searchHighlights) => {
    try {
      const candidateId = incomingCandidate?.candidateId;
      const { data: candidateResp } = await api.GetCandidate(candidateId);
      const candidateInitialsGen = generateInitials([candidateResp?.firstName, candidateResp?.lastName], true);
      if (searchHighlights) {
        const searchRankings = calculateSearchRankings(candidateResp, searchHighlights);
        candidateResp.searchRankings = searchRankings;
      }
      setCandidate(candidateResp);
      modalNavigatorDispatch({ type: "SET_CURRENT_CANDIDATE", payload: { ...candidateResp } });
      setCandidateInitials(candidateInitialsGen);
      dispatch({ type: "UPDATE_SEARCH_HITS", payload: { candidate: candidateResp } });
    } catch (e) {
      console.error("could not fetch candidate", e);
    }
  }, [incomingCandidate, dispatch, modalNavigatorDispatch]);

  useEffect(() => {
    fetchCandidate(searchHighlights);
    fetchCompaniesAndProjects();
  }, [fetchCandidate, fetchCompaniesAndProjects]);

  const fetchCandidateNotes = useCallback(async () => {
    if (!candidate?.candidateId) return;
    const { candidateId } = candidate;
    try {
      const { data: candidateNotesResp } = await api.GetCandidateNotes(candidateId);
      window.analytics.track("Candidate Note Viewed", {
        candidate_id: candidateId,
        state: candidate?.location?.region,
        locality: candidate?.location?.locality,
        is_unlocked: candidate?.isContactInfoViewable,
      });
      setCandidateNotes(candidateNotesResp);
    } catch (e) {
      console.error("could not fetch candidate notes");
    }
  }, [candidate]);

  const getDefaultPage = useCallback(() => {
    return initialPage || (canUserViewInsights(user) ? "insights" : "detail");
  }, [initialPage, user]);

  useEffect(() => {
    if (candidate?.candidateId) {
      fetchCandidateNotes();
    }
  }, [candidate, fetchCandidateNotes]);

  useEffect(() => {
    if (!user?.organizationId) {
      return;
    }
    setCurrentPage(getDefaultPage());
  }, [user, getDefaultPage]);

  const [companiesProjects, setCompaniesProjects] = useState([]);

  const [candidateInitials, setCandidateInitials] = useState([]);

  const [candidateNotes, setCandidateNotes] = useState([]);
  const [noteContent, setNoteContent] = useState([]);

  const [currentPage, setCurrentPage] = useState(getDefaultPage());

  const [isNavigatorView, setIsNavigatorView] = useState(isNavView ?? true);

  const [isMobileAddProjectOpen, setIsMobileAddProjectOpen] = useState(false);

  const addCandidateToProject = async (comp, project, projectId, candidateId) => {
    try {
      const response = await api.AddCandidateToProject(projectId, candidateId);
      if (response.status === 204) {
        // Segment tracking
        window.analytics.track("Candidate Added To Project", {
          candidate_id: candidateId,
          state: candidate?.location?.region,
          locality: candidate?.location?.locality,
          is_unlocked: candidate?.isContactInfoViewable,
          project_id: projectId,
        });
      }
      updateCandidateProjects(candidate, comp, project, "add");
      dispatch({ type: "UPDATE_SEARCH_HITS", payload: { candidate: candidate } });
      setCandidate(candidate);
      onCandidateUpdated && onCandidateUpdated(candidate);
    } catch (e) {
      console.error(e);
    }
  };

  const removeCandidateFromProject = async (comp, project, projectId, candidateId) => {
    try {
      const response = await api.RemoveCandidateFromProject(projectId, candidateId);
      if (response.status === 204) {
        window.analytics.track("Candidate Removed From Project", {
          candidate_id: candidateId,
          state: candidate?.location?.region,
          locality: candidate?.location?.locality,
          is_unlocked: candidate?.isContactInfoViewable,
          project_id: projectId,
        });
      }
      updateCandidateProjects(candidate, comp, project, "remove");
      dispatch({ type: "UPDATE_SEARCH_HITS", payload: { candidate: candidate } });
      setCandidate(candidate);
      onCandidateUpdated && onCandidateUpdated(candidate);
    } catch (e) {
      console.error(e);
    }
  };

  // updateCandidateCompanies will take the candidate, company, and project information as well as an action.
  // The action will either be add/remove to determine how to manipulate the companies list.
  // We will then call the updateCandidate which will update the candidate context list to drive
  // the candidate search results UI.
  const updateCandidateProjects = (candidate, company, project, action) => {
    if (!candidate || !company || !project) return;

    const newCompany = clone(company);
    newCompany.projects = null;
    const newProject = clone(project);

    if (action === "add") {
      // No companies
      if (!candidate.companies?.length) {
        newCompany.projects = [newProject];
        candidate.companies = [newCompany];
        return;
      }

      // New company
      const companyIndex = candidate.companies.findIndex((c) => c.companyId === company.companyId);
      if (companyIndex < 0) {
        newCompany.projects = [newProject];
        candidate.companies.push(newCompany);
        return;
      }

      // First project for existing company
      if (!candidate.companies[companyIndex].projects?.length) {
        candidate.companies[companyIndex].projects = [newProject];
        return;
      }

      // Previously added project
      const projectIndex = candidate.companies[companyIndex].projects.findIndex(
        (p) => p.projectId === project.projectId
      );
      if (projectIndex >= 0) {
        return;
      }
      // New project for existing company
      candidate.companies[companyIndex].projects.push(newProject);
      return;
    }

    if (action === "remove") {
      if (!candidate.companies?.length) return;

      // Company not found
      const companyIndex = candidate.companies.findIndex((c) => c.companyId === company.companyId);
      if (companyIndex < 0) return;

      // Company has no projects
      if (!candidate.companies[companyIndex].projects?.length) return;

      // Project not found in company
      const projectIndex = candidate.companies[companyIndex].projects.findIndex(
        (p) => p.projectId === project.projectId
      );
      if (projectIndex < 0) return;

      // Remove project
      candidate.companies[companyIndex].projects.splice(projectIndex, 1);

      // Remove company, if it doesn't contain any projects
      if (!candidate.companies[companyIndex].projects.length) {
        candidate.companies.splice(companyIndex, 1);
      }
      return;
    }

    console.error(`expected updateCandidateProjects action to be "add" or "remove". received ${action}`);
  };

  const updateProjectStage = async (companyId, projectId, stage) => {
    try {
      await api.UpdateCandidateStage(projectId, candidate.candidateId, stage.stageId);
      window.analytics.track("Candidate Stage Updated", {
        candidate_id: candidate?.candidateId,
        state: candidate?.location?.region,
        locality: candidate?.location?.locality,
        is_unlocked: candidate?.isContactInfoViewable ?? false,
        stage: stage?.name,
      });
      updateCandidateProjectStage(candidate, companyId, projectId, stage);
      dispatch({ type: "UPDATE_SEARCH_HITS", payload: { candidate: candidate } });
      setCandidate(candidate);
    } catch (e) {
      console.error(e);
    }
  };

  // updateCandidateProjectStage will take the candidate, companyId, projectId, and stage information.
  // in order to update the candidate context list to drive the search hits, in the event that we have
  // an active search query in the discover page.
  const updateCandidateProjectStage = (candidate, companyId, projectId, stage) => {
    if (!candidate || !companyId || !projectId || !stage) return;

    // Find company
    const companyIndex = candidate.companies.findIndex((c) => c.companyId === companyId);
    if (companyIndex < 0) return;

    // Find project
    const projectIndex = candidate.companies[companyIndex].projects.findIndex((p) => p.projectId === projectId);
    if (!projectIndex < 0) return;

    // Update stage object
    candidate.companies[companyIndex].projects[projectIndex].stage = stage;
    return;
  };

  const toggleMobileAddProject = () => {
    setIsMobileAddProjectOpen(!isMobileAddProjectOpen);
  };

  const openMobileAddProject = () => {
    setIsMobileAddProjectOpen(true);
    window.analytics.track(`Candidate Projects Viewed`, {
      candidate_id: candidate?.candidateId,
      state: candidate?.location?.region,
      locality: candidate?.location?.locality,
      is_unlocked: candidate?.isContactInfoViewable,
    });
  };

  const clearNoteInput = () => {
    setNoteContent("");
  };

  const handleOnNoteContentChange = (e) => {
    setNoteContent(e.target.value);
  };

  const onSaveButtonPressed = async (e) => {
    e.preventDefault();
    if (noteContent === "") return;
    await createAndFetchNotes(noteContent);
  };

  const createAndFetchNotes = async (note) => {
    if (!candidate?.candidateId || !note) return;
    const { candidateId } = candidate;
    try {
      const response = await candidateService.addCandidateNote(candidateId, note);
      if (response.data) {
        // Segment Tracking
        window.analytics.track("Candidate Note Created", {
          candidate_id: candidate.candidateId,
          state: candidate?.location?.region,
          locality: candidate?.location?.locality,
          is_unlocked: candidate?.isContactInfoViewable,
          note: note,
        });
      }

      await fetchCandidateNotes(candidateId);
      clearNoteInput();
      // Refresh height of text area element
      let tx = document.getElementById("create-note-textarea");
      if (tx) {
        tx.style.height = "68px";
      }
      setCurrentPage("notes");
    } catch (e) {
      console.error(e);
    }
  };

  const editAndFetchNotes = async (editedNoteContent) => {
    if (!candidate?.candidateId || !editedNoteContent) return;
    const { candidateId } = candidate;
    try {
      await api.UpdateCandidateNote(candidateId, editedNoteContent);
      await fetchCandidateNotes(candidateId);
    } catch (e) {
      console.error(e);
    }
  };

  const toggleNavigatorView = () => {
    setIsNavigatorView(!isNavigatorView);
    fetchCandidate();
  };

  const skills = (candidate && candidate.skills) || [];
  const education = (candidate && candidate.education) || [];
  const experiences = (candidate && candidate.experiences) || [];
  const fullName = capitalizeWords([candidate?.firstName, candidate?.lastName].filter((x) => x).join(" "));

  const location = constructLocation(candidate?.location);

  if (candidate?.candidateId === 130338703) {
    candidate.experiences = candidate.experiences.map(item => {
      if (item.company?.name === 'chatterworks') {
        item.isPrimary = true;
      }
      return item;
    })
  }

  if (candidate?.candidateId === 104351687) {
    candidate.experiences = candidate.experiences.map(item => {
      if (item.company?.name === 'mdc-ventures') {
        item.isPrimary = true;
      }
      return item;
    })
  }

  let latestExperience = candidate?.experiences?.find(item => item.isPrimary);
  if (!latestExperience) {
    latestExperience = candidate?.experiences?.[0];
  }

  let company = "";
  if (latestExperience?.company?.name) {
    company = capitalizeWords(latestExperience.company.name);
  }

  let title = "";
  if (latestExperience?.title) {
    title = capitalizeWords(latestExperience.title);
  }

  const IconButton = ({ Icon, onClick, label }) => {
    return (
      <div onClick={onClick} className={styles.iconButton}>
        <Icon />
        {label}
      </div>
    );
  };

  const BackButton = ({ onClick, label }) => <IconButton Icon={CaretLeft} onClick={onClick} label={label} />;

  return (
    <ModalBody className={cx(styles.modalBody, "candidate-detail-modal-body")}>
      {/* Left Pane */}
      <div className={styles.candidateDetailContainer} style={{ margin: "initial" }}>
        <div className={cx(styles.candidateDetailProfile, "col-xl-4", "col-lg-5", "col-md-12", "col-sm-12")}>
          {candidate ? (
            <div
              className={css`
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <div>
                <div className="row bg-2 profile-container text-center">
                  <div className="col-12" style={{ marginBottom: "15px" }}>
                    <div className="project-body" style={{ marginTop: "0px", marginBottom: "14px" }}>
                      <CandidateProfileImg
                        candidate={candidate}
                        initials={candidateInitials}
                        style={{ width: "110px", height: "110px" }}
                      />
                    </div>
                    <div className={styles.candidateInfoContainer}>
                      <CandidateInfo
                        candidate={{
                          name: fullName,
                          location: location,
                          company: company,
                          title: title,
                        }}
                        styleClass="detail"
                      />
                    </div>
                    <div className="d-none d-lg-flex" style={{ justifyContent: "center", width: "100%" }}>
                      <Button
                        id="large-screen-add-button"
                        className="add-button"
                        style={{
                          width: "200px",
                          height: "31px",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        variant="outline-primary"
                        onClick={() => setIsNavigatorView(false)}
                      >
                        <span
                          className={cx(
                            "standard-theme-text",
                            css`
                              font-family: Montserrat-Medium !important;
                              font-size: 14px;
                              font-stretch: normal;
                              font-style: normal;
                              line-height: normal;
                              letter-spacing: -0.38px;
                              text-align: center;
                              color: #1e8bfe;
                            `
                          )}
                        >
                          {`Add to ${uiSettings?.mappings?.project}`}
                        </span>
                      </Button>
                    </div>
                    {/* Same button but will trigger the mobile add project flow */}
                    <div className="d-flex d-lg-none" style={{ justifyContent: "center", width: "100%" }}>
                      <Button
                        id="mobile-add-button"
                        className="add-button"
                        style={{
                          width: "200px",
                          height: "31px",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        variant="outline-primary"
                        onClick={() => openMobileAddProject()}
                      >
                        <span
                          className={cx(
                            "standard-theme-text",
                            css`
                              font-family: Montserrat-Medium !important;
                              font-size: 14px;
                              font-stretch: normal;
                              font-style: normal;
                              line-height: normal;
                              letter-spacing: -0.38px;
                              text-align: center;
                              color: #1e8bfe;
                            `
                          )}
                        >
                          {`Add to ${uiSettings?.mappings?.project}`}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
                <hr className={styles.maxWidth} />
                {/*TODO: Mobile Add Project Section */}
                {/* TODO: extract into a separate component */}
                {isMobileAddProjectOpen && (
                  <Modal
                    isOpen={isMobileAddProjectOpen}
                    toggle={() => toggleMobileAddProject()}
                    id="mobile-add-project-modal"
                  >
                    {/* ADD PROJECT HEADER */}
                    <ModalHeader className="grey-border-bottom add-project-header">
                      <div className="row add-project-header-icon-container">
                        <div>
                          <button
                            onClick={() => {
                              toggleMobileAddProject();
                            }}
                            type="button"
                            className="close bs-close-button"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                      <div className="row add-project-title-container">
                        <h6 className="candidate-projects-mobile-header">{uiSettings?.mappings?.projects}</h6>
                      </div>
                    </ModalHeader>
                    {/* ADD PROJECT BODY */}
                    <ModalBody style={{ height: "100%" }}>
                      <NavigatorTabContainer className={styles.projectContainer}>
                        <CandidateCompanyAddRemove
                          uiSettings={uiSettings}
                          candidate={candidate}
                          companiesProjects={companiesProjects}
                          addCandidateToProject={addCandidateToProject}
                          removeCandidateFromProject={removeCandidateFromProject}
                        />
                      </NavigatorTabContainer>
                    </ModalBody>
                  </Modal>
                )}

                {/* CONTACT SECTION */}
                <div className={styles.iconContainer}>
                  <ContactIcons candidateData={candidate} fetchCandidate={fetchCandidate} />
                </div>

                <hr className={styles.maxWidth} />
                <div
                  className={cx(
                    "d-block d-lg-none",
                    css`
                      padding: 0 22px;
                    `
                  )}
                >
                  <CandidateDetailSection title={"experience"} showViewMore={true}>
                    <MobileExperience entries={experiences} viewAll={false} maxEntries={3} />
                  </CandidateDetailSection>
                </div>

                <hr className={cx("d-block d-lg-none", styles.maxWidth)} />
                <div
                  className={cx(
                    "d-block d-lg-none",
                    css`
                      padding: 0 22px;
                    `
                  )}
                >
                  <CandidateDetailSection title={"featured skills"} showViewMore={true}>
                    <MobileSkills entries={skills} viewAll={false} maxEntries={3} />
                  </CandidateDetailSection>
                </div>
                {/* <MobileSkills entries={skills} /> */}
                <hr className={cx("d-block d-lg-none", styles.maxWidth)} />

                <div className={styles.iconContainer}>
                  <SocialIcons candidateData={candidate} />
                </div>
              </div>

              <MobileNoteFooter
                candidate={candidate}
                candidateNotes={candidateNotes}
                saveNote={createAndFetchNotes}
                onEditNoteSaved={editAndFetchNotes}
              />

              {/* Web App NotesDrawer */}
              <NotesDrawer
                candidate={candidate}
                candidateNotes={candidateNotes}
                noteContent={noteContent}
                onNoteContentChange={handleOnNoteContentChange}
                saveNote={onSaveButtonPressed}
                setCurrentPage={setCurrentPage}
              />
            </div>
          ) : (
            <div className="main-content-container">
              <div className="justify-content-center text-center">
                <span>There was an issue fetching the data from the current site.</span>
              </div>
            </div>
          )}
        </div>
        {/* Right Pane */}
        {isNavigatorView ? (
          <NavigatorWrapper
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            candidate={candidate}
            user={user}
            uiSettings={uiSettings}
            updateProjectStage={updateProjectStage}
            experiences={experiences}
            education={education}
            skills={skills}
            candidateNotes={candidateNotes}
            companiesProjects={companiesProjects}
            editAndFetchNotes={editAndFetchNotes}
          />
        ) : (
          <div
            className={cx(
              "candidate-detail-navigate-container",
              "col-xl-8",
              "col-lg-7",
              "col-md-12",
              "d-none",
              "d-lg-block",
              css`
                padding-left: 23px !important;
                padding-right: 19px !important;
              `
            )}
          >
            <div
              className={cx(css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px;
                padding-bottom: 5px;
                border-bottom: 1px solid #d6d6d6;
                position: sticky;
                top: 0;
              `)}
            >
              <div
                onClick={toggleNavigatorView}
                className={cx(
                  css`
                    cursor: pointer;
                  `
                )}
              >
                <BackButton label={"Back"} />
              </div>
              <span
                className={cx(css`
                  font-family: Montserrat-Bold !important;
                  font-size: 14px;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.29;
                  letter-spacing: -0.71px;
                  text-align: center;
                  color: #000000;
                `)}
              >
                {`Add To ${uiSettings?.mappings?.project}`}
              </span>
              <div></div>
            </div>
            <NavigatorTabContainer className={styles.addRemoveProjectContainer}>
              <CandidateCompanyAddRemove
                uiSettings={uiSettings}
                candidate={candidate}
                companiesProjects={companiesProjects}
                addCandidateToProject={addCandidateToProject}
                removeCandidateFromProject={removeCandidateFromProject}
              />
            </NavigatorTabContainer>
          </div>
        )}
      </div>
    </ModalBody>
  );
}

const styles = {
  modalBody: css`
    padding: 0px;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
  `,
  candidateDetailContainer: css`
    display: flex;
    flex: 1;
    align-items: stretch;
    padding-top: 29px;
    ${lgU} {
      overflow: hidden;
    }
  `,
  candidateLastViewedContainer: css`
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  `,
  candidateDetailProfile: css`
    display: flex;
    flex-direction: column;
    border-right-color: #d2d2d2;
    border-right-width: 1px;
    border-right-style: solid;
    overflow-y: auto;
    ${md} {
      border: 0;
    }
    ${md} {
      overflow-y: initial;
    }
  `,
  addedToCompanyContainer: css`
    display: inline-flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 13.3px;
    letter-spacing: -0.55px;
    margin-top: 7px;
  `,
  addedToString: cx(
    "standard-theme-text",
    css`
      letterspacing: -0.41px;
      color: #9b9b9b;
    `
  ),
  toCompanyNumberString: cx(
    "standard-theme-text",
    css`
      letterspacing: -0.41px;
      color: #208bfe;
      cursor: pointer;
      margin-left: 4px;
    `
  ),
  iconContainer: css`
    padding: 0 15px;
    ${lg} {
      padding: 0 5px;
    }
    ${md} {
      padding: 0 20px;
    }
  `,
  iconButton: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #000000;

    &:hover {
      color: #000000;
    }

    svg {
      fill: #000000;
      stroke: #000000;
      transition: all 0.2s ease-in-out;
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }

    &:hover svg {
      fill: #000000;
      stroke: #000000;
    }
  `,
  projectContainer: css`
    margin: 24px 0px;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100% - 60px);
    padding-right: 10px;
    height: 100%;
    ${md} {
      padding: 0 30px;
      margin-top: 0px;
      max-height: calc(100% - 125px);
    }
  `,
  addRemoveProjectContainer: css`
    margin: 7px 0px;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100% - 60px);
    ${md} {
      margin-top: 0px;
    }
  `,
};

export default CandidateDetailModalBody;
