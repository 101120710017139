import React, { useEffect, useContext, useReducer } from "react";

import * as api from "../api/apiService";
import { useAuthController } from "./AuthProvider";

const fetchUser = async () => {
  const [userResp, integrations] = await Promise.all([
    api.GetCurrentUser(),
    api.GetIntegrationList()
  ]);

  if (userResp.data) {
    window.analytics.identify(userResp.data.userId, {
      external_id: userResp.data?.externalId,
      name: userResp.data?.name,
      email: userResp.data?.email,
      organization_id: userResp.data?.organizationId,
      organization_type: userResp.data?.organizationType,
      subscription_name: userResp.data?.subscriptionName,
      subscription_end_date: userResp.data?.subscriptionEndDate,
      platform: "web-app",
    });

    return { ...userResp.data, integrations: integrations.data || {} };
  }
  return null;
};

export const getCandidateUnlockNumber = async () => {
  const resp = await api.GetCandidateUnlockNumber();
  if (!resp?.data) return null;

  let candidateTotalUnlocks = resp.data?.candidateUnlocksTotal;
  let redeemedCredits = 0;
  if (resp?.data?.candidateUnlocksRedeemed) {
    redeemedCredits = resp.data?.candidateUnlocksRedeemed;
  }

  const totalCreditsRemaining = candidateTotalUnlocks - redeemedCredits;
  return totalCreditsRemaining;
};

const userReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "UPDATE_CURRENT_USER":
      return { ...state, user: { ...payload } };
    case "UPDATE_USER_USAGE":
      return { ...state, userCredits: payload };
    default:
      return state;
  }
};

const initialState = {
  user: {
    uiSettings: {
      mappings: {
        company: "Company",
        companies: "Companies",
        project: "Project",
        projects: "Projects",
        candidate: "Candidate",
        candidates: "Candidates",
      },
    },
    integrations: {},
  },
  userUsage: 0,
};

export const UserContext = React.createContext();
export const useUserData = () => useContext(UserContext);
export const UserDataProvider = ({ children }) => {
  const { isAuthenticated } = useAuthController();
  const [state, dispatch] = useReducer(userReducer, initialState);

  const fetchUserAndUsageData = async () => {
    const fetchedUser = await fetchUser();
    const fetchedUserCredits = await getCandidateUnlockNumber();

    dispatch({ type: "UPDATE_CURRENT_USER", payload: fetchedUser });
    dispatch({ type: "UPDATE_USER_USAGE", payload: fetchedUserCredits });
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserAndUsageData();
    }
  }, [isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        fetchUserAndUsageData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
