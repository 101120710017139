import * as api from "../../../api/apiService";
import { UISettingsService } from "../../../services/UISettingsService";
import { IdName } from "../../../types";

export type ProjectRefWithCandidateCount = {
  id: number;
  name: string;
  candidateCount: number;
};

export type StageFlowInterface = {
  name: string
  stageFlowId: number
  archivedAt: string
  createdAt: string
  createdBy: IdName & { profileImgUrl: string }
  updatedAt: string
  updatedBy: IdName & { profileImgUrl: string }
  projects: ProjectRefWithCandidateCount[]
  stages: Array<StageFlowItemInterface>
  isDefault: boolean
  tags: string[]
}

export type StageFlowItemInterface = {
  name: string
  stageId: number
  color: string
  hoursToComplete: number
  order: number
  __hiddenAt: string
}

export type StageFlowUpdateDTOInterface = {
  name: string
}

export type StageFlowCreateDTOInterface = {
  copyFromStageFlowId?: number
} & StageFlowUpdateDTOInterface

export type StageFlowItemUpdateDTOInterface = {
  name: string
  color: string
  hoursToComplete: number
  order: number
}

export enum StageColor {
  GRAY = "GRAY",
  RED = "RED",
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  PINK = "PINK",
  VIOLET = "VIOLET",
  BLUE = "BLUE",
  TURQUOISE = "TURQUOISE",
  DARKGREEN = "DARKGREEN",
  GREEN = "GREEN"
}

export const STAGE_COLORS: string[] = [
  StageColor.GRAY, StageColor.RED, StageColor.ORANGE, StageColor.YELLOW, StageColor.PINK, StageColor.VIOLET, StageColor.BLUE, StageColor.TURQUOISE, StageColor.DARKGREEN, StageColor.GREEN,
];

type StageFlowFetchAllFilter = {
  archived: 'archived' | 'active' | 'all'
  query: string
  limit?: number
  skip?: number
}

class StageFlowService {

  mapToInterface(obj: any): StageFlowInterface {
    const uiSettings = UISettingsService.getUISettings();
    return {
      ...obj,
      stages : (obj.stages || []).map((item: Partial<StageFlowItemInterface>) => {
        return {
          ...item,
          __hiddenAt : uiSettings.stageFlow?.[obj.organizationId + '__' + obj.stageFlowId]?.stages?.[item.stageId!]?.hiddenAt,
        };
      }),
    };
  }

  fetchAll = async (organizationId: number, filter: StageFlowFetchAllFilter) => {
    const result = await api.GetOrganizationStageFlowList(organizationId, filter);
    if (!result.data) {
      return Promise.reject(result.error);
    }

    if (!result.data.items) {
      return [];
    }

    const items: StageFlowInterface[] = [];
    const defaultStageFlow = result.data.items.find((stageFlow: StageFlowInterface) => stageFlow.isDefault);
    if (defaultStageFlow) {
      items.push(defaultStageFlow);
    }

    const otherStageFlows = result.data.items.filter((stageFlow: StageFlowInterface) => !stageFlow.isDefault);
    return [...items, ...otherStageFlows].map(this.mapToInterface);
  };

  fetchOne = async (organizationId: number, id: number) => {
    const result = await api.GetOrganizationStageFlow(organizationId, id);
    if (!result.data) {
      return Promise.reject(result.error);
    }
    return this.mapToInterface(result.data);
  };

  create = async (organizationId: number, data: StageFlowUpdateDTOInterface): Promise<number> => {
    const result = await api.CreateOrganizationStageFlow(organizationId, data);
    if (!result.data) {
      return Promise.reject(result.error);
    }
    return result.data;
  };

  delete = async (organizationId: number, id: number) => {
    const result = await api.DeleteOrganizationStageFlow(organizationId, id);
    if (typeof result.data === "undefined") {
      return Promise.reject(result);
    }
    return result.data;
  };

  update = async (organizationId: number, id: number, data: StageFlowUpdateDTOInterface) => {
    const result = await api.UpdateOrganizationStageFlow(organizationId, id, data);
    if (typeof result.data === "undefined") {
      return Promise.reject(result);
    }
    return this.mapToInterface(result.data);
  };

  archive = async (organizationId: number, id: number) => {
    const result = await api.ArchiveOrganizationStageFlow(organizationId, id, { archived : true });
    if (typeof result.data === "undefined") {
      return Promise.reject(result);
    }
    return result.data;
  }

  unarchive = async (organizationId: number, id: number) => {
    const result = await api.ArchiveOrganizationStageFlow(organizationId, id, { archived : false });
    if (typeof result.data === "undefined") {
      return Promise.reject(result);
    }
    return result.data;
  }

  duplicate = async (organizationId: number, id: number, data: { name: string }): Promise<number> => {
    const result = await api.DuplicateOrganizationStageFlow(organizationId, id, data);
    if (typeof result.data === "undefined") {
      return Promise.reject(result);
    }
    return result.data;
  };

  updateStage = async (organizationId: number, stageFlowId: number, stageId: number, data: StageFlowItemUpdateDTOInterface) => {
    const result = await api.UpdateOrganizationStageFlowStage(organizationId, stageFlowId, stageId, data);
    if (!result.data) {
      return Promise.reject(result.error);
    }
    return this.mapToInterface(result.data);
  };

  createStage = async (organizationId: number, stageFlowId: number, data: StageFlowItemUpdateDTOInterface) => {
    const result = await api.CreateOrganizationStageFlowStage(organizationId, stageFlowId, data);
    if (!result.data) {
      return Promise.reject(result.error);
    }
    return this.mapToInterface(result.data);
  };

  deleteStage = async (organizationId: number, stageFlowId: number, stageId: number) => {
    const result = await api.DeleteOrganizationStageFlowStage(organizationId, stageFlowId, stageId);
    if (typeof result.data === "undefined") {
      return Promise.reject(result);
    }
    return this.mapToInterface(result.data);
  };

  showStage = (organizationId: number, stageFlowId: number, stageId: number) => {
    const stageFlowConfig = (UISettingsService.getUISettings().stageFlow?.[organizationId + '__' + stageFlowId] || {});
    UISettingsService.updateUISettings({
      stageFlow : {
        [organizationId + '__' + stageFlowId] : {
          ...stageFlowConfig,
          stages : {
            ...(stageFlowConfig.stages || {}),
            [stageId] : {
              hiddenAt : undefined,
            },
          },
        },
      },
    });
  }

  hideStage = (organizationId: number, stageFlowId: number, stageId: number) => {
    const stageFlowConfig = (UISettingsService.getUISettings().stageFlow?.[organizationId + '__' + stageFlowId] || {});
    UISettingsService.updateUISettings({
      stageFlow : {
        [organizationId + '__' + stageFlowId] : {
          ...stageFlowConfig,
          stages : {
            ...(stageFlowConfig.stages || {}),
            [stageId] : {
              hiddenAt : new Date().toISOString(),
            },
          },
        },
      },
    });
  }

}

export const stageFlowService = new StageFlowService();