import * as React from "react";
import { Link as CustomLink } from "../../../components/DiscoverNew/UI/Link/Link";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import capitalizeWords from "../../../formatters/capitalizeWords";
import Table from "../../../components/project/Table/Table";
import { PaginationFull } from "../../../components/DiscoverNew/UI/PaginationFull/PaginationFull";
import PropTypes from "prop-types";
import { TagSelectHtml } from "../../../components/DiscoverNew/UI/SelectHtml/TagSelectHtml/TagSelectHtml";
import workableIcon from '../../../assets/images/workableIcon.png'
import { candidateService } from "../../projects/CandidateService";

import css from "./ProjectCandidatesTable.module.css";

export const ProjectCandidatesTable = (props) => {
  const {
    candidates,
    pageSize,
    currentPage,
    onChangePagination,
    onClickCandidate,
    onChangeCandidateStage,
    onSelectCandidate,
    projectStageOptions,
    selectedCandidates,
    onSelectAll,
    isAllSelected,
    uiSettings,
    selectedWorkableJob,
  } = props;
  return (
    <div className={css.container}>
      <Table
        noData={`No ${uiSettings.mappings.candidates} found`}
        selectable={!!candidates?.nodes?.length}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        selected={selectedCandidates}
        onSelect={onSelectCandidate}
        dataObject={{
          loading: false,
          error: null,
          data: {
            candidates: candidates?.nodes,
          },
        }}
        columns={[
          {
            className: css.tdName,
            dataIndex: "name",
            render: (v, item) => {
              return (
                <CustomLink className={css.candidateLink} onClick={() => onClickCandidate(item)}>
                  {Formatter.fullName(item)}
                  {item.integrations?.workable?.jobId === selectedWorkableJob.value && <img src={workableIcon} alt="Workable Icon" className={css.integrationIcon} />}
                </CustomLink>
              );
            },
          },
          {
            dataIndex: "position",
            render: (v, item) =>
              item.latestExperience?.title ? capitalizeWords(item.latestExperience?.title) : undefined,
          },
          {
            dataIndex: "company",
            render: (v, item) =>
              item.latestExperience?.company?.name ? capitalizeWords(item.latestExperience?.company?.name) : undefined,
          },
          {
            dataIndex: "stage",
            render: (v, item) => {
              return (
                <TagSelectHtml
                  isIntValue
                  style={candidateService.getStageStyle(item.stage)}
                  onChange={(stage) => onChangeCandidateStage(stage, item)}
                  value={item.stage?.stageId}
                  options={projectStageOptions}
                />
              );
            },
          },
          { className: css.tdDate, dataIndex: "createdAt", title: "Added", render: Formatter.fullDate },
        ]}
      />
      {candidates?.pageInfo?.pageCount > 1 && (
        <PaginationFull
          style={{ marginTop: 20 }}
          onChangePageSize={(pageSize) => onChangePagination({ currentPage: 1, pageSize })}
          onChangeCurrentPage={(currentPage) => onChangePagination({ currentPage })}
          currentPage={currentPage}
          pageSize={pageSize}
          pageCount={candidates?.pageInfo?.pageCount}
        />
      )}
    </div>
  );
};

ProjectCandidatesTable.propTypes = {
  candidates: PropTypes.object,
  projectStageOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePagination: PropTypes.func.isRequired,
  onChangeCandidateStage: PropTypes.func.isRequired,
  onClickCandidate: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool,
};
