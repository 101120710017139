import * as api from "../../api/apiService";
import { DateTime } from "luxon";

/**
 * @typedef ProcessSubscriptionDTO
 * @property {number} subscription_id
 * @property {number} operation_id
 */

class UserService {
  /**
   * @param {any} obj
   * @returns {UserInterface}
   */
  mapToInterface(obj) {
    let latestSubscription = null;
    if (obj.subscriptions) {
      latestSubscription = obj.subscriptions
        .slice()
        .filter((item) => item.externalId)
        .sort((a, b) => {
          const aDate = DateTime.fromISO(a.createdAt);
          const bDate = DateTime.fromISO(b.createdAt);
          return aDate.diff(bDate) > 0;
        })[0];

      if (!latestSubscription) {
        latestSubscription = obj.subscriptions.slice().sort((a, b) => {
          const aDate = DateTime.fromISO(a.createdAt);
          const bDate = DateTime.fromISO(b.createdAt);
          return aDate.diff(bDate) > 0;
        })[0];
      }
    }

    const data = {
      id: obj.id || obj.userId,
      firstName: obj.firstName,
      lastName: obj.lastName,
      email: obj.email,
      externalId: obj.externalId,
      hasActiveSubscription: obj.hasActiveSubscription,
      name: obj.name,
      organizationId: obj.organizationId,
      organizationType: obj.organizationType,
      phoneNumbers: obj.phoneNumbers,
      profileImgUrl: obj.profileImgUrl,
      subscriptionEndDate: obj.subscriptionEndDate,
      subscriptionName: obj.subscriptionName,
      uiSettings: obj.uiSettings,
      userId: obj.userId,
      createdAt: obj.createdAt,
      isAdmin: obj.role === "admin",
      // admin users fields
      organization: obj.organization,
      // @todo
      subscriptions: obj.subscriptions ? obj.subscriptions.map((item) => ({ ...item, status: "Active" })) : null,
      latestSubscription,
      // @todo
      projectsCount: 5,
      activatedAt: obj.activatedAt,
      emailConfirmed: !!obj.activatedAt,
      monthlyUnlocksRedeemed: obj.monthlyUnlocksRedeemed,
      monthlyUnlocksCount: obj.monthlyUnlocksCount === undefined ? obj.monthlyUnlockCount : obj.monthlyUnlocksCount,
      phoneNumber: obj.phoneNumber,
    };
    return data;
  }

  /**
   * @returns {Promise<UserInterface | null>}
   */
  fetchMe() {
    return api.GetCurrentUser().then((r) => (r.data ? userService.mapToInterface(r.data) : null));
  }

  /**
   * @param {ProcessSubscriptionDTO} data
   */
  processSubscription(data) {
    return api.ProcessSubscription(data).then((r) => r.data || null);
  }

  /**
   * @param {any} args
   * @returns {Promise<unknown>}
   */
  fetchAll = async (args) => {
    const usersRes = await api.AdminGetUsers(args);
    return {
      nodes: usersRes.data.items.filter(Boolean).map(this.mapToInterface),
      pageInfo: {
        pageCount: Math.max(Math.ceil(usersRes.data.count / args.limit), 1),
        total: usersRes.data.count,
      },
    };
  };

  /**
   * @param {string} id
   * @returns {Promise<unknown>}
   */
  fetchOne = async (id) => {
    const userRes = await api.AdminGetUser(id);
    return userRes?.data ? this.mapToInterface(userRes.data) : null;
  };

  /**
   * @param {string} id
   * @returns {Promise<unknown>}
   */
  delete = (id) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  /**
   * @param {UpdateProfileDTO} data}
   * @returns {Promise<void>}
   */
  updateProfile = async (data) => {
    const res = await api.UpdateUserProfile(data);
    return res;
  }

  /**
   * @param {Blob} blob
   * @param {string} extension
   * @returns {Promise<void>}
   */
  updateProfileImage = async (blob, extension) => {
    const res = await api.UploadUserProfileImage(blob, extension);
    return res;
  }

  // todo move to AdminService
  /**
   * @param {string} id
   * @param {any} data
   * @returns {Promise<unknown>}
   */
  update = async (id, data) => {
    const activateRes = await api.AdminUpdateUser(id, data);
    return activateRes.data || null;
  };

  loginAs = async (id) => {
    const activateRes = await api.AdminLoginAs(id);
    return activateRes.data || null;
  };

  activate = async (userId) => {
    const activateRes = await api.AdminActivateUser(userId);
    return activateRes.data || null;
  };

  createOrganizationSubscription = async (organizationId, data) => {
    const res = await api.AdminCreateOrganizationSubscription(organizationId, data);
    return res.data || null;
  };

  updateOrganizationSubscription = async (organizationId, subscriptionId, data) => {
    const res = await api.AdminUpdateOrganizationSubscription(organizationId, subscriptionId, data);
    return res.data || null;
  };

  getSubscriptionUrl(subscriptionId) {
    return `https://chatterworks.chargebee.com/d/subscriptions/${subscriptionId}`;
  }
}

export const userService = new UserService();
