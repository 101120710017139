import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes, { string } from "prop-types";
import { cx } from "emotion";
import { Formatter } from "../../util/Formatter";
import capitalizeWords from "../../../../formatters/capitalizeWords";
import { Tag } from "../../UI/Tag/Tag";
import { Checkbox } from "../../Form/Checkbox/Chekbox";
import { Button } from "../../UI/Button/Button";
import { Icon } from "../../UI/IconComponent/Icon";
import { DiscoverCandidateContacts } from "./DiscoverCandidateContacts/DiscoverCandidateContacts";
import { Link } from "../../UI/Link/Link";
import { useUserData } from "../../../../contexts/user";
import CandidateDetailModalWrapper from "../../../modals/CandidateDetailModalWrapper";
import { getRelativeCalendarDate } from "../../../../formatters/calendarFormatter";
import AddCandidatesToProjectModal from "../../../modals/AddCandidatesToProjectModal";

import css from "./DiscoverCandidate.module.css";
import { getUnifiedExplain, parseExplainDescription } from "../../../../utils/devtools";

export function DiscoverCandidate(props) {
  const {
    data,
    onSelect,
    isSelectable,
    isSelected,
    onUnlock,
    onCandidateUpdated,
    onAddToProject,
    canAddToProject,
  } = props;

  const [candidateModalData, setCandidateModalData] = useState();
  const [showAddToProjectModal, setShowAddToProjectModal] = useState();
  const [explanation, setExplanation] = useState();

  const {
    state: { user },
  } = useUserData();
  const uiSettings = user.uiSettings;

  const onOpenProjects = () => {
    setCandidateModalData({ candidate: data, initialPage: "projects" });
  };

  function onCandidateCardClick() {
    setCandidateModalData({ candidate: data });
    window.history.replaceState({}, "", window.location.pathname + `/` + data.id + window.location.search);
    window.analytics.track("Candidate Viewed", {
      candidate_id: data._source?.candidateId,
      state: data._source?.location?.region,
      locality: data._source?.location?.region,
      is_unlocked: data._source?.isContactInfoViewable,
    });
  }

  function onToggleModalWindow() {
    window.history.replaceState({}, "", `/discover${window.location.search}`);
    setCandidateModalData(undefined);
  }

  useEffect(() => {
    if (!window.devmode) {
      return;
    }

    if (!data?._explanation?.details?.length || !window.lastSearchResult) {
      return;
    }

    const parsedDetails = getUnifiedExplain(data);
    const explain = {
      _id: data.id,
      _name: data.firstName + " " + data.lastName,
      _score: data._explanation.value,
      ...parsedDetails,
    }

    // console.log(JSON.stringify(explain, null, 2));
    setExplanation(explain);
  }, []);

  function renderExperience(experience, index) {
    let dateRangeStr = "";
    let durationStr = "";

    let shouldHighlightPresent = (!!window.lastSearchFilters?.title && window.lastSearchFilters?.titleMode !== "allJobs") || (!!window.lastSearchFilters?.employer && window.lastSearchFilters?.companyNameMode !== "allJobs");
    shouldHighlightPresent = !!window.highlightPresent && shouldHighlightPresent;

    const present = shouldHighlightPresent ? `<span style="color: #408cf6">Present</span>` : "Present";
    const score = experience._score || 0;
    const hits = experience._hits || [];

    if (experience.startDate) {
      if (experience.endDate) {
        dateRangeStr = Formatter.dateRange(experience.startDate, experience.endDate);
        durationStr = Formatter.duration(experience.startDate, experience.endDate);
      } else if (index === "latest-experience" || index === 0) {
        dateRangeStr = `${Formatter.date(experience.startDate)} – ${present}`;
      }
      else {
        dateRangeStr = `${Formatter.date(experience.startDate)} – ${present}`;
      }
    }
    else if (shouldHighlightPresent) {
      dateRangeStr = present;
    }
    return (
      <div className={css.experienceItem} key={index}>
        <div className={css.position}>
          {experience.title ? (
            <>
              <span className={css.positionTitle}>{capitalizeWords(experience.title)}</span>
              {!!experience.company?.name && <> | {capitalizeWords(experience.company.name)}</>}
            </>
          ) : (
            !!experience.company?.name && <span>{capitalizeWords(experience.company.name)}</span>
          )}
        </div>
        {!!dateRangeStr && (
          <div className={css.dateRange} dangerouslySetInnerHTML={{__html: [dateRangeStr, durationStr].filter(Boolean).join(", ")}} />
        )}
        {!!window.devmode && !!score && <div style={{
            fontSize: "10px",
            color: "#a8a8a8",
          }}>_score: <span>{score}</span><br/>_hits: <span>{JSON.stringify(hits)}</span></div>}
      </div>
    );
  }

  function renderAddedCompanies() {
    if (!data.companies.length) {
      return null;
    }

    const company = data.companies[0];
    return (
      <Link onClick={onOpenProjects} className={css.addedCompanies}>
        in {company.name} {data.companies.length > 1 ? `(+${data.companies.length - 1})` : ""}
      </Link>
    );
  }

  let latestExperience = data.experiences?.find(item => item.isPrimary);
  if (!latestExperience) {
    latestExperience = data.experiences?.[0];
  }

  return (
    <article className={cx(props.className, css.discoverUser)}>
      {!!isSelectable && (
        <div className={css.checkboxHolder}>
          <Checkbox onClick={onSelect} checked={isSelected} />
        </div>
      )}
      <div className={css.mainSection}>
        <Button
          disabled={!canAddToProject}
          className={css.addButton}
          onClick={() => setShowAddToProjectModal(true)}
          leftIcon={<Icon name="plus" />}
        >
          Add
        </Button>
        <div className={css.title} onClick={onCandidateCardClick}>
          {Formatter.fullName(data)} {(!!window.indextest && data._score) ? (<>
            <span style={{
              color: "#a8a8a8",
            }}>{`[${data._score}]`}</span>
          </>) : (<></>)}
        </div>
        <div className={css.address}>
          {Formatter.address(data.address)}
          {renderAddedCompanies()}
        </div>
        {!!latestExperience && renderExperience(latestExperience, "latest-experience")}
        <div className={css.footer}>
          <DiscoverCandidateContacts 
            data={data} 
            onUnlock={onUnlock} 
          />
        </div>
        {!!data.lastViewedAt && (
          <div className={css.lastViewed}>
            <Icon name="calendar" />
            <span className={css.lastViewedDate}>Viewed {getRelativeCalendarDate(data.lastViewedAt)}</span>
          </div>
        )}
        { !!window.devmode && !!explanation && (
          <details style={{
            marginTop: 20,
            backgroundColor: '#f5f5f5',
            padding: 20,
            borderRadius: 5,
          }}>
            <summary><button style={{marginRight: 10}} onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(data._raw, null, 4));
            }}>_raw</button>Score: {explanation._score}</summary>
            <pre style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              maxHeight: 200,
              overflow: 'auto',
            }} dangerouslySetInnerHTML={{__html: Formatter.highlightJson(JSON.stringify(explanation, null, 4))}} />
          </details>
        )}
      </div>
      <div className={css.divider} />
      <div className={css.section}>
        <>
          <div className={css.experienceTitle}>Experience</div>
          {!!data.experiences?.length ? (
            <div className={css.experienceList}>{data.experiences.slice(0, 3).map(renderExperience)}</div>
          ) : (
            <p className={css.noData}>No experience</p>
          )}
        </>
        <div className={css.tags}>
          {!!data?._rankedSkills ? (
            <>
              {data._rankedSkills?.slice(0, 5)?.map((item) => {
                return <Tag key={item.value} theme={item._highlight ? undefined : "gray"}>{capitalizeWords(item.value)}</Tag>;
              })}
            </>
          ) : (
            <>
              {data.skills?.slice(0, 5)?.map((item) => {
                return <Tag key={item} theme={"gray"}>{capitalizeWords(item)}</Tag>;
              })}
            </>
          )}
        </div>
      </div>

      {!!candidateModalData && (
        <CandidateDetailModalWrapper
          viewModalOpenState
          onCandidateUpdated={onCandidateUpdated}
          uiSettings={uiSettings}
          candidate={candidateModalData.candidate._source}
          initialPage={candidateModalData.initialPage}
          toggleModalWindow={onToggleModalWindow}
          isNavigatorView={candidateModalData.isNavigatorView ?? true}
          searchHighlights={data._highlight}
        />
      )}

      {showAddToProjectModal && (
        <AddCandidatesToProjectModal
          candidatesCount={1}
          onSubmit={onAddToProject}
          isOpen={showAddToProjectModal}
          onClose={() => setShowAddToProjectModal(false)}
        />
      )}
    </article>
  );
}

DiscoverCandidate.defaultProps = {
  canAddToProject: true,
};

DiscoverCandidate.propTypes = {
  canAddToProject: PropTypes.bool,
  onAddToProject: PropTypes.func,
  onUnlock: PropTypes.func,
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
    }),
    socialProfiles: PropTypes.objectOf(
      PropTypes.shape({
        site: PropTypes.string,
        link: PropTypes.string,
      })
    ),
  }),
};
