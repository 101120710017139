import { cx } from "emotion";
import { DateTime } from "luxon";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import capitalizeWords from "../../../../formatters/capitalizeWords";
import { StageFlowInterface } from "../../../../screens/profile/ProfileStageFlow/StageFlowService";
import {
  CandidateNote,
  candidateService,
  ProjectCandidateInterface, ProjectInterface,
} from "../../../../screens/projects/CandidateService";
import { PermissionEnum, permissionService } from "../../../../screens/projects/PermissionService";
import { EntityConnection } from "../../../../types";
import { Checkbox } from "../../../DiscoverNew/Form/Checkbox/Chekbox";
import { ButtonHTML } from "../../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";
import { PaginationFull } from "../../../DiscoverNew/UI/PaginationFull/PaginationFull";
import { TagSelectHtml } from "../../../DiscoverNew/UI/SelectHtml/TagSelectHtml/TagSelectHtml";
import { Formatter } from "../../../DiscoverNew/util/Formatter";
import { CandidatesExportMode, CandidateExportSelect } from "../../../ui/ExportSelect/CandidateExportSelect";
import { CandidateContextMenu } from "../CandidateBoard/CandidateContextMenu/CandidateContextMenu";
import { CandidateContacts } from "../CandidatePreview/CandidateContacts/CandidateContacts";
import { CandidateSocials } from "../CandidatePreview/CandidateSocials/CandidateSocials";
import { CandidateVerificationIcon } from "../CandidateVerificationIcon/CandidateVerificationIcon";
import { UserImage } from "../../../ui/ProfileImage/UserImage";

import css from "./CandidateTable.module.css";

type Props = {
  project: ProjectInterface
  candidates: EntityConnection<ProjectCandidateInterface>
  isArchived: boolean
  selectedCandidates: Array<ProjectCandidateInterface>
  setSelectedCandidates: (candidates: Array<ProjectCandidateInterface>) => void
  stageFlow: StageFlowInterface,
  onUpdateCandidateStage: (candidate: ProjectCandidateInterface, stageId: number) => void
  onOpenProfile: (candidate: ProjectCandidateInterface) => void;
  onRemoveCandidate: (candidate: ProjectCandidateInterface) => void;
  onArchiveCandidate: (candidate: ProjectCandidateInterface) => void;
  onUnarchiveCandidate: (candidate: ProjectCandidateInterface) => void;
  onToggleArchivedCandidates: () => void
  onExport: (mode: CandidatesExportMode) => void

  pagination: {
    pageCount: number
    pageSize: number
    onChangePageSize: (pageSize: string) => void
    currentPage: number
    onChangeCurrentPage: (page: string) => void
  }
}

export const CandidateTable: React.FC<Props> = (props) => {
  const { selectedCandidates, setSelectedCandidates } = props;
  const { candidates, onUpdateCandidateStage, stageFlow, project, onOpenProfile } = props;
  const { pageCount, pageSize, currentPage, onChangePageSize, onChangeCurrentPage } = props.pagination;
  const [expandedCandidateId, setExpandedCandidateId] = React.useState<number | undefined>(undefined);
  const [expandedCandidateNotes, setExpandedCandidateNotes] = React.useState<Array<CandidateNote>>([]);

  // todo move it to top level and update candidate in fetched data
  const onExpandRow = (candidate: ProjectCandidateInterface) => {
    setExpandedCandidateId(candidate.candidateId);
    candidateService.fetchNotes(candidate.candidateId).then(notes => {
      setExpandedCandidateNotes(notes);
    });
  };

  const onCollapseRow = () => {
    setExpandedCandidateNotes([]);
    setExpandedCandidateId(undefined);
  };

  const toggleSelectAll = () => {
    if (!candidates.nodes.length) {
      return;
    }

    if (selectedCandidates.length === candidates.nodes.length) {
      setSelectedCandidates([]);
    } else {
      setSelectedCandidates([...candidates.nodes]);
    }
  };

  const toggleSelect = (candidate: ProjectCandidateInterface) => {
    if (selectedCandidates.find(item => item.candidateId === candidate.candidateId)) {
      setSelectedCandidates(selectedCandidates.filter(item => item.candidateId !== candidate.candidateId));
    } else {
      setSelectedCandidates([
        ...selectedCandidates,
        candidate,
      ]);
    }
  };

  React.useEffect(() => {
    setExpandedCandidateId(undefined);
    setSelectedCandidates([]);
  }, [currentPage, pageSize]);

  return (
    <div className={css.holder}>
      <table className={css.table}>
        <thead>
          <tr>
            <th className={css.tdCheckbox}>
              <Checkbox className={css.checkbox} onClick={toggleSelectAll}
                        checked={selectedCandidates.length === candidates.nodes.length} />
            </th>
            <th className={css.tdName}>Name</th>
            {/* todo [candidates] [feature] */}
            {/*<th><Icon name="filter" /></th>*/}
            <th className={css.tdLocation}>Location</th>
            <th className={css.tdHeadline}>Headline</th>
            <th className={css.tdStage}>Stage</th>
            <th className={css.tdLastActivity}>Last Activity</th>
            <th className={css.tdActions} />
          </tr>
        </thead>
        <tbody>
          {candidates.nodes.map((candidate, index) => {
            const address = candidateService.getAddressFromLocation(candidate.location);
            const dateRaw = candidate.lastStageChangedAt || candidate.createdAt;
            const date = dateRaw ? DateTime.fromISO(dateRaw) : null;
            return (
              <React.Fragment key={candidate.candidateId}>
                <tr className={css.tr}>
                  <td className={css.tdCheckbox}>
                    <Checkbox className={css.checkbox} onClick={() => toggleSelect(candidate)}
                              checked={!!selectedCandidates.find(item => item.candidateId === candidate.candidateId)} />
                  </td>
                  <td className={css.tdName}>
                    <span className={css.name} onClick={() => onOpenProfile(candidate)}>
                      <UserImage data={candidate}
                                 style={{ display : "inline-block", verticalAlign : "middle", marginRight : 8 }} />
                      {Formatter.fullName(candidate)}
                    </span>
                    <CandidateVerificationIcon style={{ position : "relative", top : 2, marginLeft : 4 }}
                                               candidate={candidate} />
                    {!!candidate.integrations?.workable &&
                      <Icon style={{ position : "relative", top : 2, marginLeft : 6 }} name="workable" />}
                    <ButtonHTML
                      className={css.actionsItem}
                      onClick={() => expandedCandidateId === candidate.candidateId ? onCollapseRow() : onExpandRow(candidate)}
                    >
                      <span style={{
                        position : "relative", top : -2, marginLeft : 16,
                        ...(candidate.candidateId === expandedCandidateId ? {
                          transform : "rotate(180deg)",
                          display : "inline-block",
                        } : {}),
                      }}
                      >
                        <Icon name="chevron-thin" />
                      </span>
                    </ButtonHTML>
                  </td>
                  {/* @todo [candidates] [feature] */}
                  {/*<td>-</td>*/}
                  <td className={css.tdLocation}>{Formatter.address(address)}</td>
                  <td className={css.tdHeadline}>{candidateService.getHeadlineText(candidate)}</td>
                  <td className={css.tdStage}>
                    {!!stageFlow && candidate.stage ? (
                      <TagSelectHtml
                        isIntValue
                        disabled={!permissionService.can(project, PermissionEnum.projectUpdateCandidateStage)}
                        style={candidateService.getStageStyle(candidate.stage)}
                        onChange={(stage) => onUpdateCandidateStage(candidate, stage.value)}
                        value={candidate.stage.stageId}
                        options={stageFlow.stages.map(item => ({ value : item.stageId, label : item.name }))}
                      />
                    ) : (
                      "–"
                    )}
                  </td>
                  <td
                    className={css.tdLastActivity}>{date ? <>{date.toFormat("LLL dd, h:mm")}{date.toFormat("a").toLowerCase()}</> : "–"}</td>
                  <td className={css.tdActions}>
                    <div className={css.actionsList}>
                      <CandidateContextMenu
                        project={project}
                        candidate={candidate}
                        onArchive={() => props.onArchiveCandidate(candidate)}
                        onUnarchive={() => props.onUnarchiveCandidate(candidate)}
                        onRemove={() => props.onRemoveCandidate(candidate)}
                        onChangeStage={(stageId) => onUpdateCandidateStage(candidate, stageId)}
                      />
                    </div>
                  </td>
                </tr>
                {candidate.candidateId === expandedCandidateId && (
                  <React.Fragment>
                    <tr className={css.detailsTr}>
                      <td />
                      <td colSpan={2} className={css.detailsTdContent}>
                        <div className={css.detailsSectionTitle}>
                          Current position
                        </div>
                        {candidate.latestExperience && (
                          <p className={css.detailsSectionText}>
                            {candidate.latestExperience.title ? (
                              <>
                                <span>{capitalizeWords(candidate.latestExperience.title)}</span>
                                {!!candidate.latestExperience.company?.name && <> | {capitalizeWords(candidate.latestExperience.company.name)}</>}
                              </>
                            ) : (
                              !!candidate.latestExperience.company?.name &&
                              <span>{capitalizeWords(candidate.latestExperience.company.name)}</span>
                            )}
                          </p>
                        )}
                      </td>
                      <td>
                        <div className={css.detailsSectionTitle}>
                          Contacts
                        </div>
                        <div>
                          <CandidateContacts style={{ paddingLeft : 2 }} data={candidate} />
                        </div>
                      </td>
                      <td>
                        <div className={css.detailsSectionTitle}>
                          Social Media
                        </div>
                        <div>
                          <CandidateSocials data={candidate} />
                        </div>
                      </td>
                      <td colSpan={2} />
                    </tr>
                    <tr className={css.detailsTr}>
                      <td />
                      <td colSpan={6} className={css.detailsTdContent}>
                        <div className={css.detailsSectionTitle}>
                          Notes
                        </div>
                        <p className={css.detailsSectionText}>
                          {expandedCandidateNotes?.[0]?.note || "–"}
                        </p>
                      </td>
                    </tr>
                    <tr className={cx(css.detailsTr, css.last)}>
                      <td />
                      <td colSpan={6} className={css.detailsTdContent}>
                        {/* todo [candidate] [feature] */}
                        <div className={css.detailsLinks}>
                          {/*<Link to={`/candidate/${candidate.candidateId}`}>Go to profile</Link>*/}
                          <a href="#" onClick={() => onOpenProfile(candidate)}>Open profile</a>
                          {/*<div className={css.detailsLinksDivider}>|</div>*/}
                          {/*<a href='#'>Send message</a>*/}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <PaginationFull
        leftChildren={
          <CandidateExportSelect
            onChange={props.onExport}
            label={
              <ButtonHTML style={{ marginLeft : 12 }} className={css.footerItem} disabled={props.isArchived}>
                <Icon name="arrow-down-to-line" className={css.footerItemIcon} />
                Export CSV
              </ButtonHTML>
            }
          />
        }
        rightChildren={
          props.isArchived ?
            <Link to={`/my-candidates/${project.projectId}`} style={{ marginRight : 12 }}
                  className={css.footerItem} onClick={props.onToggleArchivedCandidates}>
              All Candidates
            </Link> :
            <Link to={`/my-candidates/${project.projectId}?isArchived=true`} style={{ marginRight : 12 }}
                  className={css.footerItem} onClick={props.onToggleArchivedCandidates}>
              <Icon name="archive" className={css.footerItemIcon} />
              Archived Candidates
            </Link>
        }
        className={css.pagination}
        // it's intentionally, because we don't need to show pages count
        pageCount={0}
        pageSize={pageSize}
        onChangePageSize={onChangePageSize}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
      />
    </div>
  );
};