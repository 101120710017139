import React from "react";
import { isFunction } from "lodash";
import { cx, css } from "emotion/macro";

import { xs } from "../../css/mediaQueries";

/// ModalInputField
///
/// label: React Element (or HTML element) used to describe the input
/// placeholder: Message to help instruct user
/// value: String input value

/// onChange: Callback function for detecting input changes
/// onBlur: Callback function when losing input focus
/// onFocus: Callback function when focusing on the input

/// autocomplete: String to determine whether browser provides suggestions
/// position: Position in the form you are creating. Default 1
/// disabled: Boolean for determining whether input is disabled
/// children: React/HTML elements (for example company options suggestions)

/// additionalClasses: emotion wrapped classes as shown below
/// Example: cx("classOne", "classTwo", css`text-align: center`,{ [styles.disabledLabel]: selectedCompany && selectedCompany?.website })

const ModalInputField = React.forwardRef((props, ref) => {
  const {
    style,
    containerStyle,
    label,
    placeholder = "",
    value,
    onChange,
    onBlur,
    onFocus,
    component,
    autocomplete = "on",
    position = -1,
    disabled = false,
    children,
    additionalClasses = "",
  } = props;
  const updateValue = (e) => {
    if (!isFunction(onChange)) return;
    onChange(e);
  };

  const handleOnBlur = (e) => {
    if (!isFunction(onBlur)) return;
    onBlur(e);
  };

  const handleOnFocus = (e) => {
    if (!isFunction(onFocus)) return;
    onFocus(e);
  };

  const Component = component || "input";

  return (
    <div className={modalInputFieldStyles.holder} ref={ref} style={containerStyle}>
      {label}
      <Component
        style={style}
        className={cx(modalInputFieldStyles.input, additionalClasses)}
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => updateValue(e)}
        onBlur={(e) => handleOnBlur(e)}
        onFocus={(e) => handleOnFocus(e)}
        autoComplete={autocomplete}
        disabled={disabled}
        position={position}
      />
      {children}
      <div className={cx("modal-input-spacer", modalInputFieldStyles.spacer)} />
    </div>
  );
});

export const modalInputFieldStyles = {
  holder: css`
    width: 100%;
    position: relative;
    &:last-child .modal-input-spacer {
      background-color: black;
      display: none;
    }
    ${xs} {
      padding-bottom: 5px;
    }
  `,
  input: css`
    width: 100%;
    height: 34px;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    border: solid 1px #9b9b9b;

    &::placeholder {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.57px;
      color: #a8a8a8;
    }
    &:focus {
      border: 1px solid #408cf6;
    }
  `,
  disabledLabel: css`
    opacity: 0.5;
    color: #373737;
  `,
  spacer: css`
    height: 29px;
    ${xs} {
      height: 20px;
    }
  `,
};

export default ModalInputField;
