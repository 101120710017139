import * as React from "react";
import { Link } from "react-router-dom";
import { UserImage } from "../../../../components/ui/ProfileImage/UserImage";
import { ContextMenuDropdown } from "../../../../components/DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";
import { Formatter } from "../../../../components/DiscoverNew/util/Formatter";
import { Pill } from "../../../../components/ui/Pill/Pill";
import { StageFlowInterface } from "../StageFlowService";

import css from "./StageFlowTable.module.css";

type Props = {
  stageFlows: Array<StageFlowInterface>
  onDelete: (stageFlowId: number) => void
  onEdit: (stageFlow: StageFlowInterface) => void
  onArchive: (stageFlow: StageFlowInterface) => void
  onUnarchive: (stageFlow: StageFlowInterface) => void
  onDuplicate: (stageFlow: StageFlowInterface) => void
}

export const StageFlowTable: React.FC<Props> = (props) => {
    const { stageFlows } = props;
    return (
      <table className={css.table}>
        <thead>
          <tr>
            <th className={css.tdName}>Name</th>
            <th>Owner</th>
            <th>Projects</th>
            <th>Status</th>
            <th>Date Updated</th>
            <th>Date Created</th>
            <th className={css.tdActions} />
          </tr>
        </thead>
        <tbody>
          {stageFlows.map((stageFlow, index) => {
            return (
              <tr key={stageFlow.stageFlowId} className={css.tr}>
                <td className={css.tdName}>
                  <Link to={`/profile/interview-process/${stageFlow.stageFlowId}`} className={css.link}>{stageFlow.name}</Link>
                </td>
                <td>
                  {stageFlow.createdBy ? <div style={{ paddingLeft: 6 }}><UserImage data={stageFlow.createdBy} /></div> : <div style={{ paddingLeft: 14 }}>–</div>}
                </td>
                <td>
                  {stageFlow.projects?.length || 0}
                </td>
                <td>
                  {stageFlow.archivedAt ? (
                    <Pill style={{ color : '#868686', backgroundColor : "rgba(134,134,134,0.2)" }}>Archived</Pill>
                  ) : (
                    <Pill style={{ color: '#72BB42', backgroundColor: 'rgba(114, 187, 66, .1)'  }}>Active</Pill>
                  )}
                </td>
                <td>{stageFlow.updatedAt ? Formatter.fullDate(stageFlow.updatedAt) : '–'}</td>
                <td>{stageFlow.createdAt ? Formatter.fullDate(stageFlow.createdAt) : '–'}</td>
                <td className={css.tdActions}>
                  <ContextMenuDropdown>
                    <ContextMenuDropdown.Item icon='copy' onClick={() => props.onDuplicate(stageFlow)}>
                      Duplicate
                    </ContextMenuDropdown.Item>
                    {!stageFlow.isDefault && (
                      <>
                        <ContextMenuDropdown.Item icon="pencil-2" onClick={() => props.onEdit(stageFlow)}>
                          Edit
                        </ContextMenuDropdown.Item>
                        {stageFlow.archivedAt ? (
                          <ContextMenuDropdown.Item icon="archive" onClick={() => props.onUnarchive(stageFlow)}>
                            Unarchive
                          </ContextMenuDropdown.Item>
                        ) : (
                          <ContextMenuDropdown.Item icon="archive" onClick={() => props.onArchive(stageFlow)}>
                            Archive
                          </ContextMenuDropdown.Item>
                        )}
                        <ContextMenuDropdown.DangerItem icon="fa-trash" onClick={() => props.onDelete(stageFlow.stageFlowId)}>
                          Delete
                        </ContextMenuDropdown.DangerItem>
                      </>
                    )}
                  </ContextMenuDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
;