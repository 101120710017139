import { USER_INACTIVE_MESSAGE } from "../constants/constants";
import { authService } from "../components/DiscoverNew/services/AuthService";
import { default as axios } from "axios";
import { authProviderInstance } from "../contexts/AuthProvider";

// This interceptor will be used to redirect the user to
// the suspended page, if their account is inactive.
export const handleResponseAccountSuspended = {
  onSuccess: (response) => response,
  onError: (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response?.data) {
      const { code, message } = error.response.data;
      if (code === 403 && message === USER_INACTIVE_MESSAGE && window.location.pathname !== "/suspended") {
        // Only redirect if we are not on the page
        window.location.replace("/suspended");
        return;
      }
    }
    return Promise.reject(error);
  },
};

export const handleRequestAccessToken = {
  onSuccess: (config) => {
    const tokenData = authService.getTokenData();
    if (!tokenData) {
      return config;
    }

    config.headers = {
      Authorization: `Bearer ${tokenData.access_token}`,
      Accept: "application/json",
      ...(config.headers || {}),
    };
    return config;
  },
  onError: (error) => Promise.reject(error),
};

export const handleResponseInvalidAccessToken = {
  onSuccess: (response) => response,
  onError: async (error) => {
    const originalRequest = error.config;
    if (error.response?.data?.message === "invalid access token") {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const tokenData = authService.getTokenData();
        try {
          const updatedTokenData = await authService.refreshAccessToken(tokenData.refresh_token);
          originalRequest.headers.Authorization = "Bearer " + updatedTokenData.access_token;
          return axios(originalRequest);
        } catch (err) {
          authProviderInstance.logout();
        }
      } else {
        authProviderInstance.logout();
      }
    }
    return Promise.reject(error);
  },
};
