import React from "react";

function NoteFooterDetail({ note }) {
  const date = new Date(note.createdAt);

  const dateFormatted = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const timeFormatted = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  });

  return (
    <div className="note-footer-detail-container">
      <span className="note-footer-note">{note.note}</span>
      <span className="note-footer-detail-info">{`${note?.author} (${dateFormatted} @ ${timeFormatted}) `}</span>
    </div>
  );
}

export default NoteFooterDetail;
