import React from "react";
import { take } from "lodash";
import { cx, css } from "emotion/macro";

import { lgU } from "../../css/mediaQueries";

function RowListItem({
  entries = [],
  viewAll = true,
  maxEntries = 3,
  emptyListMessage = "",
  onListItemNameClick = () => {},
  handleViewMore = () => {},
}) {
  const displayedEntries = take(entries, maxEntries);

  return (
    <div className={cx("mobile-experience-container", styles.listContainer)}>
      {displayedEntries.length > 0 ? (
        <>
          <ul className={cx("company-project-list", styles.companyProjectList)}>
            {displayedEntries.map((project, key) => {
              return (
                <li className={cx(styles.listItemContainer)} key={key}>
                  <div className={cx(styles.rowListItemName)}>
                    <span onClick={(e) => onListItemNameClick(e, project)} className={cx(styles.itemNameSpan)}>
                      {project?.name}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div
            className={cx(css`
              display: flex;
              ${lgU} {
                padding-left: 3px;
              }
            `)}
          >
            <span
              onClick={(e) => handleViewMore(e)}
              className={cx(
                "view-more-projects",
                "row-action-button-text",
                css`
                  font-family: Montserrat-Medium !important;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: -0.33px;
                  color: #4a4a4a;
                  cursor: pointer;
                `
              )}
            >
              View {entries?.length <= maxEntries ? "" : entries?.length - maxEntries} More
            </span>
          </div>
        </>
      ) : (
        <div className={cx(styles.emptyListMessage)}>
          <span>{emptyListMessage}</span>
        </div>
      )}
    </div>
  );
}

const styles = {
  companyProjectList: css`
    padding-left: 20px;
  `,
  listItemContainer: css`
    margin-bottom: 3px;
  `,
  rowListItemName: css`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.61px;
    color: #373737;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `,
  itemNameSpan: css`
    word-break: break-word;
    &:hover {
      cursor: pointer;
      color: #208bfe;
    }
  `,
  experienceSpanContainer: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `,
  emptyListMessage: css`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #373737;
    text-align: center;
  `,
};

export default RowListItem;
