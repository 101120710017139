import React from "react";
import { Button } from "react-bootstrap";
import { cx, css } from "emotion/macro";

import { xs } from "../../css/mediaQueries";
import Spinner from "../spinner/Spinner.js";

/// Modal Button Container houses the Button and Spinner
///
/// isDisabled: Boolean for determining whether button is disabled
/// isWaiting: Boolean for determining the Spinner state
/// onClick: Callback function when clicking the save button
/// title: Submit button title
function ModalButtonContainer({ onClick, isDisabled = false, isWaiting = false, title = "Save" }) {
  return (
    <div className={cx("modal-button-container", styles.modalButtonContainer)}>
      <div className={cx(styles.buttonContainer)} style={{ display: isWaiting ? "none" : "" }}>
        <Button
          className={css`
            font-size: 14px !important;
            font-family: Montserrat;
            width: 100%;
            height: 39px;
            border-radius: 20px !important;
          `}
          onClick={onClick}
          variant="outline-primary"
          color="primary"
          disabled={isDisabled}
        >
          {title}
        </Button>
      </div>
      <div className={cx({ [styles.modalSpinner]: isWaiting }, { [styles.modalSpinnerHidden]: !isWaiting })}>
        <Spinner />
      </div>
    </div>
  );
}

const styles = {
  modalButtonContainer: css`
    ${xs} {
      width: 100%;
    }
  `,
  buttonContainer: css`
    margin: 50px auto 0 auto;
    width: 240px;
    height: 39px;
    ${xs} {
      width: 100%;
      margin-top: 100px;
    }
  `,
  modalSpinner: css`
    width: 240px;
    height: 39px;
    margin: 20px auto 0 auto;
  `,

  modalSpinnerHidden: css`
    display: none;
  `,
};
export default ModalButtonContainer;
