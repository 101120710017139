import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";
import { cx, css } from "emotion/macro";

import * as api from "../../api/apiService";
import { useModalNavigator } from "../../contexts/modalNavigator";
import { useUserData } from "../../contexts/user";

import "./transitions.css";
import downloadIcon from "../../assets/images/download.svg";
import shareIcon from "../../assets/images/share.svg";
import * as globals from "../../constants/constants";

import { canExportToCSV } from "../../utils/projectFeatures";
import { candidateService } from "../../screens/projects/CandidateService";

export const modalHeight = "90vh";

const CandidateActions = ({ currentCandidate }) => {
  const shareCandidateDetails = async () => {
    if (!currentCandidate?.candidateId) return;
    const deepLink = `${window.location.origin}/candidates/${currentCandidate?.candidateId}`;

    // Create fake element in order to utilize document.execCommand("copy")
    var el = document.createElement("textarea");
    document.body.appendChild(el);
    el.value = deepLink;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    //Show toast for copy clipboard success
    setIsToastOpen(true);
  };

  const downloadCandidatePDF = async () => {
    if (!currentCandidate?.candidateId) return;
    candidateService.downloadPDF(currentCandidate);
  };

  const [isToastOpen, setIsToastOpen] = useState(false);

  return (
    <>
      <span className={styles.downloadIconSpan} onClick={(e) => downloadCandidatePDF()}>
        <img src={downloadIcon} title="Download PDF" alt="Download PDF" className={styles.downloadShareIcon} />
      </span>
      <span className={styles.shareIconSpan} onClick={(e) => shareCandidateDetails()}>
        <img src={shareIcon} title="Share" alt="Share" className={styles.downloadShareIcon} />
      </span>
      {isToastOpen && (
        <Toast
          onClose={() => setIsToastOpen(false)}
          show={isToastOpen}
          delay={1000}
          autohide
          className={styles.toastContainer}
        >
          <Toast.Body className={styles.toastContent}>Candidate link copied to clipboard!</Toast.Body>
        </Toast>
      )}
    </>
  );
};

const ProjectActions = ({ currentUser, currentProject }) => {
  const projectHasCandidates = currentProject?.candidates?.length > 0;

  const downloadProjectCSV = async () => {
    if (!currentProject?.projectId || !projectHasCandidates) {
      return;
    }

    try {
      const { data: project } = await api.DownloadProjectCSV(currentProject?.projectId);
      let url = window.URL.createObjectURL(project);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${currentProject?.name}.csv`.replace(/[^a-zA-Z0-9_\-\. ]/g, "");
      a.click();
    } catch (e) {
      console.error("could not download project detail csv");
    }
  };

  return (
    <>
      <span
        className={cx(
          styles.downloadIconSpan,
          css`
            &:hover {
              cursor: ${projectHasCandidates ? "pointer" : "initial"};
            }
          `
        )}
        onClick={downloadProjectCSV}
      >
        <img
          src={downloadIcon}
          title="Export to CSV"
          alt="Export to CSV"
          className={cx(
            styles.downloadShareIcon,
            css`
              margin-right: 27px;
              display: ${canExportToCSV(currentUser) ? "initial" : "none"};
            `
          )}
        />
      </span>
    </>
  );
};

function ModalNavigatorActions({ currentView = null }) {
  const {
    state: { currentProject, currentCandidate },
  } = useModalNavigator();
  const {
    state: { user },
  } = useUserData();

  const currentScreen = currentView?.currentView;

  const renderHeaderComponent = () => {
    const modalScreenName = currentView?.currentView;
    switch (modalScreenName) {
      case globals.CANDIDATE_MODAL_NAME:
        return <CandidateActions currentCandidate={currentCandidate} />;
      case globals.PROJECT_MODAL_NAME:
        return <ProjectActions currentUser={user} currentProject={currentProject} />;
      default:
        return "";
    }
  };

  return (
    <>
      {!currentScreen || currentScreen === globals.COMPANY_MODAL_NAME ? (
        ""
      ) : (
        <div className={styles.actionsContainer}>{renderHeaderComponent()}</div>
      )}
    </>
  );
}

const styles = {
  actionsContainer: css`
    display: flex;
    justifty-content: center;
    align-items: center;
    float: right;
    padding-top: 14px;
  `,
  downloadIconSpan: css`
    color: #27a0fe;
    cursor: pointer;
  `,
  shareIconSpan: css`
    cursor: pointer;
    margin-left: 15px;
  `,
  downloadShareIcon: css`
    width: 12px;
    height: 15px;
  `,
  toastContainer: css`
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 1000;
  `,
  toastContent: css`
    background-color: black !important;
    opacity: 0.7;
    height: 25px;
    padding: 10px !important;
    font-size: 12px;
    color: white !important;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
  `,
};

export default ModalNavigatorActions;
