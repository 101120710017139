import * as React from "react";
import { userService } from "../../projects/UserService";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import { Loading } from "../../../components/DiscoverNew/UI/Loading/Loading";
import UserModal from "../../../components/modals/UserModal";
import { BackButton } from "../../../components/ui/BackButton/BackButton";
import { InfoTable } from "../../../components/ui/InfoTable/InfoTable";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";
import { SubscriptionModal } from "../../../components/modals/SubscriptionModal";
import { AdminUserSubscription } from "./AdminUserSubscription/AdminUserSubscription";
import { Tabs } from "../../../components/ui/Tabs/Tabs";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ContextMenuDropdown } from "../../../components/DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";

import css from './AdminUser.module.css'
import { ModalEdit } from "../../../components/DiscoverNew/DiscoverNew/DiscoverFilters/ModalEdit";
import { Dropdown } from "../../../components/DiscoverNew/UI/Dropdown/Dropdown";
import { authService } from "../../../components/DiscoverNew/services/AuthService";
import { useAuthController } from "../../../contexts/AuthProvider";

export class AdminUser extends React.Component {
  cleanStyleOverride;

  constructor(props) {
    super(props);
    this.state = {
      initializing : true,
      activating : false,
      user : undefined,
      isEditUserModalOpen : false,
      isCreditsModalOpen : false,
      isCreateSubscriptionModalOpen : false,
      selectedSubscription : false,
    };
  }

  async componentDidMount() {
    this.cleanStyleOverride = injectStyleOverride();
    try {
      await this.fetchUser();
      this.setState({ initializing : false });
    } catch (err) {
      this.setState({ initializing : false });
      console.error(`couldn't fetch the user`, err);
    }
  }

  componentWillUnmount() {
    this.cleanStyleOverride();
  }

  fetchUser = async () => {
    const user = await userService.fetchOne(this.props.match.params.userId);
    this.setState({ user });
  };

  onEditSubscription = (subscription) => this.setState({ selectedSubscription : subscription });
  onCloseEditSubscription = () => this.setState({ selectedSubscription : undefined });
  onSubscriptionUpdated = () => {
    this.fetchUser();
    this.onCloseEditSubscription();
  };

  onOpenCreateSubscription = () => this.setState({ isCreateSubscriptionModalOpen : true });
  onCloseCreateSubscription = () => this.setState({ isCreateSubscriptionModalOpen : false });
  onSubmitCreateSubscription = () => {
    this.fetchUser();
    this.onCloseCreateSubscription();
  };

  onEditUser = () => this.setState({ isEditUserModalOpen : true });
  onCloseEditUser = () => this.setState({ isEditUserModalOpen : false });
  onUserUpdated = () => {
    this.onCloseEditUser();
    this.fetchUser();
  };

  onEditCredits = () => this.setState({ isCreditsModalOpen : true });
  onCloseEditCredits = () => this.setState({ isCreditsModalOpen : false });
  onSubmitEditCredits = async (values) => {
    this.onCloseEditCredits();
    const { firstName, lastName, email } = this.state.user;
    await userService.update(this.props.match.params.userId, {
      firstName,
      lastName,
      email,
      monthlyUnlocksCount : parseInt(values.credits)
    });
    this.fetchUser();
  };


  onLogin = async (close) => {
    close();
    const res = await userService.loginAs(this.state.user.id);
    authService.pauseSessionAndLoginAs(res, this.props.currentUser);
  }

  onActivate = async () => {
    const { confirm, notifications } = this.props;
    if (this.state.activating) {
      return;
    }

    const isConfirmed = await confirm.open({
      content : <>Are you sure you want to activate {Formatter.fullName(this.state.user)}?</>,
      confirmButtonTitle : "Activate",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      this.setState({ activating : true });
      await userService.activate(this.state.user.id);
      this.setState({ activating : false });
      this.fetchUser();
      notifications.showSuccess(`User has been activated!`);
    } catch (err) {
      this.setState({ activating : false });
      console.error("Couldn't activate the user", err);
      notifications.showError(`Couldn't activate the user!`);
    }
  };

  onCancelSubscription = async (subscription) => {
    const { confirm } = this.props;
    const isConfirmed = await confirm.open({
      content : (
        <>
          Are you sure you want to cancel the{" "}
          <a href={userService.getSubscriptionUrl(subscription.externalId)} rel="noopener noreferrer" target="_blank">
            {subscription.externalId}
          </a>{" "}
          subscription?
          <br />
          The user will still have access until <b>{Formatter.fullDate(subscription.endDate)}</b>
        </>
      ),
      destructive : true,
      cancelButtonTitle : "Dismiss",
      confirmButtonTitle : "Cancel Subscription",
    });
    if (!isConfirmed) {
      return;
    }
  };

  render() {
    const { initializing, user, isEditUserModalOpen, isCreditsModalOpen, isCreateSubscriptionModalOpen, activating, selectedSubscription } = this.state;

    if (initializing) {
      return (
        <div className="app">
          <div className="max-width">
            <Loading />
          </div>
        </div>
      );
    }

    const creditsAvailable = user.monthlyUnlocksCount - (user.monthlyUnlocksRedeemed || 0);
    const hasCreditsAvailable = creditsAvailable > 0;

    return (
      <div className="app">
        <div className="max-width">
          <BackButton to="/admin/userss" />
          <div style={{ display : "flex", justifyContent : "space-between", marginBottom : 10 }}>
            <h1 className={css.title}>{Formatter.fullName(user)}</h1>
            <ContextMenuDropdown
              contentStyle={{ width: 'unset' }}
              label={<ContextMenuDropdown.ActionButton />}
            >
              <ContextMenuDropdown.Item icon='pencil-2' onClick={this.onEditCredits}>
                Edit Credits
              </ContextMenuDropdown.Item>
              <ContextMenuDropdown.Item icon='pencil-2' onClick={this.onEditUser}>
                Edit Profile
              </ContextMenuDropdown.Item>
              <ContextMenuDropdown.Item icon='fa-plus' onClick={this.onOpenCreateSubscription}>
                New Subscription
              </ContextMenuDropdown.Item>
              <Dropdown.Divider/>
              <ContextMenuDropdown.Item icon='person' onClick={this.onLogin}>
                Log In to Account
              </ContextMenuDropdown.Item>
            </ContextMenuDropdown>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start'}}>
            <InfoTable>
              <tr>
                <td>Email:</td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>Phone number</td>
                <td>{user.phoneNumber}</td>
              </tr>
              <tr>
                <td>Organization:</td>
                <td>{user.organization?.name || "n/a"}</td>
              </tr>
              <tr>
                <td>Activated:</td>
                <td>
                  {user.activatedAt ? (
                    Formatter.fullDate(user.activatedAt)
                  ) : (
                    <>No {<Link onClick={this.onActivate}>{activating ? "(activating...)" : "(activate)"}</Link>}</>
                  )}
                </td>
              </tr>
              <tr>
                <td>Created:</td>
                <td>{Formatter.fullDateTime(user.createdAt)}</td>
              </tr>
            </InfoTable>
            <InfoTable style={{ marginLeft: 40 }}>
              <tr>
                <td>Total Credits:</td>
                <td>{user.monthlyUnlocksCount}</td>
              </tr>
              <tr>
                <td>Used Credits:</td>
                <td>{user.monthlyUnlocksRedeemed}</td>
              </tr>
              {!!user.monthlyUnlocksCount && (
                <tr>
                  <td>Available Credits:</td>
                  <td style={{ color: hasCreditsAvailable ? '' : '#f83e3e'}}>{creditsAvailable}</td>
                </tr>
              )}
            </InfoTable>
          </div>
          <Tabs style={{ marginTop : 30, marginBottom : 20 }}>
            <Tabs.Tab
              style={{ minWidth: 185}}
              component={Link}
              to={`/admin/users/${user.id}/subscriptions`}
              active={this.props.history.location.pathname === `/admin/users/${user.id}/subscriptions`}
            >
              Subscriptions
            </Tabs.Tab>
          </Tabs>
          <Switch>
            <Route path="/admin/users/:userId/subscriptions" component={() =>
              <AdminUserSubscription
                onCancelSubscription={this.onCancelSubscription}
                onEditSubscription={this.onEditSubscription}
                data={user?.subscriptions}
              />}
            />
            <Redirect to={`/admin/users/:userId/subscriptions`} />
          </Switch>
          {isEditUserModalOpen && (
            <UserModal onClose={this.onCloseEditUser} onSuccess={this.onUserUpdated} userId={user.id} />
          )}
          {isCreditsModalOpen && (
            <ModalEdit submitButtonTitle="Update" onSubmit={this.onSubmitEditCredits} onClose={this.onCloseEditCredits} title={
              <>
                Edit Monthly Credits<br/><span style={{ fontSize: '14px' }}>(available: {creditsAvailable})</span>
              </>
            }
                       config={[{ name : "credits", label : "Monthly Credits", defaultValue : user.monthlyUnlocksCount, type: 'text' }]}
            />
          )}
          {selectedSubscription && (
            <SubscriptionModal
              onClose={this.onCloseEditSubscription}
              onSuccess={this.onSubscriptionUpdated}
              data={selectedSubscription}
            />
          )}
          {isCreateSubscriptionModalOpen && (
            <SubscriptionModal
              data={{ organizationId : user.organization.id }}
              onClose={this.onCloseCreateSubscription}
              onSuccess={this.onSubmitCreateSubscription}
            />
          )}
        </div>
      </div>
    );
  }
}

AdminUser = (function withUserReducer(WrappedComponent) {
  return function(props) {
    const notifications = useNotifications();
    const history = useHistory();
    const { user } = useAuthController();
    return <WrappedComponent {...props} history={history} notifications={notifications} currentUser={user} />;
  };
})(AdminUser);

AdminUser = withConfirm(AdminUser);
