import * as React from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { Button } from "../../../components/DiscoverNew/UI/Button/Button";
import { ButtonHTML } from "../../../components/DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Loading } from "../../../components/DiscoverNew/UI/Loading/Loading";
import { withConfirm, WithConfirmProps } from "../../../components/hoc/withConfirm";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { BackButton } from "../../../components/ui/BackButton/BackButton";
import { Tabs } from "../../../components/ui/Tabs/Tabs";
import { useAuthController } from "../../../contexts/AuthProvider";
import { ModalStageFlow } from "./ModalStageFlow";
import { ModalStageFlowStageEdit } from "./ModalStageFlowStageEdit/ModalStageFlowStageEdit";

import css from "./ProfileStage.module.css";
import { ProjectsTable } from "./ProjectsTable/ProjectsTable";
import {
  StageFlowInterface,
  StageFlowItemInterface,
  StageFlowItemUpdateDTOInterface,
  stageFlowService,
  StageFlowUpdateDTOInterface,
} from "./StageFlowService";
import { StagesTable } from "./StagesTable/StagesTable";

type Props = WithConfirmProps & {
  history: any; // todo
  match: any; // todo
};

const ProfileStageCore: React.FC<Props> = (props) => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);

  const authController = useAuthController();
  const user = authController.user as any;

  const [stageFlow, setStageFlow] = React.useState<StageFlowInterface>();
  const [originalStageFlow, setOriginalStageFlow] = React.useState<StageFlowInterface>();

  const [stageToEdit, setStageToEdit] = React.useState<StageFlowItemInterface>();
  const [isOpenStageFlowModal, setIsOpenStageFlowModal] = React.useState(false);
  const [isOpenStageModal, setIsOpenStageModal] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [currentView, setCurrentView] = React.useState<"stages" | "projects">("stages");

  const notifications = useNotifications();

  function fetchData() {
    return stageFlowService
      .fetchOne(user.organizationId, parseInt(props.match.params.id))
      .then((stageFlow) => {
        setStageFlow(stageFlow);
        setOriginalStageFlow(stageFlow);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    fetchData().then((r) => {
      setLoading(false);
    });
  }, []);

  const onCreate = async (data: any) => {
    if (stageToEdit) {
      await stageFlowService.updateStage(user.organizationId, stageFlow!.stageFlowId, stageToEdit.stageId, data);
      setStageToEdit(undefined);
      setIsOpenStageModal(false);
      setTimeout(fetchData, 300);
      notifications.showSuccess("The stage has been updated!");
    } else {
      await stageFlowService.createStage(user.organizationId, stageFlow!.stageFlowId, data);
      setIsOpenStageModal(false);
      setTimeout(fetchData, 300);
      notifications.showSuccess("The stage has been created!");
    }
  };

  const onInlineEditUpdate = async (stageId: number, data: StageFlowItemUpdateDTOInterface) => {
    setStageFlow({
      ...stageFlow!,
      stages: stageFlow!.stages.map((stage) => {
        return stage.stageId === stageId ? { ...stage, ...data } : stage;
      })
    })
    await stageFlowService.updateStage(user.organizationId, stageFlow!.stageFlowId, stageId, data);
    fetchData();
    notifications.showSuccess("The stage has been updated!");
  }

  const onEdit = async (stageId: number) => {
    setStageToEdit(stageFlow!.stages.find((item) => item.stageId === stageId)!);
    setIsOpenStageModal(true);
  };

  const onCloseEdit = () => {
    setIsOpenStageModal(false);
    setTimeout(() => {
      setStageToEdit(undefined);
    }, 300);
  };

  const onShow = async (stageId: number) => {
    stageFlowService.showStage(user.organizationId, stageFlow!.stageFlowId, stageId);
    notifications.showSuccess("Stage has been deleted!");
    fetchData();
  };

  const onHide = async (stageId: number) => {
    stageFlowService.hideStage(user.organizationId, stageFlow!.stageFlowId, stageId);
    notifications.showSuccess("Stage has been deleted!");
    fetchData();
  };

  const onEditStageFlow = async (data: StageFlowUpdateDTOInterface) => {
    await stageFlowService.update(user.organizationId, stageFlow!.stageFlowId, data);
    notifications.showSuccess("Interview Process has been updated!");
    fetchData();
    setIsOpenStageFlowModal(false);
  };

  const onDelete = async (stageId: number) => {
    const stage = stageFlow!.stages.find((item) => item.stageId === stageId)!;
    const isConfirmed = await props.confirm.open({
      title : "Delete Stage",
      content : `Are you sure you want to delete stage "${stage.name}"?`,
      confirmButtonTitle : "Delete",
      destructive : true,
    });
    if (!isConfirmed) {
      return;
    }
    try {
      await stageFlowService.deleteStage(user.organizationId, stageFlow!.stageFlowId, stageId);
      notifications.showSuccess("Stage has been deleted!");
      fetchData();
    } catch (err) {
      if ((err as any)?.response?.data?.message?.includes("with candidates")) {
        notifications.showError("Could not delete stage. Stage is used by candidates.");
      } else {
        notifications.showError("Could not delete stage");
      }
    }
  };

  const onReorder = async (stages: StageFlowItemInterface[]) => {
    let promises: Promise<any>[] = [];
    setStageFlow({
      ...stageFlow!,
      stages
    })
    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      const originalStage = originalStageFlow!.stages.find((item) => item.stageId === stage.stageId)!;
      if (originalStage.order !== stage.order) {
        promises.push(stageFlowService.updateStage(user.organizationId, stageFlow!.stageFlowId, stage.stageId, stage));
      }
    }
    await Promise.all(promises);
    notifications.showSuccess("Interview Process has been updated!");
    fetchData();
  };

  if (loading) {
    return (
      <div className={css.holder}>
        <Loading />
      </div>
    );
  }

  const isDefaultStageFlow = stageFlow!.isDefault;

  return (
    <div className={css.holder}>
      <header className={css.header}>
        <div className={css.titleWrapper}>
          <BackButton to="/profile/interview-process" goBack={true} />
          <SectionTitle className={css.title} level={2}>
            {stageFlow!.name} {!isDefaultStageFlow && <ButtonHTML onClick={() => setIsOpenStageFlowModal(true)}><Icon name="pencil-2" /></ButtonHTML>}
          </SectionTitle>
        </div>
        <div>
          {currentView === "stages" && !isDefaultStageFlow && (
            <>
              <Button
                style={{ marginRight : 16, verticalAlign : "top" }}
                leftIcon={<Icon name="plus" />}
                onClick={() => setIsOpenStageModal(true)}
              >
                Add Stage
              </Button>
            </>
          )}
        </div>
      </header>
      <Tabs style={{ marginBottom : 32 }}>
        <Tabs.Tab active={currentView === "stages"} onClick={() => setCurrentView("stages")}>All Stages</Tabs.Tab>
        <Tabs.Tab active={currentView === "projects"} onClick={() => setCurrentView("projects")}>Projects</Tabs.Tab>
      </Tabs>
      {currentView === "projects" ? (
        <ProjectsTable projects={stageFlow!.projects} />
      ) : (
        <div style={{ paddingBottom: 48 }}>
          <StagesTable
            onInlineEdit={onInlineEditUpdate}
            onEdit={onEdit}
            onDelete={onDelete}
            onShow={onShow}
            onHide={onHide}
            onReorder={onReorder}
            stageFlow={stageFlow!}
          />
          <ModalStageFlowStageEdit
            key={stageToEdit ? stageToEdit.stageId : "new"}
            isOpen={isOpenStageModal}
            stage={stageToEdit}
            onSubmit={onCreate}
            onClose={onCloseEdit}
          />
        </div>
      )}

      <ModalStageFlow
        title="Edit Inteview Process"
        submitTitle="Save"
        stageFlow={stageFlow}
        onSubmit={onEditStageFlow}
        isOpen={isOpenStageFlowModal}
        onClose={() => setIsOpenStageFlowModal(false)}
      />
    </div>
  );
};

// todo
// @ts-ignore
export const ProfileStage = withRouter(withConfirm(ProfileStageCore));
