import * as React from "react";
import css from "./Form.module.css";

export function Form(props) {
  return <form {...props} />;
}

Form.Row = (props) => (
  <div className={css.row} style={props.style}>
    {props.children}
  </div>
);
