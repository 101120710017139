// todo update all of the "any"s in this file to proper types

import * as React from "react";
import { Link } from "react-router-dom";
import {
  StageFlowInterface,
  StageFlowItemInterface,
} from "../../../../screens/profile/ProfileStageFlow/StageFlowService";
import {
  CandidateInterface,
  ProjectCandidateInterface,
  ProjectInterface,
} from "../../../../screens/projects/CandidateService";
import { PermissionEnum, permissionService } from "../../../../screens/projects/PermissionService";
import { EntityConnection } from "../../../../types";
import { ButtonHTML } from "../../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";
import { BoardView, BoardViewColumn } from "../../../ui/BoardView/BoardView";
import { CandidatesExportMode, CandidateExportSelect } from "../../../ui/ExportSelect/CandidateExportSelect";

import css from "./CandidateBoard.module.css";
import { CandidateBoardCard } from "./CandidateBoardCard/CandidateBoardCard";
import { CandidateBoardColumnHeader } from "./CandidateBoardColumnHeader/CandidateBoardColumnHeader";
import { CandidateBoardHelper, ProjectBoardConfig } from "./CandidateBoardHelper";

type Props = {
  project: ProjectInterface,
  stageFlow: StageFlowInterface;
  isArchived: boolean;
  candidates: EntityConnection<ProjectCandidateInterface>;
  onUpdateCandidateStage: (candidate: ProjectCandidateInterface, stageId: number) => void;
  onOpenProfile: (candidate: ProjectCandidateInterface) => void;
  onRemoveCandidate: (candidate: ProjectCandidateInterface) => void;
  onArchiveCandidate: (candidate: ProjectCandidateInterface) => void;
  onUnarchiveCandidate: (candidate: ProjectCandidateInterface) => void;
  onAddCandidateNote: (candidate: ProjectCandidateInterface) => void;
  onToggleArchivedCandidates: () => void
  onExport: (mode: CandidatesExportMode) => void
}

type State = {
  config: ProjectBoardConfig | undefined;
}

const stageSortOptions = CandidateBoardHelper.getSortOptions();
const defaultStageSortValue = stageSortOptions[0].value;

export class CandidateBoard extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      config : undefined,
    };
  }

  async componentDidMount() {
    this.updateTableColumnsHeight();
    //window.addEventListener('resize', this.updateTableColumnsHeight);
    const config = await CandidateBoardHelper.getConfigForProject(this.props.project.projectId);
    this.setState({ config });
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.updateTableColumnsHeight);
  }

  componentDidUpdate() {
    this.updateTableColumnsHeight();
  }

  updateTableColumnsHeight = () => {
    const boardColumns = document.querySelectorAll<HTMLDivElement>(".react-kanban-column");
    const board = document.querySelector<HTMLDivElement>(".react-kanban-board");
    let maxColumnHeight = 0;
    boardColumns.forEach(item => {
      if (item.clientHeight > maxColumnHeight) {
        maxColumnHeight = item.clientHeight;
      }
    });
    maxColumnHeight = Math.max(maxColumnHeight, board ? board.clientHeight : 0);
    boardColumns.forEach(item => {
      item.style.height = `${maxColumnHeight}px`;
    });
  };

  onCardDragEnd = async (card: ProjectCandidateInterface, source: BoardViewColumn<CandidateInterface>, destination: any) => {
    this.props.onUpdateCandidateStage(card, destination.toColumnId);
  };

  onChangeSort = async (stageId: number, sort: string) => {
    await CandidateBoardHelper.updateStageConfigForProject(this.props.project.projectId, stageId, { sort });
    const config = await CandidateBoardHelper.getConfigForProject(this.props.project.projectId);
    this.setState({ config });
  };

  getSortForStage = (stageId: number) => {
    const { config } = this.state;
    return config?.stages?.[stageId]?.sort || defaultStageSortValue;
  };

  render() {
    const { config } = this.state;
    const { candidates, stageFlow, onOpenProfile, onExport, project } = this.props;

    if (!config) {
      return null;
    }

    const columns = stageFlow.stages.filter(item => !item.__hiddenAt).map(item => {
      const sort = this.getSortForStage(item.stageId);
      const candidatesForStage = candidates.nodes.filter(candidate => candidate.stage.stageId === item.stageId);
      const sortedCandidates = CandidateBoardHelper.getSortedCandidates(candidatesForStage, sort);
      return {
        id : item.stageId,
        title : item.name,
        cards : sortedCandidates,
        stage : item,
      };
    });

    return (
      <div className={css.holder}>
        <BoardView
          disableColumnDrag
          disableCardDrag={!permissionService.can(project, PermissionEnum.projectUpdateCandidateStage)}
          columns={columns}
          onCardDragEnd={this.onCardDragEnd}
          renderColumnHeader={(data: BoardViewColumn<ProjectCandidateInterface> & {
            stage: StageFlowItemInterface
          }) => {
            return <CandidateBoardColumnHeader
              sortOptions={stageSortOptions}
              onChangeSort={(sort: string) => this.onChangeSort(data.id, sort)}
              sort={this.getSortForStage(data.id)}
              data={data}
            />;
          }}
          renderCard={(candidate: ProjectCandidateInterface, meta: any) => {
            return <CandidateBoardCard
              project={project}
              onChangeStage={(stageId) => this.props.onUpdateCandidateStage(candidate, stageId)}
              isDragging={meta.dragging}
              data={candidate}
              onAddNote={() => this.props.onAddCandidateNote(candidate)}
              onRemove={() => this.props.onRemoveCandidate(candidate)}
              onArchive={() => this.props.onArchiveCandidate(candidate)}
              onUnarchive={() => this.props.onUnarchiveCandidate(candidate)}
              onOpenProfile={() => onOpenProfile(candidate)}
            />;
          }}
        />
        <footer className={css.footer}>
          <CandidateExportSelect
            onChange={onExport}
            label={
              <ButtonHTML className={css.footerItem} disabled={this.props.isArchived}>
                <Icon name="arrow-down-to-line" className={css.footerItemIcon} />
                Export CSV
              </ButtonHTML>
            }
          />
          {this.props.isArchived ?
            <Link to={`/my-candidates/${project.projectId}`} style={{ marginRight : 12 }} onClick={this.props.onToggleArchivedCandidates}
                  className={css.footerItem}>
              All Candidates
            </Link> :
            <Link to={`/my-candidates/${project.projectId}?isArchived=true`} style={{ marginRight : 12 }} onClick={this.props.onToggleArchivedCandidates}
                  className={css.footerItem}>
              <Icon name="archive" className={css.footerItemIcon} />
              Archived Candidates
            </Link>}
        </footer>
        {/*<CandidatePreview className={css.candidatePreview} data={candidates.nodes[0]} />*/}
      </div>
    );
  }
}