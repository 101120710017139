import * as React from "react";
import { StageFlowInterface, StageFlowItemInterface, StageFlowItemUpdateDTOInterface } from "../StageFlowService";

import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";

import css from "./StagesTable.module.css";
import { candidateService } from "screens/projects/CandidateService";
import { DraggableStageRow } from "./DraggableStageRow";

type Props = {
  stageFlow: StageFlowInterface;
  onDelete: (stageId: number) => void;
  onEdit: (stageId: number) => void;
  onShow: (stageId: number) => void
  onHide: (stageId: number) => void
  onReorder: (stages: StageFlowItemInterface[]) => void;
  onInlineEdit: (stageId: number, stageData: StageFlowItemUpdateDTOInterface) => void;
};

export const StagesTable: React.FC<Props> = ({ onDelete, onEdit, onReorder, onShow, onHide, onInlineEdit, stageFlow }) => {
  const tableRef = React.useRef<HTMLTableElement>(null);

  const onDragEnd = (e: any) => {
    const { active, over } = e;
    if (over && active.id !== over.id) {
      const draggingItemIndex = stageFlow!.stages.findIndex((item) => item.stageId === active.id)!;
      const overItemIndex = stageFlow!.stages.findIndex((item) => item.stageId === over.id)!;
      const sortedStages = arrayMove(stageFlow!.stages.slice(), draggingItemIndex!, overItemIndex!);
      const reindexedStages = sortedStages.map((item, index) => ({ ...item, order: index }));
      onReorder(reindexedStages);
    }
  };

  const sortedStages = stageFlow!.stages.slice().sort((a, b) => a.order - b.order);
  const isDefaultStageFlow = stageFlow!.isDefault

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={sortedStages.map((item) => ({ id: item.stageId }))}>
        <table className={css.table} ref={tableRef}>
          <thead>
            <tr>
              <th className={css.tdOrder}>{!isDefaultStageFlow ? '' : '№'}</th>
              <th className={css.tdName}>Name</th>
              <th className={css.tdDays}>Days On Stage</th>
              <th>Color</th>
              <th>Automations</th>
              <th>Board Visibility</th>
              <th className={css.tdActions} />
            </tr>
          </thead>
          <tbody>
            {sortedStages.map((stage, i) => {
              const stageStyle = candidateService.getStageStyle(stage);
              return (
                <DraggableStageRow
                  key={stage.stageId}
                  id={stage.stageId}
                  onInlineEdit={(stageData) => onInlineEdit(stage.stageId, stageData)}
                  onEdit={() => onEdit(stage.stageId)}
                  onDelete={() => onDelete(stage.stageId)}
                  onShow={() => onShow(stage.stageId)}
                  onHide={() => onHide(stage.stageId)}
                  stage={stage}
                  stageStyle={stageStyle}
                  isEditable={!isDefaultStageFlow}
                />
              );
            })}
          </tbody>
        </table>
      </SortableContext>
    </DndContext>
  );
};
