import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { Spinner } from "reactstrap";
import { css } from "emotion";

import "./App.css";

import history from "./utils/history";
import NavigationBar from "./components/navigation/NavigationBar";
import Companies from "./screens/companies/Companies";
import Profile from "./screens/profile/Profile";
import SuspendedAccount from "./screens/suspendedAccount";
import { isBefore } from "./formatters/calendarFormatter";
import { useUserData } from "./contexts/user";
import { DiscoverNewWrapper } from "./components/DiscoverNew/DiscoverNewWrapper";
import { ProjectsWrapper } from "./screens/projects/ProjectsWrapper";
import { AcceptInvite } from "./screens/projects/AcceptInvite/AcceptInvite";
import Project from "./screens/project/project";
import { useAuthController } from "./contexts/AuthProvider";
import { landingUrlTo } from "./components/DiscoverNew/util/UrlHelper";
import { SubscriptionRequired } from "./components/auth/SubscriptionRequired/SubscriptionRequired";
import { SubscriptionVerification } from "./components/auth/SubscriptionVerification/SubscriptionVerification";
import Admin from "./screens/admin/admin";
import { AdminUser } from "./screens/admin/AdminUser/AdminUser";
import { CandidatesPage } from "./components/candidate/v2/CandidatesPage";
import { ImpersonatedBanner } from "./components/navigation/ImpersonatedBaner/ImpersonatedBanner";
import { LowCreditsBanner } from "./components/navigation/LowCreditsBanner/LowCreditsBanner";

window.__chatterworks__appVersion = "1.2.75";

// console.log(Sentry);

// Sentry.init({
//   dsn: "https://5bfd9b58b25eb8d8a41c7616255659d6@o4505799212007424.ingest.sentry.io/4505799214039040",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", "https://chatterworks.com"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function RedirectToLanding() {
  useEffect(() => {
    window.location.href = landingUrlTo("/sign-in");
  }, []);
  return null;
}

const App = () => {
  const { isAuthenticated, isImpersonated, loading, user } = useAuthController();

  if (loading) {
    return (
      <div style={{ padding: "200px 0", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spinner color="primary" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Router history={history}>
        {isImpersonated && <ImpersonatedBanner />}
        <NavigationBar />
        <Switch>
          <Route path="/profile/:section?" component={Profile} />
          <Route path="/accept-invite">
            <div
              id="app-container"
              className={css`
                min-height: 100vh;
                height: 100%;
              `}
            >
              <AcceptInvite />
            </div>
          </Route>
          <RedirectToLanding />
        </Switch>
      </Router>
    );
  }

  if (!user.hasActiveSubscription) {
    return (
      <Router history={history}>
        <div
          id="app-container"
          className={css`
            min-height: 100vh;
            height: 100%;
          `}
        >
          {isImpersonated && <ImpersonatedBanner />}
          <NavigationBar />
          <Switch>
            <Route path="/profile/:section?" component={Profile} />
            <Route path="/subscription-required" component={SubscriptionRequired} />
            <Route path="/subscription-verification" component={SubscriptionVerification} />
            <Redirect to="/subscription-required" />
          </Switch>
        </div>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <div
        id="app-container"
        className={css`
          min-height: 100vh;
          height: 100%;
        `}
      >
        {isImpersonated && <ImpersonatedBanner />}
        {/*<LowCreditsBanner />*/}
        <NavigationBar />
        <Switch>
          <Route path="/accept-invite">
            <AcceptInvite />
          </Route>
          <Route path={["/discover-new/:candidateId", "/discover-new"]}>
            <DiscoverNewWrapper />
          </Route>
          <Route path="/suspended">
            <SuspendedAccount />
          </Route>
          <Route path="/profile/:section?">
            <Profile />
          </Route>
          <AllowedRoutes path="*">
            <Route exact path="/">
              <Redirect to="/discover" />
            </Route>
            <Route path={["/my-candidates/:projectId?"]} component={CandidatesPage} />
            <Route path={["/discover/:candidateId", "/discover", "/candidates/:candidateId", "/candidates"]}>
              <DiscoverNewWrapper />
            </Route>
            {/*<Route path={["/my-candidates/:candidateId", "/my-candidates"]}>*/}
            {/*  <MyCandidatesWrapper />*/}
            {/*</Route>*/}
            <Route path="/companies">
              <Companies />
            </Route>
            <Route path="/projects" exact>
              <ProjectsWrapper />
            </Route>
            <Route path="/projects/:id">
              <Project />
            </Route>
            {user.isAdmin && (
              <Switch>
                <Route path="/admin/users/:userId" component={AdminUser} />
                <Route path="/admin" component={Admin} />
              </Switch>
            )}
          </AllowedRoutes>
        </Switch>
      </div>
    </Router>
  );
};

function AllowedRoutes({ children, ...rest }) {
  const {
    state: { user },
  } = useUserData();

  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    if (!user?.userId) {
      return;
    }
    const isTimeBefore = isBefore(user?.subscriptionEndDate);
    setIsRedirect(isTimeBefore);
  }, [user]);

  return <Route {...rest} render={() => (isRedirect ? <Redirect to="/suspended" /> : children)} />;
}

export default App;
