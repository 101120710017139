import * as React from "react";
import { DiscoverCandidate } from "../DiscoverCandidate/DiscoverCandidate";
import { Link } from "../../UI/Link/Link";
import { Icon } from "../../UI/IconComponent/Icon";
import { PaginationFull } from "../../UI/PaginationFull/PaginationFull";
import { NoData } from "../../UI/NoData/NoData";
import AddCandidatesToProjectModal from "../../../modals/AddCandidatesToProjectModal";
import { useUserData } from "../../../../contexts/user";
import { Loading } from "../../UI/Loading/Loading";

import css from "./DiscoverSearchResults.module.css";

export const DiscoverSearchResults = React.memo((props) => {
  const [showAddToProjectModal, setShowAddToProjectModal] = React.useState(false);

  const {
    selected,
    result,
    selectedCount,
    onSelect,
    onSelectAll,
    isSelectedAll,
    onClearSelection,
    loading,
    onUnlockCandidate,
    onCandidateUpdated,
    onAddSelectedToProject,
    onAddToProject,
  } = props;

  const {
    state: { user },
  } = useUserData();
  const uiSettings = user.uiSettings;

  return (
    <div className={css.holder}>
      <header className={css.header}>
        <div className={css.controls}>
          {!selectedCount ? (
            !!result?.pageInfo?.total && (
              <Link className={css.controlsItem} onClick={onSelectAll}>
                Select All
              </Link>
            )
          ) : (
            <>
              <span className={css.controlsItem}>{selectedCount} selected</span>
              {isSelectedAll ? (
                <Link className={css.controlsItem} onClick={onClearSelection}>
                  Clear Selection
                </Link>
              ) : (
                <Link className={css.controlsItem} onClick={onSelectAll}>
                  Select All
                </Link>
              )}
              <Link
                className={css.controlsItem}
                leftIcon={<Icon name="plus" />}
                onClick={() => setShowAddToProjectModal(true)}
              >
                Add to {uiSettings?.mappings?.project}
              </Link>
            </>
          )}
        </div>
      </header>
      <div className={css.list}>
        {loading ? (
          <Loading />
        ) : !result ? (
          <NoData />
        ) : result.pageInfo?.total ? (
          result.data.map((item) => {
            return (
              <DiscoverCandidate
                isSelectable
                onAddToProject={(projectId) => onAddToProject(projectId, item)}
                canAddToProject={selectedCount === 0}
                key={item.id}
                data={item}
                className={css.item}
                onCandidateUpdated={onCandidateUpdated}
                onUnlock={() => onUnlockCandidate(item)}
                onSelect={() => onSelect(item)}
                isSelected={selected[item.id]}
              />
            );
          })
        ) : (
          <NoData text={`No ${uiSettings?.mappings?.candidates} found for your request`} />
        )}
      </div>
      {!!result?.pageInfo?.total && (
        <PaginationFull
          className={css.pagination}
          pageCount={result.pageInfo.pageCount}
          onChangeCurrentPage={props.onChangeCurrentPage}
          currentPage={props.currentPage}
          pageSize={props.pageSize}
          onChangePageSize={props.onChangePageSize}
        />
      )}

      {showAddToProjectModal && (
        <AddCandidatesToProjectModal
          candidatesCount={selectedCount}
          onSubmit={onAddSelectedToProject}
          isOpen={showAddToProjectModal}
          onClose={() => setShowAddToProjectModal(false)}
        />
      )}
    </div>
  );
});
