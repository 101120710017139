import React from "react";
import { get, take } from "lodash";

// candidate avatars
import briefcaseImg from "../../assets/images/briefcase.svg";

import capitalizeWords from "../../formatters/capitalizeWords";
import formatDateRange from "../../formatters/formatDateRange";

import { cx, css } from "emotion/macro";

const educationAvatar = () => (
  <div>
    <img
      className={cx(
        "job-img",
        css`
          border-radius: 50%;
          margin-top: auto;
          margin-bottom: auto;
          width: 60px;
        `
      )}
      src={briefcaseImg}
      alt=""
    />
  </div>
);

const ExperienceItem = ({ Icon = () => <div />, experienceItemDetails = {} }) => {
  const { title = "", companyName = "", dateRange = "", _score = 0, _hits = [] } = experienceItemDetails;
  return (
    <div
      className={css`
        display: flex;
        width: 100%;
        margin-bottom: 12px;
      `}
    >
      <Icon />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-left: 18px;
          justify-content: center;
        `}
      >
        <span className={cx("standard-theme-text", "candidate-detail-list-item-title")}>{capitalizeWords(title)}</span>
        <span className={cx("standard-theme-text", "candidate-detail-list-item-details")}>
          {capitalizeWords(companyName)}
        </span>
        <span className={cx("standard-theme-text", "candidate-detail-list-item-details")} dangerouslySetInnerHTML={{__html: dateRange}}/>
        {!!window.devmode && !!_score && (
          <div style={{
            fontSize: "10px",
            color: "#a8a8a8",
          }}>
            _score: <span>{_score}</span>
            <br />
            _hits: <span>{JSON.stringify(_hits)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

function Experiences({ entries = [], viewAll = true, maxEntries = 3, ranked = null }) {
  const experiences = ranked ? ranked : entries;
  const displayedEntries = viewAll ? experiences : take(experiences, maxEntries);

  return (
    <div>
      {experiences.length > 0 ? (
        displayedEntries.map((experience, index) => {
          return (
            <ExperienceItem
              key={index}
              Icon={educationAvatar}
              experienceItemDetails={{
                title: get(experience, "title"),
                companyName: get(experience, "company.name"),
                dateRange: formatDateRange(get(experience, "startDate"), get(experience, "endDate"), ranked !== null),
                _score: experience._score || 0,
                _hits: experience._hits || [],
              }}
            />
          );
        })
      ) : (
        <div
          className={css`
            font-size: 12px;
            color: #a8a8a8;
          `}
        >
          No experience
        </div>
      )}
    </div>
  );
}

export default Experiences;
