import React from "react";
import { cx, css } from "emotion/macro";

import CompanyProjectsAccordian from "../company/CompanyProjectsAccordion";
import CompanyProject from "../company/CompanyProject";

function CandidateCompanyAddRemove({
  candidate = {},
  companiesProjects = [],
  addCandidateToProject,
  removeCandidateFromProject,
  uiSettings,
}) {
  const isCandidateInProject = (projectId) => {
    if (!candidate?.companies?.length) return false;
    let companies = candidate.companies;

    for (let comp of companies) {
      if (!comp?.projects?.length) continue;
      for (let project of comp.projects) {
        if (project.projectId === projectId) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <div className={cx("candidate-company-add-remove-container")}>
      {companiesProjects?.length > 0 ? (
        companiesProjects.map((comp, idx) => (
          <CompanyProjectsAccordian
            key={JSON.stringify(comp)}
            companyName={comp.name}
            bottomBorder={idx === companiesProjects.length - 1}
          >
            {comp.projects &&
              comp.projects.map((project) => {
                const isInProject = isCandidateInProject(project.projectId);
                return (
                  <CompanyProject
                    key={JSON.stringify(project)}
                    project={project}
                    onClick={(e) => {
                      if (isInProject) {
                        removeCandidateFromProject(comp, project, project.projectId, candidate.candidateId);
                      } else {
                        addCandidateToProject(comp, project, project.projectId, candidate.candidateId);
                      }
                    }}
                    isChecked={isInProject}
                  />
                );
              })}
          </CompanyProjectsAccordian>
        ))
      ) : (
        <div style={{ textAlign: "center" }}>
          <span
            className={css`
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              -webkit-letter-spacing: -0.33px;
              -moz-letter-spacing: -0.33px;
              -ms-letter-spacing: -0.33px;
              letter-spacing: -0.33px;
              text-align: center;
              color: #9b9b9b;
            `}
          >
            No <span style={{ textTransform: "lowercase" }}>{uiSettings?.mappings?.projects}</span> found.
          </span>
        </div>
      )}
    </div>
  );
}

export default CandidateCompanyAddRemove;
