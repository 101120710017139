import React from "react";

import { css, cx } from "emotion/macro";

function NotesDrawer({ candidate, candidateNotes, noteContent, onNoteContentChange, saveNote, setCurrentPage }) {
  // Source: https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
  let tx = document.getElementById("create-note-textarea");
  if (tx) {
    tx.scrollTop = 0;
    tx.style.minHeight = "68px";
    tx.style.overflowY = "hidden";
    tx.style.width = "100%";
    tx.style.resize = "none";
    tx.style.border = "1px solid #d8d8d8";

    tx.addEventListener("input", OnInput, false);
  }

  function OnInput() {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
  }

  const onViewAllNotes = () => {
    window.analytics.track("Candidate Note Viewed", {
      candidate_id: candidate?.candidateId,
      state: candidate?.location?.region,
      locality: candidate?.location?.locality,
      is_unlocked: candidate?.isContactInfoViewable,
    });

    if (candidateNotes?.length === 0) return;
    setCurrentPage("notes");
  };

  return (
    <div className={cx(styles.newNoteContainer, "d-none", "d-lg-flex")}>
      <div className="col-12">
        <div
          className={cx(
            "row",
            "justify-content-between",
            css`
              margin-bottom: 15px;
            `
          )}
        >
          <span
            className={css`
              font-family: Montserrat-Bold !important;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.54;
              letter-spacing: -0.71px;
              color: #373737;
              margin-bottom: initial;
            `}
          >
            Notes
          </span>
          <div
            className={candidateNotes?.length === 0 ? "view-all-button-disabled" : "view-all-button"}
            unselectable="on"
            onClick={() => onViewAllNotes()}
            style={{ fontSize: "0.80rem" }}
          >
            {" "}
            View All
          </div>
        </div>
        <div className="row">
          <textarea id="create-note-textarea" type="textarea" value={noteContent} onChange={onNoteContentChange} />
        </div>
        <div className="row" style={{ justifyContent: "flex-end" }}>
          <button
            id="add-note-button"
            type="button"
            disabled={noteContent === "" ? true : false}
            className={noteContent === "" ? "btn btn-outline-secondary" : "btn btn-outline-primary"}
            style={{
              width: "77px",
              height: "36px",
              objectFit: "contain",
              float: "right",
              borderRadius: "20px",
              marginTop: "10px",
              marginBottom: "-12px",
            }}
            onClick={saveNote}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  newNoteContainer: css`
    width: initial;
    min-height: 200px;
    background-color: #f2f2f2;
    padding: 30px;
    margin: 0;
    margin-top: 30px;
    overflow: overlay;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center;
  `,
};

export default NotesDrawer;
