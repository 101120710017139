import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ModalButtonContainer from "../../button/ModalButtonContainer";
import CloseButton from "../../button/CloseButton";
import { modalStyles } from "../modalStyles";
import { Select } from "../../DiscoverNew/Form/Select/Select";

import styles from "./InviteCollaboratorModal.module.css";
import { ProjectCardPreview } from "../../project/ProjectCardPreview/ProjectCardPreview";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Dropdown } from "../../DiscoverNew/UI/Dropdown/Dropdown";
import { projectService } from "../../../screens/projects/ProjectService";
import { ROLE_LEVEL } from "../../../screens/projects/PermissionService";
import { COLLABORATOR_ROLE_OPTIONS } from "../../../constants/constants";

const dropdownItemStyle = {
  paddingLeft: 13,
  paddingRight: 13,
  marginLeft: -13,
  marginRight: -13,
};

function InviteCollaboratorModal({ isOpen, onClose, project }) {
  const notificationController = useNotifications();
  const [emails, setEmails] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(0);
  const role = COLLABORATOR_ROLE_OPTIONS[selectedRoleIndex];

  function getEmailsList() {
    return emails.filter((item) => item.value.trim()).map((item) => item.value.trim());
  }

  async function onSubmit() {
    setIsWaiting(true);
    try {
      for (const email of getEmailsList()) {
        await projectService.sendInvite(project.id, { email, role: role.id.toLowerCase() });
      }
      notificationController.showSuccess("Invitation has been sent!");
      onClose();
    } catch (err) {
      setIsWaiting(false);
      if (err?.response?.data?.message?.includes("already a member")) {
        notificationController.showError("Couldn't send the invitation. Some users are already collaborators of the project.");
      } else {
        notificationController.showError("Couldn't send the invitation. Please try again later or contact support.");
      }
    }
  }

  return (
    <Modal show={isOpen} onHide={onClose} centered={true}>
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle} style={{ paddingBottom: 10 }}>
          Invite a Collaborator
        </div>
        <p className={modalStyles.modalSubtitle}>Invite a collaborator to work with you on the following project:</p>
        {!!project.company && <ProjectCardPreview project={project} />}

        <div className={styles.selectRoleHolder}>
          <span className={styles.selectRoleLabel}>Choose a role:</span>
          <Dropdown
            classNames={{
              content: styles.selectedRoleDropdownContent,
            }}
            label={
              <div className={styles.selectedRole}>
                {role.name}
                <div className={styles.selectedRoleIcon}>
                  <Icon name="chevron" />
                </div>
              </div>
            }
          >
            {COLLABORATOR_ROLE_OPTIONS.filter((item) => {
              const currentUserLevel = ROLE_LEVEL[project.currentUserRole];
              const roleLevel = ROLE_LEVEL[item.id];
              return currentUserLevel <= roleLevel;
            }).map((role, i) => {
              return (
                <Dropdown.Item
                  onClick={(close) => {
                    setSelectedRoleIndex(i);
                    close();
                  }}
                  key={role.name}
                  style={dropdownItemStyle}
                >
                  {role.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        </div>
        <div className={styles.roleDescription}>
          Selected role provides the following rights:
          <ul>
            {role.rights.map((text, i) => (
              <li key={i}>{text}</li>
            ))}
          </ul>
        </div>
        <div className={modalStyles.modalFormContainer}>
          <div className={modalStyles.modalInputLabel}>Email(s)</div>
          <Select
            placeholder="Enter an email"
            noOptionsMessage={() => "Start typing to add an email"}
            formatCreateLabel={(value) => `Add "${value}"`}
            onChange={setEmails}
            value={emails}
            creatable
            isMulti
          />
        </div>
        <ModalButtonContainer
          title="Invite"
          onClick={onSubmit}
          isWaiting={isWaiting}
          isDisabled={!getEmailsList().length || isWaiting}
        />
      </div>
    </Modal>
  );
}

export default InviteCollaboratorModal;
