import React, { useEffect, useRef, useState } from "react";

import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import Table from "../../../components/project/Table/Table";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";
import { whitelistUserEmailService } from "../WhitelistUserEmailService";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import { ModalAddWhitelistUserEmail } from "./ModalAddWhitelistUserEmail/ModalAddWhitelistUserEmail";
import {
  AdminWhitelistUserEmailListControlls,
} from "./AdminWhitelistUserEmailListControlls/AdminWhitelistUserEmailListControlls";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import { debounce } from "lodash";
import { omit, pick } from "../../../components/DiscoverNew/util/util";
import { UrlHelper } from "../../../components/DiscoverNew/util/UrlHelper";
import { Link } from "react-router-dom";

import css from "./AdminWhitelistUserEmailList.module.css";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";

const sortOptions = [
  { value: "CREATED_DESC", label: "Created (desc)", field: "created_at", dir: "DESC" },
  { value: "CREATED_ASC", label: "Created (asc)", field: "created_at", dir: "ASC" },
  { value: "UPDATED_DESC", label: "Updated (desc)", field: "updated_at", dir: "DESC" },
  { value: "UPDATED_ASC", label: "Updated (asc)", field: "updated_at", dir: "ASC" },
  { value: "REGISTERED_DESC", label: "Registered (desc)", field: "registered_at", dir: "DESC" },
  { value: "REGISTERED_ASC", label: "Registered (asc)", field: "registered_at", dir: "ASC" },
  { value: "EMAIL_DESC", label: "Email (desc)", field: "email", dir: "DESC" },
  { value: "EMAIL_ASC", label: "Email (asc)", field: "email", dir: "ASC" },
];

const activeOptions = [
  { value: "ALL", label: "All" },
  { value: "ACTIVE", label: "Not registered" },
  { value: "INACTIVE", label: "Already registered" },
];

const trialOptions = [
  { value: "ALL", label: "All" },
  { value: "ENABLED", label: "Enabled" },
  { value: "DISABLED", label: "Disabled" },
  { value: "CUSTOM", label: "Custom"},
];

const getDefaultFilters = () => {
  return {
    sort: sortOptions[0],
    active: activeOptions[0],
    trial: trialOptions[0],
    query: "",
  };
};

const getDefaultPagination = () => {
  return {
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
  };
};

export let AdminWhitelistUserEmailList = ({ confirm, notifications }) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [args, setArgs] = useState({
    filters: getDefaultFilters(),
    pagination: getDefaultPagination(),
  });

  const didMount = useRef(false);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-      U R L   S E A R C H   P A R A M S      -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  const restoreSearchFromUrl = () => {
    const data = UrlHelper.parseSearch(window.location.search);
    const { currentPage, pageSize, ...filters } = data || {};

    return new Promise((resolve) => {
      const defaultFilters = getDefaultFilters();
      const defaultPagination = getDefaultPagination();
      const updatedFilters = {
        ...defaultFilters,
        ...pick(filters, Object.keys(defaultFilters)),
        sort: filters.sort
          ? sortOptions.find((item) => item.value === filters.sort) || defaultFilters.sort
          : defaultFilters.sort,
        active: filters.active
          ? activeOptions.find((item) => item.value === filters.active) || defaultFilters.active
          : defaultFilters.active,
        trial: filters.trial
          ? trialOptions.find((item) => item.value === filters.trial) || defaultFilters.trial
          : defaultFilters.trial,
      };
      const updatedPagination = {
        currentPage: parseInt(currentPage || defaultPagination.currentPage),
        pageSize: parseInt(pageSize || defaultPagination.pageSize),
      };
      setArgs({
        filters: updatedFilters,
        pagination: updatedPagination,
      });
      resolve();
    });
  };

  const saveSearchToUrl = () => {
    const activeFilters = pick(args.filters, Object.keys(args.filters));
    const filterString = UrlHelper.stringifyParams({
      ...omit(activeFilters, ["sort", "active", "trial"]),
      sort: activeFilters.sort.value,
      active: activeFilters.active.value,
      trial: activeFilters.trial.value,
      pageSize: args.pagination.pageSize,
      currentPage: args.pagination.currentPage,
    });
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
  };

  const debouncedSaveSearchToUrl = debounce(saveSearchToUrl, 200);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-              A P I   C A L L S              -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await whitelistUserEmailService.fetchAll({
        query: args.filters.query,
        limit: args.pagination.pageSize,
        skip: (args.pagination.currentPage - 1) * args.pagination.pageSize,
        orderBy: args.filters.sort.field,
        sortBy: args.filters.sort.dir.toLowerCase(),
        active: args.filters.active.value.toLowerCase(),
        trial: args.filters.trial.value ? args.filters.trial.value.toLowerCase() : undefined,
      });
      setData(response);
      setLoading(false);
    } catch (err) {
      notifications.showError("Couldn't fetch data.");
      setError(err);
      setLoading(false);
    }
  };

  const debouncedFetchData = debounce(fetchData, 250);

  const createEmail = async (data) => {
    try {
      let createData = {
        email: data.email,
        trial: data.trial.toLowerCase(),
      };

      if (data.trial === "CUSTOM") {
        createData.trial = JSON.stringify({
          "days_interval": data.customDaysInterval,
          "unlock_credits": data.customUnlocksCount,
        })
      }

      await whitelistUserEmailService.add(createData);
      setIsOpenModal(false);
      notifications.showSuccess("Email has been added.");
      fetchData();
    } catch (err) {
      setIsOpenModal(false);
      if (err?.response?.data?.message.includes("specified email is invalid")) {
        notifications.showError("Specified email is invalid.");
      } else if (err?.response?.data?.message.includes("specified email is already whitelisted")) {
        notifications.showError("Specified email is already whitelisted.");
      } else {
        notifications.showError(
          err?.response?.data?.message
            ? err?.response?.data?.message.capitalize()
            : "Couldn't add the domain to the whitelist."
        );
      }
    }
  };

  const deleteEmail = async (id) => {
    try {
      const isConfirmed = await confirm.open({
        title: "Remove Email",
        content: "Are you sure you want to remove this email from the whitelist?",
        confirmButtonTitle: "Remove",
        destructive: true,
      })
      if (!isConfirmed) {
        return;
      }
      await whitelistUserEmailService.delete(id);
      notifications.showSuccess("Email has been deleted.");
      fetchData();
    } catch (err) {
      console.error('deleteEmail', err);
      notifications.showError("Couldn't remove an email");
    }
  };

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-            U S E   E F F E C T S            -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  useEffect(() => {
    const loadData = async () => {
      await restoreSearchFromUrl();
    };
    loadData();
  }, []);

  useEffect(() => {
    return injectStyleOverride();
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    debouncedFetchData();
    debouncedSaveSearchToUrl();
  }, [args]);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-              C A L L B A C K S              -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  const onChangeFilter = (filter) => {
    setArgs((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        ...filter,
      },
      pagination: {
        ...prev.pagination,
        currentPage: 1,
      },
    }));
    
  };

  const onChangePagination = (pagination) => {
    setArgs((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        ...pagination,
      },
    }));
    saveSearchToUrl();
    fetchData();
  };

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-                 R E N D E R                 -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  return (
    <>
      <AdminWhitelistUserEmailListControlls
        data={data}
        query={args.filters.query}
        onChangeQuery={(v) => onChangeFilter({ query: v })}
        sort={args.filters.sort}
        sortOptions={sortOptions}
        onChangeSort={(v) => onChangeFilter({ sort: v })}
        active={args.filters.active}
        activeOptions={activeOptions}
        onChangeActive={(v) => onChangeFilter({ active: v })}
        trial={args.filters.trial}
        trialOptions={trialOptions}
        onChangeTrial={(v) => onChangeFilter({ trial: v })}
        onAdd={() => setIsOpenModal(true)}
      />
      <div className={css.container}>
        <Table
          actions={{
            delete: {
              render: (v) => {
                return <span className={css.deleteButton} onClick={() => deleteEmail(v.id)}>
                  <Icon name='fa-trash' />
                </span>
              },
            },
          }}
          noData="No entries found."
          // onSelectAll={}
          // isAllSelected={}
          // selected={}
          // onSelect={}
          dataObject={{
            loading: pageLoading,
            error,
            data: {
              entries: data?.nodes,
            },
          }}

          columns={[
            {
              dataIndex: "email",
              style: { width: 250 },
              render: (v) => {
                return (
                  <div
                    title={v}
                    style={{ maxWidth: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {v}
                  </div>
                );
              },
            },
            {
              dataIndex: "trial",
              style: { width: 250 },
              render: (v, r) => {
                const option = trialOptions.find((o) => o.value.toLowerCase() === v);
                const parsed = option ? {} : JSON.parse(v);
                return (
                  <div
                    style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {option ? option.label : 
                      `Custom: ${parsed.days_interval ? parsed.days_interval + " days;" : ""} ${parsed.unlock_credits ? parsed.unlock_credits + " unlocks;" : ""}`
                    }
                  </div>
                );
              },
            },
            {
              dataIndex: "userRef",
              title: "User",
              style: { width: 350 },
              render: (v) => {
                return (
                  <div
                    style={{ maxWidth: 350, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {v?.name ? (
                      <Link to={`/admin/users/${v.id}`}>{v?.name}</Link>
                    ) : (
                      "	–"
                    )}
                  </div>
                );
              },
            },
            { className: css.tdDate, dataIndex: "registeredAt", title: "Registered", render: Formatter.fullDate },
            { className: css.tdDate, dataIndex: "createdAt", title: "Created", render: Formatter.fullDate}
          ]}
        />
      </div>
      <ModalAddWhitelistUserEmail
        key={"new"}
        isOpen={isOpenModal}
        onSubmit={createEmail}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};

AdminWhitelistUserEmailList = ((WrappedComponent) => {
  return function (props) {
    const notifications = useNotifications();
    return <WrappedComponent {...props} notifications={notifications} />;
  };
})(AdminWhitelistUserEmailList);

AdminWhitelistUserEmailList = withConfirm(AdminWhitelistUserEmailList);
