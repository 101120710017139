import * as React from "react";
import { cx } from "emotion";
import PropTypes from "prop-types";

import css from "./Tag.module.css";

const classNameByTheme = {
  red: css.red,
  gray: css.gray,
};

export function Tag(props) {
  const Component = props.href ? "a" : "div";
  return (
    <Component
      className={cx(css.tag, classNameByTheme[props.theme], props.className)}
      href={props.href}
      target={props.target}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </Component>
  );
}

Tag.propTypes = {
  theme: PropTypes.oneOf(["red", "gray"]),
};
