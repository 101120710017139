import * as api from "../../api/apiService";

/**
 * @typedef ProjectInviteInterface
 * @property {number} id
 * @property {string} email
 * @property {('admin'|'editor'|'viewer')} role
 * @property {object} creator
 * @property {number} creator.id
 * @property {string} creator.name
 * @property {object} project
 * @property {number} project.id
 * @property {string} project.name
 * @property {string} status
 */

class ProjectInviteService {
  /**
   * @param {any} obj
   * @returns {ProjectInviteInterface}
   */
  mapToInterface(obj) {
    return {
      id: obj.id,
      email: obj.email,
      role: obj.role,
      creator: obj.creator,
      project: obj.project,
      status: obj.status,
    };
  }

  /**
   * @param {string }token
   * @returns {Promise<ProjectInviteInterface>}
   */
  fetchInvite = (token) => {
    return api.GetProjectInvite(token).then((r) => (r.data ? this.mapToInterface(r.data) : null));
  };

  /**
   * @returns {Promise<ProjectInviteInterface>}
   */
  fetchMyIncomingInvites = () => {
    return api.GetProjectInviteMine().then((r) => (r.data ? r.data.map(this.mapToInterface) : null));
  };
}

export const projectInviteService = new ProjectInviteService();
