import * as React from "react";
import css from "../DiscoverCandidate.module.css";
import { ContactButton } from "../../../UI/ContactButton/ContactButton";
import { Icon } from "../../../UI/IconComponent/Icon";
import { DiscoverCandidateContactButton } from "./DiscoverCandidateContactButton/DiscoverCandidateContactButton";
import PropTypes from "prop-types";
import formatPhoneNumber from "../../../../../formatters/phoneFormatter";
import { candidateService } from "../../../../../screens/projects/CandidateService";
import {
  colorToSocialMap,
  iconToSocialMap,
} from "../../../../candidate/v2/CandidatePreview/CandidateSocials/CandidateSocials";
import { isValidURL } from "../../../util/util";

export function DiscoverCandidateContacts(props) {
  const { data } = props;
  const [validatedEmails, setValidatedEmails] = React.useState(null);
  // that way it prevents validation on initial load, but once emails are opened, it will validate them and update on data change
  const [isEmailsOpened, setIsEmailsOpened] = React.useState(false);

  const onUnlock = () => {
    setIsEmailsOpened(true);
    props.onUnlock && props.onUnlock();
  }

  React.useEffect(() => {
    if (data?.emails !== undefined && !data.isLocked && isEmailsOpened) {
      validateEmails(data?.id, data?.emails);
    }
  }, [data.emails]);

  const validateEmails = async(id, emails) => {
    if (emails.length) {
      let result = await candidateService.getValidatedEmails(id, emails);
      if (result) {
        setValidatedEmails(result);
      }
    }
  }

  return (
    <>
      <div className={css.footerSection}>
        <div className={css.footerSectionTitle}>Contact</div>
        <div className={css.footerRow}>
          <DiscoverCandidateContactButton
            type='phone'
            isLocked={data.isLocked}
            onUnlock={onUnlock}
            data={(data.phoneNumbers || []).map((item) => ({
              id: item.id,
              value: item.e164,
              label: formatPhoneNumber(item.e164),
            }))}
          >
            <Icon name="phone" />
          </DiscoverCandidateContactButton>
          <DiscoverCandidateContactButton
            type='sms'
            isLocked={data.isLocked}
            onUnlock={onUnlock}
            data={(data.phoneNumbers || []).map((item) => ({
              id: item.id,
              value: item.e164,
              label: formatPhoneNumber(item.e164),
            }))}
          >
            <Icon name="message" />
          </DiscoverCandidateContactButton>
          <DiscoverCandidateContactButton
            type='email'
            isLocked={data.isLocked}
            onUnlock={onUnlock}
            data={((validatedEmails ?? data.emails) || []).map((item) => ({
              id: item.id,
              value: item.address,
              label: item.address,
              status: item.status
            }))}
            onOpen={data.isLocked ? null : () => {
              setIsEmailsOpened(true);
              validateEmails(data.id, data.emails);
            }}
            isStatusVisible
          >
            <Icon name="email" />
          </DiscoverCandidateContactButton>
          <DiscoverCandidateContactButton disabled>
            <Icon name="video-call" />
          </DiscoverCandidateContactButton>
          <DiscoverCandidateContactButton disabled>
            <Icon name="add-contact" />
          </DiscoverCandidateContactButton>
        </div>
      </div>
      <div className={css.footerSection}>
        <div className={css.footerSectionTitle}>Social Media</div>
        <div className={css.footerRow}>
          {candidateService.getSortedSocialMedia(filterDuplicateSocialProfiles(data._source.socialProfiles || []))
            .filter(isValidURL)
            .map((item) => {
              return (
                <ContactButton
                  key={item.url}
                  target="_blank"
                  component='a'
                  href={item.url}
                  style={{ backgroundColor: colorToSocialMap[item.site] }}
                >
                  <Icon name={iconToSocialMap[item.site]} />
                </ContactButton>
              )
          })}
        </div>
      </div>
    </>
  );
}

const filterDuplicateSocialProfiles = (socialProfiles) => {
  const socialProfileMap = {};
  const result = [];
  socialProfiles.forEach((item) => {
    if (!socialProfileMap[item.url]) {
      socialProfileMap[item.url] = true;
      result.push(item);
    }
  });
  return result;
};

DiscoverCandidateContacts.propTypes = {
  onUnlock: PropTypes.func,
  // @todo
  data: PropTypes.any,
};
