import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../button/CloseButton";
import ModalButtonContainer from "../../../button/ModalButtonContainer";
import { modalStyles } from "../../../modals/modalStyles";
import { FormField } from "../../Form/FormField/FormField";
import { Input } from "../../Form/Input/Input";
import { Textarea } from "../../Form/Textarea/Textarea";
import { TextareaHighlightable } from "../../Form/TextareaHighlightable/TextareaHighlightable";

type Props = {
  title: string
  onSubmit: (data: any) => Promise<any>;
  onClose: () => void;
  config: { name: string, label: string, defaultValue: any, type?: string, placeholder?: string }[]
  submitButtonTitle?: string
}

export const ModalEdit: React.FC<Props> = ({ title, config, onClose, onSubmit, submitButtonTitle }) => {
  const [rerenderKey, setRerenderKey] = useState(0);
  const defaultValues = config.reduce((acc, item) => ({ ...acc, [item.name] : item.defaultValue || "" }), {});
  const [values, setValues] = useState<any>(defaultValues);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit(values);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setRerenderKey(rerenderKey + 1);
  }, []);

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>{title}</div>
        <div className={modalStyles.modalFormContainer}>
          {config.map((item) => {
            if (item.type === 'text') {
              return (
                <FormField label={item.label} key={item.label}>
                  <Input
                    value={values[item.name]}
                    onChange={(e: any) => setValues({ ...values, [item.name] : e.target.value })}
                    placeholder={item.placeholder || item.label}
                  />
                </FormField>
              )
            }
            if (item.type === 'textarea') {
              return (
                <FormField label={item.label} key={item.label}>
                  <Textarea
                    minRows={5}
                    value={values[item.name]}
                    onChange={(e: any) => setValues({ ...values, [item.name] : e.target.value })}
                    placeholder={item.placeholder || item.label}
                  />
                </FormField>
              )
            }
            return (
              <FormField label={item.label} key={item.label}>
                <TextareaHighlightable
                  key={rerenderKey}
                  className={css`
                    max-height: 240px !important;
                  `}
                  value={values[item.name]}
                  onChange={(e: any) => setValues({ ...values, [item.name] : e.target.value })}
                  placeholder={item.placeholder || item.label}
                />
              </FormField>
            );
          })}
        </div>
        <ModalButtonContainer
          title={submitButtonTitle || 'Confirm'}
          onClick={handleSubmit}
          isWaiting={loading}
        />
      </div>
    </Modal>
  );
};