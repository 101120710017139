import { css } from "emotion/macro";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as api from "../../../../api/apiService";
import { CandidateInterface, CandidateNote, candidateService } from "../../../../screens/projects/CandidateService";
import CloseButton from "../../../button/CloseButton";
import ModalButtonContainer from "../../../button/ModalButtonContainer";
import { FormField } from "../../../DiscoverNew/Form/FormField/FormField";
import { Textarea } from "../../../DiscoverNew/Form/Textarea/Textarea";
import { Formatter } from "../../../DiscoverNew/util/Formatter";
import { modalStyles } from "../../../modals/modalStyles";
import NoteDetail from "../../../note/NoteDetail";

type Props = {
  onSubmit: (data: any) => Promise<any>;
  onClose: () => void;
  candidate: CandidateInterface
}

export const ModalCandidateNotes: React.FC<Props> = ({ onClose, onSubmit, candidate }) => {
  const [values, setValues] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<CandidateNote[]>();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit(values);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchNotes = () => {
    candidateService.fetchNotes(candidate.id).then((notes) => {
      setNotes(notes);
    }).catch(err => {
      console.group("Error fetching notes");
      console.error(err);
      console.groupEnd();
    });
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchCandidateNotes = async () => {
    fetchNotes();
    // @ts-ignore
    window.analytics.track("Candidate Note Viewed", {
      candidate_id : candidate.candidateId,
      state : candidate?.location?.region,
      locality : candidate?.location?.locality,
      is_unlocked : candidate?.isContactInfoViewable,
    });
  };

  const onEditNote = async (editedNoteContent: any) => {
    if (!candidate?.candidateId || !editedNoteContent) return;
    try {
      await api.UpdateCandidateNote(candidate.candidateId, editedNoteContent);
      await fetchCandidateNotes();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
        .modal-dialog {
          max-width: 550px;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>{Formatter.fullName(candidate)} Notes</div>
        <div className={modalStyles.modalFormContainer}>
          <div style={{ maxHeight : 400, overflow : "auto" }}>
            {notes?.map(note => (
              <NoteDetail
                key={JSON.stringify(note)}
                note={note}
                onEditNoteSaved={onEditNote}
                isStaticNote={false}
              />
            ))}
          </div>
          <FormField label={null}>
            <Textarea
              minRows={5}
              value={values.note}
              onChange={(e: any) => setValues({ ...values, note : e.target.value })}
              placeholder="Enter your note here"
            />
          </FormField>
        </div>
        <ModalButtonContainer
          title="Add a Note"
          onClick={handleSubmit}
          isWaiting={loading}
        />
      </div>
    </Modal>
  );
};