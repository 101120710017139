import React from "react";
import { cx } from "emotion";
import { Modal } from "react-bootstrap";
import CloseButton from "../../button/CloseButton";
import { modalStyles } from "../modalStyles";
import { ProjectCardPreview } from "../../project/ProjectCardPreview/ProjectCardPreview";
import { Formatter } from "../../DiscoverNew/util/Formatter";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Dropdown } from "../../DiscoverNew/UI/Dropdown/Dropdown";
import { PermissionEnum, permissionService } from "../../../screens/projects/PermissionService";
import { COLLABORATOR_ROLE_OPTIONS } from "../../../constants/constants";
import { projectService } from "../../../screens/projects/ProjectService";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import CompanyImg from "../../company/CompanyImg";
import { extractInitials } from "../../../screens/companies/Companies";

import styles from "./CollaboratorsModal.module.css";

// @todo what if a user hasn't yet applied an invite? Role?

function CollaboratorsModal({ isOpen, onClose, project, onUpdated }) {
  // const [loading, setLoading] = React.useState(false);
  const [collaborators, setCollaborators] = React.useState([]);
  const notifications = useNotifications();

  async function changeRole(collaborator, role, close) {
    const isConfirmed = window.confirm(
      `Are you sure you want to update project access for ${Formatter.fullName(collaborator)} to ${role.name}?`
    );
    if (!isConfirmed) {
      close();
      return;
    }
    // setLoading(true);
    try {
      await projectService.updateCollaboratorAccess(project.id, collaborator.id, { role: role.id.toLowerCase() });
      fetchCollaborators();
      // setLoading(false);
      onUpdated();
      notifications.showSuccess("Project access has been updated!");
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't update project access");
      // setLoading(false);
    }
    close();
  }

  async function revokeAccess(collaborator, close) {
    const isConfirmed = window.confirm(
      `Are you sure you want to revoke project access from ${Formatter.fullName(collaborator)}?`
    );
    if (!isConfirmed) {
      close();
      return;
    }
    // setLoading(true);
    try {
      await projectService.revokeCollaboratorAccess(project.id, collaborator.id);
      fetchCollaborators();
      // setLoading(false);
      notifications.showSuccess("Project access has been revoked!");
      onUpdated();
      close();
    } catch (err) {
      console.error(err);
      // setLoading(false);
      notifications.showError("Couldn't revoke project access");
      close();
    }
  }

  async function fetchCollaborators() {
    const collaborators = await projectService.fetchCollaborators(project.id);
    setCollaborators(collaborators);
  }

  React.useEffect(() => {
    fetchCollaborators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show={isOpen} onHide={onClose} centered={true}>
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle} style={{ paddingBottom: 15 }}>
          Collaborators
        </div>
        <div className={styles.project}>
          <ProjectCardPreview project={project} />
        </div>
        {collaborators?.length ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>User</th>
                <th>Role</th>
                <th>Added</th>
              </tr>
            </thead>
            <tbody>
              {collaborators.map((collaborator) => {
                const canEditRole = permissionService.can(
                  project,
                  PermissionEnum.projectEditCollaboratorRole,
                  collaborator
                );
                const roleText = Formatter.humanize(collaborator.role);

                return (
                  <tr key={collaborator.id} title={collaborator.email}>
                    <td className={styles.tdName}>
                      <div className={styles.nameHolder}>
                        <CompanyImg
                          company={{ imgUrl: collaborator.imgUrl }}
                          style={{ width: 24, height: 24, fontSize: 11, flexShrink: 0 }}
                          initials={extractInitials([collaborator.firstName, collaborator.lastName].join(" "))}
                        />
                        <span className={styles.name}>{Formatter.fullName(collaborator)}</span>
                      </div>
                    </td>
                    <td>
                      {canEditRole ? (
                        <Dropdown
                          classNames={{
                            content: styles.roleDropdownContent,
                          }}
                          label={
                            <div className={styles.role}>
                              {roleText}
                              <div className={styles.roleIcon}>
                                <Icon name="chevron" />
                              </div>
                            </div>
                          }
                        >
                          {COLLABORATOR_ROLE_OPTIONS.map((role) => {
                            return (
                              <Dropdown.Item
                                className={cx(styles.roleItem, role.id === collaborator.role && styles.selectedRole)}
                                onClick={(close) => changeRole(collaborator, role, close)}
                                key={role.name}
                              >
                                {role.name}
                              </Dropdown.Item>
                            );
                          })}
                          <Dropdown.Divider className={styles.divider} />
                          <Dropdown.Item
                            className={styles.roleItem}
                            style={{ color: "#F83E3E" }}
                            onClick={(close) => revokeAccess(collaborator, close)}
                          >
                            Revoke Access
                          </Dropdown.Item>
                        </Dropdown>
                      ) : (
                        roleText
                      )}
                    </td>
                    <td className={styles.tdAdded}>{Formatter.fullDate(collaborator.createdAt)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>You don't have any collaborators yet</p>
        )}
      </div>
    </Modal>
  );
}

export default CollaboratorsModal;
