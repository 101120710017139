import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import css from "./Link.module.css";
import PropTypes from "prop-types";
import { cx } from "emotion";

export function Link(props) {
  const Component = props.to ? RouterLink : "span";
  const className = cx(props.className, css.link, { [css.gray]: props.color === "gray" });
  const componentProps = {
    className,
    to: props.to,
    target: props.target,
    onClick: props.onClick,
    style: props.style,
  };
  return (
    <Component {...componentProps}>
      {props.leftIcon && <span className={css.leftIcon}>{props.leftIcon}</span>}
      {props.children}
      {props.rightIcon && <span className={css.rightIcon}>{props.rightIcon}</span>}
    </Component>
  );
}

// Link.propTypes = {
//   color: PropTypes.oneOf([undefined, "gray"]),
//   leftIcon: PropTypes.node,
//   rightIcon: PropTypes.node,
//   onClick: PropTypes.func,
//   target: PropTypes.string,
//   to: PropTypes.string,
//   style: PropTypes.object,
// };
