import * as React from 'react';
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Formatter } from "../../../../components/DiscoverNew/util/Formatter";
import Table from "../../../../components/project/Table/Table";
import { userService } from "../../../projects/UserService";

import css from "./AdminUserSubscription.module.css";

type Props = {
  data: any,
  onEditSubscription: any,
  onCancelSubscription: any,
}

export const AdminUserSubscription = (props: Props) => {
  const { data, onEditSubscription, onCancelSubscription } = props;
  return (
    <Table
      noData="No subscriptions"
      actions={{
        edit: {
          render: (v: any) => {
            return <Icon name="pencil" onClick={() => onEditSubscription(v)} className={css.editButton} />;
          },
        },
        // cancel: {
        //   render: (v) => {
        //     return (
        //       <Icon name="cancel" onClick={() => this.onCancelSubscription(v)} className={css.cancelButton} />
        //     );
        //   },
        // },
      }}
      dataObject={{
        data: { data },
      }}
      columns={[
        { dataIndex: "name", title: "Name" },
        { dataIndex: "startDate", title: "Start Date", render: Formatter.fullDate },
        { dataIndex: "endDate", title: "End Date", render: Formatter.fullDate },
        // { dataIndex: "status", title: "Status", render: Formatter.humanize },
        {
          dataIndex: "externalId",
          title: "ChargeBee",
          render: (v: any) => {
            if (!v) {
              return "–";
            }
            return (
              <a href={userService.getSubscriptionUrl(v)} rel="noopener noreferrer" target="_blank">
                {v}
              </a>
            );
          },
        },
      ]}
    />
  )
}