import { isFunction } from "lodash";
import React from "react";
import { cx, css } from "emotion/macro";

import "./Dropdown.css";

/// Element for selecting from a predefined list.
///
/// options: List of strings representing the user's options
/// placeholder: Message to help instruct user
function DropdownOptions({ options, onMouseDown }) {
  const handleOptionClick = (e, option) => {
    if (!isFunction(onMouseDown)) return;
    onMouseDown(e, option);
  };
  return (
    <>
      <div
        className={cx(
          "__dropdown--optionsContainer",
          css`
            width: 100%;
          `
        )}
      >
        <div className="__dropdown--options">
          {options.map((option, key) => {
            return (
              <div
                key={key}
                onMouseDown={(e) => handleOptionClick(e, option)}
                className={cx(
                  "__dropdown--option",
                  css`
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: -0.57px;
                    color: #9b9b9b;
                  `
                )}
              >
                {option?.name}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default DropdownOptions;
