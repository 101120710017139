import * as React from 'react';
import { Button } from "../../../components/DiscoverNew/UI/Button/Button";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import { DropdownFilter } from "../../../components/ui/DropdownFilter/DropdownFilter";

import css from './CompaniesHeader.module.css'

type SortOption = {label: string, value: string, orderBy: string, isAscending: boolean};

type Props = {
  onCreateClick: () => void
  onSortChange: (value: SortOption) => void
  sortOptions: SortOption[]
  sort: string
}

export const CompaniesHeader = (props: Props) => {
  return (
    <header className={css.header}>
      <div style={{ display: 'flex' }}>
        <h1>Companies</h1>
        <Button theme="outline" className={css.addButton} leftIcon={<Icon name="plus" />} onClick={props.onCreateClick}>
          Add
        </Button>
      </div>
      <DropdownFilter
        className={css.dropdown}
        options={props.sortOptions}
        isSelected={(option, value) => option.value === value.value && option.dir === value.dir}
        onChange={props.onSortChange}
        label="Sort By"
        value={props.sort}
      />
    </header>
  )
}