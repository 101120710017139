import React from "react";
import { PageTitle } from "../../components/ui/PageTitle/PageTitle";
import { AdminUserList } from "./AdminUserList/AdminUserList";
import { AdminBlacklistDomainsList } from "./AdminBlacklistDomainsList/AdminBlacklistDomainsList";
import { AdminWhitelistUserEmailList } from "./AdminWhitelistUserEmailList/AdminWhitelistUserEmailList";
import { Tabs } from "../../components/ui/Tabs/Tabs";
import css from "./admin.module.css";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";

function Admin() {
  const history = useHistory();
  return (
    <div className="app">
      <div className={css.container}>
        <PageTitle>Admin</PageTitle>
        <div style={{ margin: "30px 0" }}>
          <Tabs>
            <Tabs.Tab style={{ minWidth: 185}} component={Link} to="/admin/users" active={history.location.pathname === "/admin/users"}>
              Users
            </Tabs.Tab>
            <Tabs.Tab
              style={{ minWidth: 185}}
              component={Link}
              to="/admin/banned-domains"
              active={history.location.pathname === "/admin/banned-domains"}
            >
              Banned Domains
            </Tabs.Tab>
            <Tabs.Tab
              component={Link}
              to="/admin/whitelisted-user-emails"
              active={history.location.pathname === "/admin/whitelisted-user-emails"}
            >
              Email Whitelist
            </Tabs.Tab>
            <Tabs.Tab disabled>Analytics</Tabs.Tab>
          </Tabs>
        </div>
        <Switch>
          <Route path="/admin/users" component={AdminUserList} />
          <Route path="/admin/banned-domains" component={AdminBlacklistDomainsList} />
          <Route path="/admin/whitelisted-user-emails" component={AdminWhitelistUserEmailList} />
          <Redirect to="/admin/users" />
        </Switch>
      </div>
    </div>
  );
}

export default Admin;
