import { useRef } from "react";
import * as React from 'react';
import { UserImage } from "../../../../components/ui/ProfileImage/UserImage";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import CropComponent from "../../../../cropper/Cropper";
import { UserInterface } from "../../../../types";

import css from './EditableUserImage.module.css';

type Props = {
  style?: React.CSSProperties
  data: UserInterface
  onChange: (blob: Blob) => void
}

export const EditableUserImage = (props: Props) => {
  const [selectedImage, setSelectedImage] = React.useState<any>(undefined);
  const imageUploadRef = useRef<HTMLInputElement>(null);

  const onCropComplete = (blob: Blob) => {
    if (blob) {
      props.onChange(blob);
    }
    setSelectedImage(undefined);
    imageUploadRef!.current!.value = '';
  }

  const onFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const selectedImage = URL.createObjectURL(file);
      setSelectedImage(selectedImage);
      imageUploadRef!.current!.value = '';
    }
  }

  return (
    <div>
      <div className={css.imageHolder}>
        <UserImage onClick={() => imageUploadRef.current?.click()} data={props.data} className={css.image} />
        <div className={css.imageOverlay}><Icon name='camera' /></div>
        <input type="file" ref={imageUploadRef} onChange={onFileChange} className={css.input} />
      </div>
      <CropComponent
        key={!!selectedImage}
        isModalOpen={!!selectedImage}
        onCropSelectionComplete={onCropComplete}
        imageSrc={selectedImage}
      />
    </div>
  )
}