import React, { useEffect, useState } from "react";
import { first, flow, isEmpty } from "lodash";
import { map, split } from "lodash/fp";
import { css, cx } from "emotion/macro";

import * as api from "../../api/apiService";
import capitalizeWords from "../../formatters/capitalizeWords";
import CompanyDetails from "../../components/modals/companyDetails";
import { md } from "../../css/mediaQueries";
import NewCompanyModal from "../../components/modals/NewCompanyModal";
import DeleteConfirmatioModal from "../../components/modals/DeleteConfirmationModal";
import { useModalNavigator } from "../../contexts/modalNavigator";
import { useUserData } from "../../contexts/user";
import CompanyRow from "../../components/company/CompanyRow";

import * as globals from "../../constants/constants";

// Order/Sort By icons
import { useAuthController } from "../../contexts/AuthProvider";
import { CompaniesHeader } from "./CompaniesHeader/CompaniesHeader";
import { injectStyleOverride } from "../../components/DiscoverNew/DiscoverNewWrapper";
import { useNotifications } from "../../components/DiscoverNew/contexts/Notification/notifications";

const companyOptions = [
  {
    label : "Company Name (asc)",
    value : globals.COMPANY_NAME_VAL + "_asc",
    orderBy : globals.COMPANY_NAME_VAL,
    isAscending : true,
  },
  {
    label : "Company Name (desc)",
    value : globals.COMPANY_NAME_VAL + "_desc",
    orderBy : globals.COMPANY_NAME_VAL,
    isAscending : false,
  },
  {
    label : "Most Recent (asc)",
    value : globals.MOST_RECENT_VAL + "_asc",
    orderBy : globals.MOST_RECENT_VAL,
    isAscending : true,
  },
  {
    label : "Most Recent (desc)",
    value : globals.MOST_RECENT_VAL + "_desc",
    orderBy : globals.MOST_RECENT_VAL,
    isAscending : false,
  },
];

// @todo move to another helpers or smth
export const extractInitials = (name) => {
  const result = name && flow([split(" "), map(first)])(name);
  return result;
};

export function getCompanyInitials(companyName) {
  if (companyName === "") return;
  const capCompanyName = capitalizeWords(companyName);

  // useMemo will only work outside of this function
  const initials = extractInitials(capCompanyName);
  return initials;
}

function Companies() {
  React.useEffect(() => {
    return injectStyleOverride();
  }, []);
  const { isAuthenticated } = useAuthController();
  const { state, dispatch } = useModalNavigator();
  const [sort, setSort] = useState(companyOptions[0]);
  const notifications = useNotifications();

  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] = useState(false);
  const [isCompanyDetailModalOpen, setIsCompanyDetailModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyInitials, setSelectedCompanyInitials] = useState("");

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    async function fetchCompanies() {
      await listCompanies(sort.orderBy, sort.isAscending);
    }

    fetchCompanies();
  }, [sort.orderBy, sort.isAscending]);

  // Pull in user data for conditional UI rendering
  const {
          state : { user },
        } = useUserData();
  const uiSettings = user.uiSettings;

  // every time the companies array is updated and
  // if the state.currentCompany is not empty, we want to overwrite
  // the current value in order to get the latest company image.
  useEffect(() => {
    if (!isEmpty(state.currentCompany)) {
      const targetCompany = companies.find((c) => c.companyId === state.currentCompany.companyId);
      if (!targetCompany) return;
      dispatch({ type : "SET_CURRENT_COMPANY", payload : { ...targetCompany } });
    }

    // despite using other dependencies within this useEffect (state.currentCompany, dispatch), we only want to perform the
    // side effect when the companies list has updated. This occurs on mount and when the
    // company edit changes are saved in companyDetails.js. Thus, we must include the eslint line
    // to avoid warnings.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const listCompanies = async (orderBy, isAscending) => {
    try {
      const { data } = await api.GetCompanies(orderBy, isAscending);
      if (data) {
        setCompanies(data);
      }
    } catch (e) {
      console.error("could not fetch companies", e);
    }
  };

  const openCreateCompanyModal = () => {
    setIsCreateCompanyModalOpen(!isCreateCompanyModalOpen);
  };

  const toggleCreateModalWindow = () => {
    setIsCreateCompanyModalOpen(!isCreateCompanyModalOpen);
  };

  // Need to figure out a way to get the correct project information
  const openCompanyDetailModal = (company) => {
    const initials = getCompanyInitials(company?.name);
    dispatch({ type : "SET_CURRENT_COMPANY", payload : { ...company } });

    setSelectedCompany(company);
    setSelectedCompanyInitials(initials);
    setIsCompanyDetailModalOpen(!isCompanyDetailModalOpen);

    window.analytics.track("Company Viewed", {
      company_id : company.companyId,
      company_name : company.name,
    });
  };

  const toggleViewModalWindow = () => {
    setIsCompanyDetailModalOpen(!isCompanyDetailModalOpen);
  };

  const buildTotalCountString = (total, singular = "", plural = "") => {
    if (!total) return "";
    if (total === 1) return `${total} ${singular}`;
    return `${total} ${plural}`;
  };

  const onCreateCompanyClose = async () => {
    refreshCompanies();
    toggleCreateModalWindow();
  };

  const handleCompanyDelete = (e, company) => {
    e.stopPropagation();
    setSelectedCompany(company);
    setIsDeleteConfirmationModalOpen(!isDeleteConfirmationModalOpen);
  };

  const toggleDeleteConfirmationModal = (company) => {
    setIsDeleteConfirmationModalOpen(!isDeleteConfirmationModalOpen);
  };

  const deleteCompany = async () => {
    if (!selectedCompany || selectedCompany?.companyId === "") return;
    toggleDeleteConfirmationModal();
    setSelectedCompany(null);
    try {
      await api.DeleteCompany(selectedCompany.companyId);
      listCompanies(getOrderByValue(), sort.isAscending);
      notifications.showSuccess("The company has been deleted!");
    } catch (e) {
    }

    window.analytics.track("Company Deleted", {
      company_id : selectedCompany.companyId,
      company_name : selectedCompany.name,
    });
  };

  // Called when saving company detail changes in Company Details Modal.
  // In order to preserve current orderBy and sort.isAscending values, we
  // must wrap the listCompanies call in this callback.
  const onCompanyDetailsSaved = () => {
    listCompanies(getOrderByValue(), sort.isAscending);
  };

  const refreshCompanies = async () => {
    listCompanies(getOrderByValue(), sort.isAscending);

    // When a company is created with a website url, the company image is
    // asynchronously retrieved and stored. Retrieve the projects after
    // allowing time for the company image to be stored.
    setTimeout(() => listCompanies(getOrderByValue(), sort.isAscending), 4000);
  };

  const getOrderByValue = () => sort.orderBy;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <CompaniesHeader
        onCreateClick={openCreateCompanyModal}
        sort={sort}
        onSortChange={setSort}
        sortOptions={companyOptions}
      />
      <div
        hidden={!companies?.length}
        className="row"
        style={{ margin : "initial", minHeight : "calc(100vh - 100px)", height : "100vh", alignItems : "flex-start" }}
      >
        <div
          className={css`
            display: flex;
            flex-flow: row wrap;
            margin-left: 0px;
            margin-right: 0px;
            max-height: calc(100% - 104px);
            width: 100%;
            overflow-y: auto;

            ${md} {
              padding: initial;
            }
          `}
        >
          {companies?.length > 0 &&
            companies.map((company, index) => {
              return (
                <CompanyRow
                  uiSettings={uiSettings}
                  key={index}
                  index={index}
                  company={company}
                  openCompanyDetailModal={openCompanyDetailModal}
                  buildTotalCountString={buildTotalCountString}
                  getCompanyInitials={getCompanyInitials}
                  refreshCompanies={refreshCompanies}
                  handleDelete={handleCompanyDelete}
                />
              );
            })}
        </div>
      </div>

      <div
        hidden={!companies?.length ? false : true}
        className={cx(
          "row",
          css`
            display: flex;
            margin: 0 !important;
            min-height: calc(100vh - 156px);
          `,
        )}
      >
        <div className={cx("col-12", "text-center", "mt-5", "nomargin", "nopadding")}>
          <p>Click Add Company to get started.</p>
        </div>
      </div>

      {/* Create Company Modal */}
      {isCreateCompanyModalOpen && (
        <NewCompanyModal
          isCreateCompanyModalOpen={isCreateCompanyModalOpen}
          toggleCreateModalWindow={toggleCreateModalWindow}
          close={onCreateCompanyClose}
        />
      )}

      {/* Company Details Modal Window */}
      {isCompanyDetailModalOpen && (
        <CompanyDetails
          uiSettings={uiSettings}
          toggleModalWindow={toggleViewModalWindow}
          isOpen={isCompanyDetailModalOpen}
          // currentCompany={selectedCompany}
          currentCompanyInitials={selectedCompanyInitials}
          onCompanyDetailsSaved={onCompanyDetailsSaved}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmatioModal
          isOpen={isDeleteConfirmationModalOpen}
          onConfirm={deleteCompany}
          onCancel={toggleDeleteConfirmationModal}
          header="Delete Company"
          objectTitle={selectedCompany?.name}
        />
      )}
    </div>
  );
}

export default Companies;
