import React, { useEffect, useState } from "react";
import { ModalBody } from "reactstrap";
import { flow, first } from "lodash";
import { map, split } from "lodash/fp";
import { css, cx } from "emotion/macro";

import * as api from "../../api/apiService";
import CandidateInitials from "../candidate/CandidateInitials";
import ModalNavigator from "./ModalNavigator";
import { ViewProjectModalBody } from "./projectDetails";
import CompanyImg from "../company/CompanyImg";
import transition from "../../css/transitions";
import generateInitials from "../../formatters/generateInitials";
import { useModalNavigator } from "../../contexts/modalNavigator";
import * as globals from "../../constants/constants";

import { xs, sm, md, lg } from "../../css/mediaQueries";
import CompanyEdit from "../company/CompanyEdit";

const extractInitials = (name) => {
  const result = name && flow([split(" "), map(first)])(name);
  return result;
};

const buildTotalCountString = (total, singular = "", plural = "") => {
  if (!total) return "";
  if (total === 1) return `${total} ${singular}`;
  return `${total} ${plural}`;
};

const Project = ({ idx, project, onClick, uiSettings }) => {
  const { name, candidateTotal } = project;

  const getProjectInitials = () => {
    let initials = extractInitials(name);
    return initials;
  };

  return (
    <>
      <div
        onClick={onClick}
        className={cx(
          "row",
          "candidate-list-item-container",
          styles.listItemContainer,
          css`
            display: flex;
            flex-flow: row;
            &:hover [data-project-title] {
              color: #208bfe;
            }
          `,
          {
            [css`
              cursor: pointer;
            `]: onClick,
          }
        )}
        key={idx}
      >
        <div
          className={cx(
            css`
              margin-right: 14px;
            `
          )}
        >
          <CandidateInitials initials={getProjectInitials()} />
        </div>
        {/* Project Details */}
        <div className={styles.listItemDetailContainer}>
          <span
            data-project-title
            className={cx(
              "standard-theme-text",
              styles.lineOne,
              styles.listItemTruncateText,
              css`
                ${transition({ type: "color" })}
              `
            )}
          >
            {name}
          </span>
          <span className={cx("standard-theme-text", styles.lineTwo)}>
            {buildTotalCountString(candidateTotal, uiSettings?.mappings?.candidate, uiSettings?.mappings?.candidates)}
          </span>
        </div>
      </div>
    </>
  );
};

export const CompanyDetailModalBody = ({ push, pop, currentCompanyInitials, onCompanyDetailsSaved, uiSettings }) => {
  const {
    state: { currentCompany },
    dispatch,
  } = useModalNavigator();

  // Need to fetch company details once component loads
  const [projects, setProjects] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const { companyId } = currentCompany;
    const fetchCompanyProjects = async () => {
      try {
        const { data } = await api.GetCompanyProjects(companyId);
        setProjects(data);
      } catch (e) {
        console.error("could not fetch project candidates");
      }
    };

    if (currentCompany) {
      fetchCompanyProjects();
    }
  }, [currentCompany]);

  const handleCompanyProjectClick = (project) => {
    dispatch({ type: "SET_CURRENT_PROJECT", payload: { ...project } });

    push(
      currentCompany?.name,
      (push, pop) => (
        <ViewProjectModalBody
          uiSettings={uiSettings}
          currentProjectInitials={generateInitials(currentCompany?.name?.split(" "))}
          push={push}
          pop={pop}
        />
      ),
      globals.PROJECT_MODAL_NAME
    );
  };

  const handleCompanyEditSave = async (company) => {
    if (!currentCompany?.companyId || !company) return;
    toggleEditMode();

    // We have not updated the company details
    if (currentCompany?.name === company?.name && currentCompany?.website === company?.website) {
      return;
    }

    await api.UpdateCompany(company);

    currentCompany.name = company?.name;
    // only reassign the website if it has changed
    if (currentCompany?.website !== company?.website) {
      currentCompany.website = company.website;
    }

    dispatch({ type: "SET_CURRENT_COMPANY", payload: { ...currentCompany } });
    // This will update the company list in the parent container
    await onCompanyDetailsSaved();
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <ModalBody
      className={css`
        padding: 29px 45px !important;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-flow: row;
          ${md} {
            flex-flow: column;
          }
        `}
      >
        <div
          className={cx(
            "project-body project-detail-modal-body",
            css`
              margin-top: -5px;
              margin-left: 0px;
              margin-right: 0px;
              width: 320px;
              min-width: 320px;
              ${md} {
                justify-content: center;
                width: 100%;
                min-width: initial;
              }
            `
          )}
        >
          {/* TODO: extract into separate component */}
          <CompanyImg
            company={currentCompany}
            initials={generateInitials(currentCompany?.name?.split(" "))}
            style={{ width: "110px", height: "110px", flexShrink: 0 }}
          />
          {!isEditMode && (
            <>
              <div className={cx(styles.companyName, styles.truncateText)}>{currentCompany?.name}</div>
              <span className={cx(styles.companyProjectCount)}>
                {buildTotalCountString(
                  currentCompany?.projectTotal,
                  uiSettings?.mappings?.project,
                  uiSettings?.mappings?.projects
                )}
              </span>
              <span className={cx(styles.companyCandidateCount, "candidates-text")}>
                {buildTotalCountString(
                  currentCompany?.candidateTotal,
                  uiSettings?.mappings?.candidate,
                  uiSettings?.mappings?.candidates
                )}
              </span>
            </>
          )}
          {/* Edit Button */}
          {!isEditMode && (
            <div className={cx("standard-theme-text", styles.editButtonContainer)}>
              <span
                onClick={toggleEditMode}
                className={cx(
                  css`
                    font-family: Montserrat-Medium !important;
                    cursor: pointer;
                  `
                )}
              >
                Edit
              </span>
            </div>
          )}
          {/* Project Edit Fields */}
          {isEditMode && (
            <CompanyEdit onSave={handleCompanyEditSave} onCancel={toggleEditMode} company={currentCompany} />
          )}
        </div>
        <div
          className={cx(
            "candidate-list-container",
            css`
              margin-left: 25px;
              width: calc(100% - 320px);
              ${md} {
                margin-left: initial;
                width: 100%;
              }
            `
          )}
        >
          {/* Project Row */}
          <header className={cx("list-header", styles.listHeader)}>{uiSettings?.mappings?.projects}</header>
          {projects.map((project, idx) => (
            <Project
              uiSettings={uiSettings}
              key={JSON.stringify(project)}
              idx={idx}
              project={project}
              onClick={() => handleCompanyProjectClick(project)}
            />
          ))}
          {projects.length === 0 && <div className={styles.emptyListMessage}>No {uiSettings?.mappings?.projects}</div>}
        </div>
      </div>
    </ModalBody>
  );
};

const CompanyDetailModal = ({
  isOpen,
  currentCompanyInitials,
  toggleModalWindow,
  onCompanyDetailsSaved,
  uiSettings,
}) => {
  return (
    <ModalNavigator
      isOpen={isOpen}
      toggle={toggleModalWindow}
      centered={true}
      className="project-detail-modal"
      id="company-detail-modal"
      initialView={globals.COMPANY_MODAL_NAME}
    >
      {(push, pop) => (
        <CompanyDetailModalBody
          uiSettings={uiSettings}
          push={push}
          pop={pop}
          currentCompanyInitials={currentCompanyInitials}
          onCompanyDetailsSaved={onCompanyDetailsSaved}
        />
      )}
    </ModalNavigator>
  );
};

const styles = {
  editButtonContainer: css`
    font-size: 14px;
    letter-spacing: -0.49px;
    text-align: center;
    color: #208bfe;
    padding: initial !important;
    margin: 10px 0px;
    ${sm} {
      text-align: center;
      padding: initial !important;
    }
  `,
  companyName: css`
    width: 100%;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 5px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.31px;
    color: #000000;
  `,
  companyProjectCount: css`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.65px;
    text-align: center;
    color: #373737;
    margin-bottom: 5px;
  `,
  companyCandidateCount: css`
    font-size: 18.7px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.76px;
    text-align: center;
    color: #a8a8a8;
  `,
  truncateText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  listItemTruncateText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${lg} {
      width: 250px;
    }
    ${xs} {
      width: 200px;
      word-break: break-word;
      white-space: initial;
    }
  `,
  listHeader: css`
    font-family: Montserrat-Bold !important;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.02px;
    color: #373737;
    margin-bottom: 22px;
    padding-left: 3px;
  `,
  listItemContainer: css`
    padding: 0px 10px 0px 17px;
    margin-bottom: 46px;
  `,
  listItemDetailContainer: css`
    display: flex;
    flex-flow: column;
    padding-top: 6px;
    width: calc(100% - 95px);
  `,
  lineOne: css`
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -1.02px;
    color: #373737;
    margin-bottom: 3px;
  `,
  lineTwo: css`
    width: 100%;
    font-weight: bold;
    font-size: 13.2px;
    letter-spacing: -0.67px;
    color: #373737;
    margin-bottom: 3px;
  `,
  lineThree: css`
    font-weight: bold;
    font-size: 15.4px;
    letter-spacing: -0.78px;
    color: #a8a8a8;
  `,
  emptyListMessage: css`
    padding-left: 5px;
  `,
};

export default CompanyDetailModal;
