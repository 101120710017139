import React, { useRef, useState } from "react";
import cn from 'classnames';
import { Overlay, Popover } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { css, cx } from "emotion/macro";

import { useUserData } from "../../contexts/user";
import defaultAvatar from "../../assets/images/blank_profile.png";
import { lgU, sm } from "../../css/mediaQueries";

import CandidateInitials from "../../components/candidate/CandidateInitials";
import capitalizeWords from "../../formatters/capitalizeWords";
import { extractInitials } from "../../screens/companies/Companies";
import { Formatter } from "../DiscoverNew/util/Formatter";
import { Tag } from "../DiscoverNew/UI/Tag/Tag";

import cssStyles from "./NavbarUserDropdown.module.css";
import { usePromise } from "../../hooks/usePromise";
import { projectInviteService } from "../../screens/projects/ProjectInviteService";
import { projectService } from "../../screens/projects/ProjectService";
import { useNotifications } from "../DiscoverNew/contexts/Notification/notifications";
import { QUERY_NAME, queryRegistry } from "../../utils/queryRegistry";
import { useAuthController } from "../../contexts/AuthProvider";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { urlTo } from "../DiscoverNew/util/UrlHelper";
import { Badge } from "../ui/Badge/Badge";

const AUTH0_AVATAR_URL = "https://s.gravatar.com/avatar";

const NavbarUserDropdown = () => {
  const notifications = useNotifications();
  const history = useHistory();
  const { logout, user: authUser } = useAuthController();
  const {
    state: { user, userCredits },
  } = useUserData();
  const uiSettings = user.uiSettings;

  const { data: projectInvites, fetchData } = usePromise(projectInviteService.fetchMyIncomingInvites);
  const { data: exportsList, setData : setExportsListData, fetchData: fetchExportsListData } = usePromise(projectService.fetchMyProjectsExports);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleProfileButtonClick = (event) => {
    setIsPopoverOpen(!isPopoverOpen);
    setTarget(event.currentTarget);
  };

  const handleSettingsButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
    history.push("/profile");
  };

  const handleLogoutClick = () => {
    logout({ returnTo: window.location.origin, client_id: process.env.REACT_APP_AUTH0_CLIENT_ID });
  };

  const handleOnPopoverHide = () => {
    if (isPopoverOpen) {
      setIsPopoverOpen(false);
    }
  };

  const getUserInitials = () => {
    let name = Formatter.fullName(user) || user?.email;
    name = capitalizeWords(name);
    return extractInitials(name);
  };

  const onAcceptInvite = async (invite) => {
    const isConfirmed = window.confirm(`Are you sure you want to accept invitation to ${invite.project.name}?`);
    if (!isConfirmed) {
      return;
    }
    try {
      await projectService.acceptInvite(invite.id);
      notifications.showSuccess("Invitation has been accepted!");
      fetchData();
      queryRegistry.fetchQuery(QUERY_NAME.fetchProjects);
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't accept the invitation");
    }
  };

  const onDeclineInvite = async (invite) => {
    const isConfirmed = window.confirm(`Are you sure you want to decline invitation to ${invite.project.name}?`);
    if (!isConfirmed) {
      return;
    }
    try {
      await projectService.declineInvite(invite.id);
      notifications.showSuccess("Invitation has been declined!");
      fetchData();
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't decline the invitation");
    }
  };

  const onDownloadProjectExport = async (project) => {
    setExportsListData(exportsList.filter(item => item.id !== project.id));
    await projectService.markExportAsClaimed(project.id);
    fetchExportsListData();
  }

  const totalNotifications = projectInvites?.length || 0 + exportsList?.length || 0;

  // @ts-ignore
  window.__refetchExportList = fetchExportsListData;

  return (
    <>
      <Overlay
        show={isPopoverOpen}
        target={target}
        placement="bottom-end"
        container={ref.current}
        containerPadding={20}
        rootClose={true}
        onHide={handleOnPopoverHide}
      >
        <Popover placement="left" className={cn(
          cssStyles.popover,
          !!exportsList?.length && cssStyles.hasExportList,
        )}>
          <Popover.Content className={cssStyles.popoverInner}>
            <div className={cssStyles.title}>{Formatter.fullName(user)}</div>
            <div className={cssStyles.subtitle}>{user.email}</div>
            <div className={cssStyles.divider} />
            <div className={cssStyles.creditsCount}>
              {(userCredits || 0).toLocaleString()} {uiSettings?.mappings?.candidates?.toLowerCase() || ""} available
            </div>
            {!!projectInvites?.length && (
              <div className={cssStyles.invitations}>
                {projectInvites.map((item) => {
                  return (
                    <div className={cssStyles.invitationsItem} key={item.id}>
                      <p className={cssStyles.invitationsItemText}>
                        <b>{item.creator.name}</b> invited you to join "<b>{item.project.name}</b>"
                      </p>
                      <div className={cssStyles.invitationsItemControls}>
                        <Tag onClick={() => onAcceptInvite(item)}>Accept</Tag>
                        <Tag onClick={() => onDeclineInvite(item)} theme="red">
                          Decline
                        </Tag>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {!!exportsList?.length && (
              <div className={cssStyles.invitations}>
                {exportsList.slice(0).sort((a, b) => {
                  const dateA = a.finishedAt || a.startedAt;
                  const dateB = b.finishedAt || b.startedAt;
                  return dateB.localeCompare(dateA);
                }).map((item) => {
                  const date =  item.finishedAt || item.startedAt;
                  const dateText = date ? DateTime.fromISO(date).toRelative() : '';
                  return (
                    <div className={cssStyles.invitationsItem} key={item.id}>
                      <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>
                        <p className={cssStyles.invitationsItemText}>
                          Your export for <Link to={urlTo('/projects/:id', { id: item.project.id })}><b>{item.project.name}</b></Link> is {item.downloadLink ? "ready to download" : "being generated"}
                        </p>
                        {!!dateText && <span className={cssStyles.invitationsItemDate}>{dateText}</span>}
                      </div>
                      {!!item.downloadLink && (
                        <div className={cssStyles.invitationsItemControls} style={{ justifyContent: 'space-between'}}>
                          <Tag href={item.downloadLink} target='_blank'>Download</Tag>
                          <span className={cssStyles.invitationsItemDismiss} onClick={() => onDownloadProjectExport(item)}>
                            dismiss
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}


            <div className={cssStyles.list}>
              <a
                href="https://chatterworks.zendesk.com/hc/en-us/sections/17749132761755-How-To"
                className={cssStyles.item}
                target="_blank"
              >
                How To
              </a>
              <a
                className={cssStyles.item}
                href={process.env.REACT_APP_EXTENSION_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Extension
              </a>
              {!!authUser?.isAdmin && (
                <Link className={cssStyles.item} to={"/admin"}>
                  Admin
                </Link>
              )}
              <span className={cssStyles.item} onClick={handleSettingsButtonClick}>
                Settings
              </span>
              <span className={cssStyles.item} onClick={handleLogoutClick}>
                Log Out
              </span>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
      <div onClick={handleProfileButtonClick} ref={ref} style={{ position : "relative" }}>
        {!!totalNotifications && (
          <Badge className={cssStyles.notificationsCounter}>
            {totalNotifications > 99 ? "99+" : totalNotifications}
          </Badge>
        )}
        <div id="profile-popover" className={styles.profilePopoverContainer}>
          {user ? (
            user?.profileImgUrl && !user.profileImgUrl.includes(AUTH0_AVATAR_URL) ? (
              <img
                src={user.profileImgUrl}
                width={"25px"}
                height={"25px"}
                alt="profile"
                className={cx(
                  css`
                    border-radius: 50%;
                  `
                )}
              />
            ) : (
              <CandidateInitials
                initials={getUserInitials()}
                width="25px"
                height="25px"
                fontSize="12px"
                style={{ cursor: "pointer" }}
              />
            )
          ) : (
            <img className={styles.profileAvatar} src={defaultAvatar} alt="" width="25px" height="25px" />
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  profileAvatar: css`
    border-radius: 50%;
    margin-right: 12px;

    ${lgU} {
      margin-right: 0px;
    }
  `,
  profilePopoverContainer: css`
    cursor: pointer;

    ${sm} {
      padding-right: 0.75rem;
    }
  `,
};

export default NavbarUserDropdown;
