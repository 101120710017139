import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import ModalButtonContainer from "../button/ModalButtonContainer";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";
import { Select } from "../DiscoverNew/Form/Select/Select";
import * as api from "../../api/apiService";
import { Link } from "../DiscoverNew/UI/Link/Link";
import { Icon } from "../DiscoverNew/UI/IconComponent/Icon";
import NewProjectModal from "./NewProjectModal";
import { useUserData } from "../../contexts/user";
import { Alert } from "../ui/Alert/Alert";

function AddCandidatesToProjectModal({ isOpen, onClose, onSubmit, candidatesCount }) {
  const isUnmounted = useRef(false);
  const [project, setProject] = useState(undefined);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [companyWithProjectOptions, setCompanyWithProjectOptions] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);

  const {
    state: { user },
  } = useUserData();
  const uiSettings = user.uiSettings;

  async function handleSubmit() {
    setIsWaiting(true);
    await onSubmit(project.value);
    if (!isUnmounted.current) {
      setIsWaiting(false);
      onClose();
    }
  }

  function onProjectCreated(project) {
    setProject({
      label: project.name,
      value: project.projectId,
    });
    fetchCompanyOptions().then((options) => {
      setCompanyWithProjectOptions(options);
      setShowCreateProjectModal(false);
    });
  }

  function fetchCompanyOptions() {
    return api.GetAllCompaniesAndProjects().then((r) => {
      return r.data
        .filter((company) => company.projects?.length)
        .map((company) => {
          return {
            label: company.name,
            options: company.projects.map((p) => ({
              value: p.projectId,
              label: p.name,
            })),
          };
        });
    });
  }

  useEffect(() => {
    fetchCompanyOptions().then((options) => {
      if (isUnmounted.current) {
        return;
      }
      setCompanyWithProjectOptions(options);
    });

    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const canAddToProject = user.monthlyUnlockCount > 0 && candidatesCount <= user.monthlyUnlockCount;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>
          Add {uiSettings?.mappings?.candidate}
          {candidatesCount > 1 && "s"} to {uiSettings?.mappings?.project}
        </div>
        <div className={modalStyles.modalFormContainer}>
          <div className={modalStyles.modalInputLabel}>Choose {uiSettings?.mappings?.project}</div>
          <Select onChange={setProject} value={project} options={companyWithProjectOptions} />
          <div style={{ marginTop: 3 }}>
            <Link leftIcon={<Icon name="plus" />} onClick={() => setShowCreateProjectModal(true)}>
              Create {uiSettings?.mappings?.project}
            </Link>
          </div>
        </div>
        {/*{canAddToProject && (*/}
        {/*  <Alert style={{ marginTop: 20 }}>*/}
        {/*    The credits on your ChatterWorks account have run out. This means you are unable to add candidates to projects.<br/><br/>*/}
        {/*    Your credits refresh every 30 days. To resume adding candidates immediately, you can <span className='alert__link'>buy more credits now</span>; à la carte credits never expire, and you can buy them whenever you need extra credits.*/}
        {/*  </Alert>*/}
        {/*)}*/}
        <ModalButtonContainer
          title={`Add ${uiSettings?.mappings?.candidate}${candidatesCount > 1 ? "s" : ""}`}
          onClick={handleSubmit}
          isWaiting={isWaiting}
          // isDisabled={!project && !canAddToProject}
          isDisabled={!project}
        />
      </div>

      {showCreateProjectModal && (
        <NewProjectModal
          uiSettings={uiSettings}
          isCreateModalOpen={showCreateProjectModal}
          toggleCreateModalWindow={() => setShowCreateProjectModal(!showCreateProjectModal)}
          close={onProjectCreated}
        />
      )}
    </Modal>
  );
}

export default AddCandidatesToProjectModal;
