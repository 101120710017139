export type CollaboratorInterface = {
  id: number
  firstName: string
  lastName: string
  role: 'admin'|'editor'|'viewer'
  createdAt: string
  imgUrl: string
}

class CollaboratorService {
  mapToInterface(obj: any): CollaboratorInterface {
    const [firstName, lastName] = (obj.user.name || " ").trim().split(" ");
    return {
      id : obj.user.id,
      role : obj.role?.toUpperCase(),
      createdAt : obj.createdAt,
      imgUrl : obj.user.profileImgUrl,
      firstName,
      lastName,
    };
  }
}

export const collaboratorService = new CollaboratorService();
