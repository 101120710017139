import React, { useEffect, useState } from "react";
import { cx, css } from "emotion/macro";
import { map, flatMap } from "lodash";

import CandidateStageDropdown from "../dropdown/CandidateStageDropdown";

import { getLocalShortDate, getCurrentLocalShortDate } from "../../formatters/calendarFormatter";
import { lg } from "../../css/mediaQueries";
import { Link } from "react-router-dom";

const CandidateStageContainer = ({
  company,
  project,
  updateProjectStage,
  toggleIsDropdownActive,
  isDropdownActive,
}) => {
  const handleOrderByOptionClick = (stageObject) => {
    if (updateProjectStage) {
      updateProjectStage(company?.companyId, project?.projectId, stageObject);
    }
    if (toggleIsDropdownActive) {
      toggleIsDropdownActive();
    }
  };

  return (
    <CandidateStageDropdown
      project={project}
      handleOrderByOptionClick={handleOrderByOptionClick}
      isDropdownActive={isDropdownActive}
    />
  );
};

const ProjectCandidateStageRow = ({ company, updateProjectStage, hasStaticFields }) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const toggleIsDropdownActive = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  return (
    <div
      tabIndex={1}
      onBlur={() => setIsDropdownActive(false)}
      onClick={toggleIsDropdownActive}
      className={cx("project-stage-row", styles.projectStageRow)}
    >
      <div className={cx(styles.gridItem, styles.truncateText)}>
        {company?.project?.projectId ? (
          <Link to={`/projects/${company.project.projectId}`} target='_blank' className={styles.link}>{company.project.name}</Link>
        ) : company?.project?.name || null}
      </div>
      <div className={cx(styles.gridItem, styles.truncateText)}>{company?.companyName}</div>
      <div className={cx("candidate-stage-container", styles.gridItem, styles.candidateStageContainer)}>
        <CandidateStageContainer
          company={company}
          project={company?.project}
          updateProjectStage={updateProjectStage}
          isDropdownActive={isDropdownActive}
          toggleIsDropdownActive={toggleIsDropdownActive}
        />
      </div>
      {!hasStaticFields && (
        <div className={cx("updated-value-cell", styles.gridItem, styles.gridItemUpdatedAt)}>
          {getLocalShortDate(company?.project?.stage?.updatedAt) || getCurrentLocalShortDate()}
        </div>
      )}
    </div>
  );
};

function CandidateProjects({
  candidate: { companies },
  hasStaticFields = false,
  handleViewMore,
  updateProjectStage,
  uiSettings,
}) {
  const [flattenedCompanies, setFlattenedCompanies] = useState([]);
  const [transformedCompaniesLength, setTransformedCompaniesLength] = useState(0);

  useEffect(() => {
    const flattenCompanies = () => {
      let transformedCompanies = flatMap(companies, ({ name: companyName, companyId, projects }) =>
        map(projects, (project) => ({ companyName, companyId, project }))
      );
      setTransformedCompaniesLength(transformedCompanies.length);
      const sliceBy = transformedCompanies?.length < 3 ? transformedCompanies.length : 3;
      const finalCompanies = hasStaticFields ? transformedCompanies.slice(0, sliceBy) : transformedCompanies;
      setFlattenedCompanies(finalCompanies);
    };
    if (companies?.length) {
      flattenCompanies();
    }
  }, [companies, hasStaticFields]);

  if (!companies?.length) {
    return (
      <div style={{ textAlign: "center" }}>
        <span
          className={cx(css`
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.33px;
            text-align: center;
            color: #9b9b9b;
          `)}
        >
          No <span style={{ textTransform: "lowercase" }}>{uiSettings?.mappings?.projects}</span> found.
        </span>
      </div>
    );
  }

  return (
    <div className={cx("candidate-projects-container", styles.candidateProjectsContainer)}>
      <div className={cx("grid-container", styles.gridContainer, { [styles.threeColumnGrid]: hasStaticFields })}>
        {/* Headers */}
        <div className={cx("project-header-container", styles.projectHeaderContainer)}>
          <div className={cx("grid-item-header", styles.gridItemHeader)}>Name</div>
          <div className={cx("grid-item-header", styles.gridItemHeader)}>Company</div>
          <div
            className={cx(
              "grid-item-header",
              "grid-item-status-header",
              styles.gridItemHeader,
              styles.gridItemStatusHeader
            )}
          >
            Status
          </div>
          {!hasStaticFields && (
            <div className={cx("grid-item-header", styles.gridItemHeader, styles.gridItemUpdatedAtHeader)}>Updated</div>
          )}
        </div>

        {/* Body */}
        {flattenedCompanies.map((comp, idx) => (
          <React.Fragment key={idx}>
            <ProjectCandidateStageRow
              company={comp}
              hasStaticFields={hasStaticFields}
              updateProjectStage={updateProjectStage}
            />
          </React.Fragment>
        ))}
      </div>
      {hasStaticFields && transformedCompaniesLength && (
        <div
          className={cx(css`
            display: flex;
            padding-left: 10px;
          `)}
        >
          <span
            onClick={(e) => handleViewMore(e)}
            className={cx(
              "view-more-projects",
              "row-action-button-text",
              css`
                font-family: Montserrat-Medium !important;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.33px;
                color: #4a4a4a;
                cursor: pointer;
              `
            )}
          >
            View {transformedCompaniesLength <= 3 ? "" : transformedCompaniesLength - 3} More
          </span>
        </div>
      )}
    </div>
  );
}

const styles = {
  candidateProjectsContainer: css`
    width: 100%;
    height: 100%;
    .order-by-container {
      width: 100%;
      height: 100%;
      ${lg} {
        min-width: 100px;
      }
    }
    .sort-by-label-span {
      display: none;
    }
    .sort-order-dropdown-options-container {
      width: 100%;
    }
    .sort-order-dropdown {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: #373737;
      width: 95%;
      height: 100%;
      display: flex;
      align-items: center;
      text-align-last: left;
      ${lg} {
        background-position: 90% 61%;
      }
    }
    .sort-order-dropdown-option {
      white-space: break-spaces;
    }
    .__dropdown--value {
      padding-right: 1px;
    }
  `,
  gridContainer: css`
    padding: 0px 10px 0px 10px;
    width: 100%;
  `,
  threeColumnGrid: css`
    padding: 0px 10px 0px 10px;

    .project-stage-row {
      & > div {
        width: 33%;
        font-size: 12px;
      }
    }
  `,
  projectHeaderContainer: css`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-bottom: 3px;
  `,

  gridItemHeader: css`
    font-family: Montserrat-Medium !important;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.33px;
    color: #a8a8a8;
    border-bottom: none;
    width: 25%;
    padding-right: 20px;
  `,
  gridItemStatusHeader: css`
    width: 30%;
  `,
  gridItemUpdatedAtHeader: css`
    width: 20%;
  `,
  projectStageRow: css`
    width: 100%;
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
    border-bottom: solid 1px #d8d8d8;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background-color: #f2f2f2;
      cursor: pointer;

      .sort-order-dropdown {
        background-color: #f2f2f2;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    &:focus {
      outline: none;
    }
  `,
  gridItem: css`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.71px;
    color: #373737;
    padding: 7px 0px;
    width: 25%;
  `,
  candidateStageContainer: css`
    display: flex;
    align-items: center;
    padding: 0px;
    width: 30%;
  `,
  gridItemUpdatedAt: css`
    width: 20%;
  `,
  truncateText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
  `,
  link: css`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }
  `,
};

export default CandidateProjects;
