import React from "react";
import { cx } from "emotion";

import CandidateInitials from "../candidate/CandidateInitials";

const CompanyImg = ({ company, initials, style, className, onClick }) => {
  if (company && company.imgUrl) {
    const defaultStyle = {
      width: "110px",
      height: "110px",
      borderRadius: "50%",
    };

    style = { ...defaultStyle, ...style };

    return (
      <div className={cx("img-container", className)} style={style} onClick={onClick}>
        <img src={company.imgUrl} alt="" style={style} />
      </div>
    );
  }

  return <CandidateInitials initials={initials} style={style} className={className} onClick={onClick} />;
};

export default CompanyImg;
