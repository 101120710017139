import { cx } from "emotion";
import * as React from "react";
import Toast from "react-bootstrap/Toast";
import {
  candidateService,
  CandidateSocialProfile,
  ProjectCandidateInterface,
} from "../../../../../screens/projects/CandidateService";
import { ButtonHTML } from "../../../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Dropdown } from "../../../../DiscoverNew/UI/Dropdown/Dropdown";
import { Icon } from "../../../../DiscoverNew/UI/IconComponent/Icon";
import { copyText, isValidURL } from "../../../../DiscoverNew/util/util";

import contactsCSS from "../CandidateContacts/CandidateContacts.module.css";
import css from "./CandidateSocials.module.css";

type Props = {
  data: ProjectCandidateInterface
  style?: React.CSSProperties
}

export const colorToSocialMap: Record<string, string> = {
  linkedin : "#0A66C2",
  twitter : "#000000",
  facebook : "#1877F2",
  instagram : "#E4405F",
  meetup : "#ED1C40",
  github : "#181717",
  stackoverflow : "#F58025",
  crunchbase : "#0288D1",
  angellist : "#000000",
  flickr : "#0063DC",
  youtube : "#FF0000",
  quora : "#B92B27",
  behance : "#1769FF",
  dribbble : "#EA4C89",
  ello : "#000000",
  foursquare : "#3333FF",
  gitlab : "#FC6D26",
  google : "#4285F4",
  soundcloud : "#FF3300",
  wordpress : "#21759B",
  tiktok : "#000000",
  reddit : "#FF4500",
  whatsapp : "#25D366",
  pinterest : "#BD081C",
  skype : "#00AFF0",
  snapchat : "#FFFC00",
  xing : "#006567",
  indeed : "#003A9B",
  klout : "#E14D9B",
  myspace : "#030303",
  aboutme : "#00A98F",
  gravatar : "#1E8CBE",
  vimeo : "#00ADEF",
}

export const iconToSocialMap: Record<string, string> = {
  linkedin : "linkedin",
  twitter : "twitter",
  facebook : "facebook",
  instagram : "instagram",
  meetup : "meetup",
  github : "github-2",
  stackoverflow : "stackoverflow",
  crunchbase : "crunchbase",
  angellist : "angellist",
  flickr : "flickr",
  youtube : "youtube",
  quora : "quora",
  behance : "behance",
  dribbble : "dribbble",
  ello : "ello",
  foursquare : "foursquare",
  gitlab : "gitlab",
  google : "google",
  soundcloud : "soundcloud",
  wordpress : "wordpress",
  tiktok : "tiktok",
  reddit : "reddit",
  whatsapp : "whatsapp",
  pinterest : "pinterest",
  skype : "skype",
  snapchat : "snapchat",
  xing : "xing",
  indeed : "indeed",
  klout : "klout",
  myspace : "myspace",
  aboutme : "aboutme",
  gravatar : "gravatar",
  vimeo : "vimeo",
};

export const CandidateSocials: React.FC<Props> = props => {
  const [openedToastId, setOpenedToastId] = React.useState('');
  const groupedData = props.data.socialProfiles.reduce((acc, item) => {
    if (!acc[item.site]) {
      acc[item.site] = [];
    }
    acc[item.site].push(item);
    return acc;
  }, {} as Record<string, CandidateSocialProfile[]>);

  const onCopy = (e: any, text: string) => {
    e.stopPropagation();
    copyText(text);
    setOpenedToastId(text);
  };

  return (
    <div className={css.holder} style={props.style}>
      {candidateService.getSortedSocialMedia(props.data.socialProfiles || [])
        .filter((item, i) => props.data.socialProfiles.findIndex(p => p.site === item.site) === i)
        .filter(isValidURL)
        .map((item, i) => {
          if (item.site === "plus") {
            return null;
          }

          const iconName = iconToSocialMap[item.site];
          const info = groupedData[item.site];

          if (info.length > 1) {
            return (
              <Dropdown
                style={{ marginLeft : 32, marginBottom : 24 }}
                classNames={{ content : contactsCSS.dropdownNarrow }}
                label={
                  <ButtonHTML style={{ margin : 0 }} className={css.item}>
                    <div className={css.itemCount}>{info.length}</div>
                    <Icon name={iconName} />
                  </ButtonHTML>
                }>
                {info.map((item, i) => {
                  return (
                    <div className={contactsCSS.dropdownItem} key={item.url + item.site}>
                      <a href={item.url} target="_blank">{item.username}</a>
                      <div className={contactsCSS.dropdownItemActions}>
                        <ButtonHTML onClick={e => onCopy(e, item.url)}><Icon name='copy' /></ButtonHTML>
                        <div className={cx("contact-toast-container", contactsCSS.toastContainer)}>
                          <Toast
                            onClose={() => setOpenedToastId('')}
                            show={openedToastId === item.url}
                            delay={500}
                            autohide
                            className="clipboard-success-toast-container"
                          >
                            <Toast.Body className="clipboard-success-toast-content">Copied!</Toast.Body>
                          </Toast>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Dropdown>
            );
          }

          return <ButtonHTML key={i} className={css.item}>
            <a href={item.url} target="_blank">
              <Icon name={iconName} />
            </a>
          </ButtonHTML>;
        })}
    </div>
  );
};