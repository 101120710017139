import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import CustomRouter from "../../components/CustomRouter/CustomRouter";
import { injectStyleOverride } from "../../components/DiscoverNew/DiscoverNewWrapper";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import { ProfileStageFlow } from "./ProfileStageFlow/ProfileStageFlow";
import { ProfileStage } from "./ProfileStageFlow/ProfileStage";
import { ProfileIntegrations } from "./ProfileIntegrations/ProfileIntegrations";

const ROUTES = [
  {
    path: "/profile/account-details",
    Component: ProfileDetails,
  },
  {
    path: "/profile/interview-process",
    Component: ProfileStageFlow,
    exact: true,
  },
  {
    path: "/profile/integrations",
    Component: ProfileIntegrations,
    exact: true,
  },
  {
    path: "/profile/interview-process/:id",
    Component: ProfileStage,
  },
  // {
  //   path: "/profile/billing/:tab?",
  //   Component: billing,
  //   routes: [
  //     {
  //       path: "/profile/billing/plans-payment",
  //       Component: PlansPayment,
  //       exact: true,
  //     },
  //     {
  //       path: "/profile/billing/subscriptions",
  //       Component: Subscriptions,
  //       exact: true,
  //     },
  //     {
  //       path: "/profile/billing/billing-history",
  //       Component: BillingHistory,
  //       exact: true,
  //     },
  //     {
  //       path: "/profile/billing/plans-payment",
  //       type: "redirect",
  //     },
  //   ],
  // },
  {
    path: "/profile/account-details",
    type: "redirect",
  },
];

const MENU_ITEMS = [
  {
    path: "/profile/account-details",
    label: "Account details",
  },
  {
    path: "/profile/interview-process",
    label: "Interview Process",
  },
  // {
  //   path: "/profile/account-security",
  //   label: "Account security",
  // },
  // {
  //   path: "/profile/team-management",
  //   label: "Team management",
  // },
  // {
  //   path: "/profile/billing",
  //   label: "Billing",
  // },
  // {
  //   path: "/profile/settings",
  //   label: "Settings",
  // },
  {
    path: "/profile/integrations",
    label: "Integrations",
  },
];


const Profile = () => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);
  return (
    <Layout title="Your account" menuItems={MENU_ITEMS}>
      <CustomRouter routes={ROUTES} />
    </Layout>
  );
};

export default Profile;
