import * as api from "../../api/apiService";

class BlacklistDomainService {
  fetchAll = async (args) => {
    const res = await api.AdminGetBlacklistDomains(args);
    return {
      nodes: res.data.items,
      pageInfo: {
        pageCount: Math.max(Math.ceil(res.data.count / args.limit), 1),
        total: res.data.count,
      },
    };
  };

  add = async (data) => {
    const res = await api.AdminAddBlacklistDomain(data);
    return res.data || null;
  };

  update = async (id, data) => {
    const res = await api.AdminUpdateBlacklistDomain({
      ...data,
      id,
    });
    return res.data || null;
  };

  enable = async (id, value) => {
    const res = await api.AdminEnableBlacklistDomain({
      id,
      value,
    });
    return res.data || null;
  };
}

export const blacklistDomainService = new BlacklistDomainService();
