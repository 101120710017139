import { HTMLProps } from "react";
import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import inputCSS from "../Input/Input.module.css";
import { InputClearButton } from "../Input/Input";
import { cx } from "emotion";
import PropTypes from "prop-types";

import css from "./Textarea.module.css";

type Props = any

export function Textarea(props: Props) {
  const { onChange, value, defaultValue, placeholder } = props;
  const inputProps: any = {};

  if (onChange) {
    inputProps.onChange = onChange;
    inputProps.value = value || "";
  } else {
    inputProps.defaultValue = defaultValue;
  }

  return (
    <div
      className={cx(inputCSS.holder, props.className, {
        [inputCSS.hasRightIcon]: !!props.rightIcon,
        [inputCSS.clearable]: !!props.clearable && !!props.value,
      })}
    >
      {!!props.rightIcon && <div className={inputCSS.rightIcon}>{props.rightIcon}</div>}
      <TextareaAutosize {...inputProps} placeholder={placeholder} minRows={props.minRows} maxRows={props.maxRows || 5} className={css.textarea} />
      <InputClearButton onClick={() => props.onChange({ target: { value: "" } })} />
    </div>
  );
}

Textarea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
