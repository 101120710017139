import * as React from "react";
import { StageFlowInterface } from "../../../../../screens/profile/ProfileStageFlow/StageFlowService";
import {
  candidateService,
  ProjectCandidateInterface,
  ProjectInterface,
} from "../../../../../screens/projects/CandidateService";
import { PermissionEnum, permissionService } from "../../../../../screens/projects/PermissionService";
import { projectService } from "../../../../../screens/projects/ProjectService";
import { ContextMenuDropdown } from "../../../../DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";

import css from './CandidateContextMenu.module.css'

type Props = {
  candidate: ProjectCandidateInterface
  project: ProjectInterface
  onRemove: () => void
  onChangeStage: (stageId: number) => void
  onArchive: () => void
  onUnarchive: () => void
}

export const CandidateContextMenu: React.FC<Props> = props => {
  const [stageFlow, setStageFlow] = React.useState<StageFlowInterface | undefined>(undefined);

  const canArchive = permissionService.can(props.project, PermissionEnum.projectArchiveCandidate)
  const canUpdateStage = permissionService.can(props.project, PermissionEnum.projectUpdateCandidateStage)
  const canRemoveFromProject = permissionService.can(props.project, PermissionEnum.projectRemoveCandidate)

  React.useEffect(() => {
    if (!canUpdateStage) {
      return;
    }
    projectService.fetchProjectStageFlow(props.project.projectId).then(setStageFlow);
  }, [props.project.projectId, canUpdateStage])

  const onExport = (closeDropdown: () => void) => {
    candidateService.downloadPDF(props.candidate);
    closeDropdown();
  }

  return (
    <ContextMenuDropdown>
      {/*<Dropdown.Item className={css.item}>*/}
      {/*  <Icon className={css.icon} name="person" /> Go to profile*/}
      {/*</Dropdown.Item>*/}
      <ContextMenuDropdown.Item icon="fa-download" onClick={onExport}>
        Export
      </ContextMenuDropdown.Item>
      {canUpdateStage && !!stageFlow && (
        <ContextMenuDropdown.Item icon="move" style={{ position: 'relative' }} onClick={() => {}}>
          Move to Stage
          <select className={css.select} onChange={e => props.onChangeStage(parseInt(e.target.value))} value={props.candidate.stage?.stageId}>
            {stageFlow?.stages?.map((item, index) => (
              <option key={index} value={item.stageId}>{item.name}</option>
            ))}
          </select>
        </ContextMenuDropdown.Item>
      )}
      {/*<Dropdown.Item className={css.itemDanger}>*/}
      {/*  <Icon className={css.icon} name="fa-ban" /> Block*/}
      {/*</Dropdown.Item>*/}
      {canArchive && (
        props.candidate.archivedAt ? (
          <ContextMenuDropdown.Item icon="archive" onClick={props.onUnarchive}>
            Unarchive
          </ContextMenuDropdown.Item>
        ) : (
          <ContextMenuDropdown.Item icon="archive" onClick={props.onArchive}>
            Archive
          </ContextMenuDropdown.Item>
        )
      )}
      {canRemoveFromProject && (
        <ContextMenuDropdown.DangerItem icon="fa-trash" onClick={props.onRemove}>
          Remove
        </ContextMenuDropdown.DangerItem>
      )}

    </ContextMenuDropdown>
  )
}