import * as api from "../../api/apiService";

class WhitelistUserEmailService {
  fetchAll = async (args) => {
    const res = await api.AdminGetUserWhitelistEmails(args);
    return {
      nodes: res.data.items,
      pageInfo: {
        pageCount: Math.max(Math.ceil(res.data.count / args.limit), 1),
        total: res.data.count,
      },
    };
  };

  add = async (data) => {
    const res = await api.AdminCreateUserWhitelistEmail(data);
    return res.data || null;
  };

  update = async (id, data) => {
    const res = await api.UpdateUserWhitelistEmail({
      ...data,
      id,
    });
    return res.data || null;
  };

  delete = async (id, value) => {
    const res = await api.AdminDeleteUserWhitelistEmail(id);
    return res.data || null;
  };
}

export const whitelistUserEmailService = new WhitelistUserEmailService();
