import React, { useEffect } from "react";
import { cx, css } from "emotion/macro";

import { xs } from "../css/mediaQueries";

import { useUserData } from "../contexts/user";

import chatterLogo from "../assets/images/chatterworks-logo-blue.svg";

function SuspendedAccount() {
  const {
    state: { user },
  } = useUserData();

  useEffect(() => {
    if (!user?.userId) {
      return;
    }

    window.analytics.track(`Lockout Screen Viewed`, {
      subscription_name: user?.subscriptionName,
      subscription_end_date: user?.subscriptionEndDate,
    });
  }, [user]);
  return (
    <div className={cx(styles.container)}>
      <section className={cx(styles.suspendedMessageContainer)}>
        <header className={cx(styles.headerContainer)}>
          <div className={cx(styles.logoContainer)}>
            <img src={chatterLogo} alt="Chatterworks Logo" className={cx(styles.logo)} />
          </div>
        </header>
        <h3 className={cx(styles.header)}>Your Subscription Has Expired</h3>
        <div className={cx(styles.horizontalDivider)}></div>
        <p className={cx(styles.message)}>
          Your subscription has expired! Please contact{" "}
          <a href="mailto:support@chatterworks.ai" target="_blank" rel="noopener noreferrer">
            support@chatterworks.ai
          </a>{" "}
          to reinstate your account.
        </p>
        <p className={cx(styles.message)}>
          You are still eligible for our early access pricing at $1,000 per year per user for unlimited data/access and exports. 
          The early access pricing is grandfathered in for life as long as an active annual subscription is maintained. 
          This will include all new features/products.
        </p>
      </section>
    </div>
  );
}

const styles = {
  container: css`
    width: 100%;
    height: calc(100vh - 44px);
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  `,
  suspendedMessageContainer: css`
    width: 500px;
    height: 250px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 15px;
    border-radius: 5px;
    background: #ffffff;
    margin-top: -50px;
    ${xs} {
      height: 325px;
    }
  `,
  headerContainer: css`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `,
  logoContainer: css`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `,
  logo: css`
    width: 235px;
    height: 40px;
  `,
  header: css`
    font-family: Montserrat-Bold !important;
    text-align: center;
    font-size: 24px;
  `,
  horizontalDivider: css`
    width: 85%;
    height: 1px;
    background-color: #d8d8d8;
    margin: 0 auto;
  `,
  message: css`
    font-size: 15px;
    text-align: center;
  `,
};

export default SuspendedAccount;
