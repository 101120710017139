import { COLLABORATOR_ROLE } from "../../constants/constants";

export const PermissionEnum = {
  projectInviteCollaborator: "projectInviteCollaborator",
  projectEditCollaboratorRole: "projectEditCollaboratorRole",
  projectEdit: "projectManageCandidates",
  projectUpdateCandidateStage: "projectUpdateCandidateStage",
  projectRemoveCandidate: "projectRemoveCandidate",
  projectArchiveCandidate: "projectArchiveCandidate",
  stageFlowEdit: "stageFlowEdit",
};

const { ADMIN, EDITOR, VIEWER, OWNER } = COLLABORATOR_ROLE;
export const ROLE_LEVEL = {
  [OWNER]: 0,
  [ADMIN]: 1,
  [EDITOR]: 2,
  [VIEWER]: 3,
};

const resolvePermission = {
  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectInviteCollaborator]: (project) => {
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[ADMIN];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectUpdateCandidateStage]: (project) => {
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[ADMIN];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectRemoveCandidate]: (project) => {
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[ADMIN];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectArchiveCandidate]: (project) => {
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[ADMIN];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.stageFlowEdit]: (project) => {
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[OWNER];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectEdit]: (project) => {
    const level = ROLE_LEVEL[project.currentUserRole];
    return level < ROLE_LEVEL[VIEWER];
  },

  /**
   * @param {ProjectInterface} project
   * @todo
   * @param {any} collaborator
   * @returns {boolean}
   */
  [PermissionEnum.projectEditCollaboratorRole]: (project, collaborator) => {
    const level = ROLE_LEVEL[project.currentUserRole];
    const subjectLevel = ROLE_LEVEL[collaborator.role];
    return level < subjectLevel;
  },
};

class PermissionService {
  can(who, what, extra) {
    const resolve = resolvePermission[what];
    if (resolve) {
      try {
        return resolve(who, extra);
      } catch (err) {
        return false;
      }
    }

    return false;
  }
}

export const permissionService = new PermissionService();
