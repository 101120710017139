import * as React from "react";
import { useEffect, useState } from "react";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { userService } from "../../../screens/projects/UserService";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import { ChooseSubscriptionButton } from "../SubscriptionRequired/SubscriptionRequired";

import css from "./SubscriptionVerification.module.css";
import { useUserData } from "../../../contexts/user";
import { SUPPORT_EMAIL } from "../../../constants/constants";

const ERROR_NAME = {
  invalid_params: "invalid_params",
  unknown: "unknown",
};

export const SubscriptionVerification = () => {
  const {
    params: { subscription_id, id },
  } = useQueryParams();
  const { fetchUserAndUsageData } = useUserData();

  const notifications = useNotifications();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!subscription_id || !id) {
      setError(ERROR_NAME.invalid_params);
      setLoading(false);
      return;
    }

    async function processSubscription() {
      try {
        await userService.processSubscription({ subscription_id, operation_id: id });
        notifications.showSuccess("Subscription has been activated!");
        fetchUserAndUsageData();
      } catch (err) {
        setError(ERROR_NAME.unknown);
      }
      setLoading(false);
    }

    processSubscription();
  }, []);

  return (
    <div className={css.container}>
      {loading && (
        <>
          <h2 className={css.title}>Processing your subscription...</h2>
          <p className={css.text}>
            Please wait a moment
            <br />
            we&apos;re checking all the details
          </p>
        </>
      )}
      {!!error && (
        <>
          <h2 className={css.title}>Couldn't activate the subscription</h2>
          <p className={css.text}>
            Please try again or
            <br />
            contact with support via{" "}
            <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer">
              {SUPPORT_EMAIL}
            </a>
          </p>
          <ChooseSubscriptionButton />
        </>
      )}
    </div>
  );
};
