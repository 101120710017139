export function omit(object, fields) {
  const clone = { ...object };
  for (const field of fields) {
    if (field in clone) {
      delete clone[field];
    }
  }
  return clone;
}

export function pick(object, fields) {
  const clone = {};
  for (const field of fields) {
    if (object[field] !== undefined) {
      clone[field] = object[field];
    }
  }
  return clone;
}

export function withoutEmptyKeys(object) {
  return Object.keys(object).reduce((acc, key) => {
    if (!object[key]) {
      return acc;
    }
    return {
      ...acc,
      [key]: object[key],
    };
  }, []);
}

export function shortId() {
  return "uiid-" + Math.random().toString(36).slice(-8);
}

export function copyText(text) {
  var el = document.createElement("textarea");
  document.body.appendChild(el);
  el.value = text;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export function replaceSmartQuotes(str) {
  if (!str || !str.trim()) {
    return "";
  }
  return str.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
}


export const isValidURL = (item) => {
  let isValidURL;
  try {
    new URL(item.url);
    isValidURL = true;
  } catch (err) {
    console.log(1)
    isValidURL = false;
  }

  return isValidURL;
}