import { css as emotionCss } from "emotion";
import { usePagination } from "hooks/usePagination";
import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { Input } from "../../../components/DiscoverNew/Form/Input/Input";
import { Button } from "../../../components/DiscoverNew/UI/Button/Button";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Loading } from "../../../components/DiscoverNew/UI/Loading/Loading";
import { withConfirm, WithConfirmProps } from "../../../components/hoc/withConfirm";
import { DropdownFilter } from "../../../components/ui/DropdownFilter/DropdownFilter";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { useAuthController } from "../../../contexts/AuthProvider";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { ModalStageFlow } from "./ModalStageFlow";

import css from "./ProfileStageFlow.module.css";
import {
  StageFlowCreateDTOInterface,
  StageFlowInterface,
  stageFlowService,
  StageFlowUpdateDTOInterface,
} from "./StageFlowService";
import { StageFlowTable } from "./StageFlowTable/StageFlowTable";

type Props = WithConfirmProps & {}

const filterOptions = [
  { value: "all", label: "All" },
  { value: "active", label: "Only Active" },
  { value: "archived", label: "Only Archived" },
];

const ProfileStageFlowCore: React.FC<Props> = props => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);

  const pagination = usePagination();
  const history = useHistory();
  const queryParams = useQueryParams({ archived: filterOptions[1].value, query: '' });
  const [search, setSearch] = React.useState(queryParams.params.query);
  const authController = useAuthController();
  const user = authController.user as any;
  const notifications = useNotifications();

  const [isOpenCreateModal, setIsOpenCreateModal] = React.useState(false);
  const [stageFlows, setStageFlows] = React.useState<StageFlowInterface[]>([]);
  const [stageFlowToEdit, setStageFlowToEdit] = React.useState<StageFlowInterface>();
  const [stageFlowToDuplicate, setStageFlowToDuplicate] = React.useState<StageFlowInterface>();
  const [loading, setLoading] = React.useState(false);

  function fetchData(params = queryParams.params) {
    setLoading(true);
    stageFlowService.fetchAll(user.organizationId, {
      archived: params.archived,
      query: params.query,
      limit: pagination.limit,
      skip: pagination.skip
    })
      .then((stageFlows) => {
        setStageFlows(stageFlows);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    fetchData(queryParams.params);
  }, [queryParams.params.query, queryParams.params.archived, pagination.page, pagination.limit, pagination.skip]);

  const onSearchChange = (value: string) => {
    setSearch(value);
    queryParams.debouncedSetParams({ query: value });
  }

  const onCreate = async (data: StageFlowCreateDTOInterface) => {
    try {
      let id;
      if (!data.copyFromStageFlowId) {
        id = await stageFlowService.create(user.organizationId, data);
      } else {
        id = await stageFlowService.duplicate(user.organizationId, data.copyFromStageFlowId, data);
      }
      notifications.showSuccess("Interview Process has been created!");
      history.push(`/profile/interview-process/${id}`);
      setIsOpenCreateModal(false);
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't create the Interview Process");
      setIsOpenCreateModal(false);
    }
  };

  const onArchive = async (stageFlow: StageFlowInterface) => {
    try {
      // todo preloader
      const isConfirmed = await props.confirm.open({
        title: "Archive Interview Process",
        content: "Are you sure you want to archive this Interview Process?",
        confirmButtonTitle: "Archive",
        className: emotionCss`
          .modal-content {
            width: 500px !important;
          }
        `
      })
      if (!isConfirmed) {
        return;
      }
      await stageFlowService.archive(user.organizationId, stageFlow.stageFlowId);
      notifications.showSuccess("Interview Process has been archived!");
      fetchData();
    } catch (err) {
      console.error(err);
      if ((err as any)?.response?.data?.message.includes('associated')) {
        notifications.showError("Couldn't archive the Interview Process, it is already associated with some projects.");
      } else {
        notifications.showError("Couldn't archive the Interview Process");
      }
    }
  }

  const onUnarchive = async (stageFlow: StageFlowInterface) => {
    try {
      // todo preloader
      const isConfirmed = await props.confirm.open({
        title: "Unarchive Interview Process",
        content: "Are you sure you want to unarchive this Interview Process?",
        confirmButtonTitle: "Unarchive",
        className: emotionCss`
          .modal-content {
            width: 500px !important;
          }
        `
      })
      if (!isConfirmed) {
        return;
      }
      await stageFlowService.unarchive(user.organizationId, stageFlow.stageFlowId);
      notifications.showSuccess("Interview Process has been unarchived!");
      fetchData();
    } catch (err) {
      console.error(err);
      if ((err as any)?.response?.data?.message.includes('associated')) {
        notifications.showError("Couldn't unarchive the Interview Process, it is already associated with some projects.");
      } else {
        notifications.showError("Couldn't unarchive the Interview Process");
      }
    }
  }

  const onEdit = async (data: StageFlowUpdateDTOInterface) => {
    await stageFlowService.update(user.organizationId, stageFlowToEdit!.stageFlowId, data);
    notifications.showSuccess("Interview Process has been updated!");
    fetchData();
    setStageFlowToEdit(undefined);
  }

  const onDuplicate = async (data: StageFlowUpdateDTOInterface) => {
    const id = await stageFlowService.duplicate(user.organizationId, stageFlowToDuplicate!.stageFlowId, data);
    notifications.showSuccess("Interview Process has been duplicated!");
    history.push(`/profile/interview-process/${id}`);
    fetchData();
    setStageFlowToEdit(undefined);
  }

  const onDelete = async (stageFlowId: number) => {
    try {
      // todo preloader
      const isConfirmed = await props.confirm.open({
        title: "Delete Interview Process",
        content: "Are you sure you want to delete this Interview Process?",
        confirmButtonTitle: "Delete",
        destructive: true,
      })
      if (!isConfirmed) {
        return;
      }
      await stageFlowService.delete(user.organizationId, stageFlowId);
      setStageFlows(stageFlows.filter(item => item.stageFlowId !== stageFlowId));
      notifications.showSuccess("Interview Process has been deleted!");
      fetchData();
    } catch (err) {
      console.error(err);
      if ((err as any)?.response?.data?.message.includes('associated')) {
        notifications.showError("Couldn't delete the Interview Process, it is already associated with some projects.");
      } else {
        notifications.showError("Couldn't delete the Interview Process");
      }
    }
  }

  return (
    <div className={css.holder}>
      <header className={css.header}>
        <div>
          <SectionTitle className={css.title} level={2}>Interview Process</SectionTitle>
          <Input
            className={css.search} placeholder="Search by interview process name"
            value={search}
            onChange={(e: any) => onSearchChange(e.target.value)}
          />
          <DropdownFilter
            className={css.filter}
            options={filterOptions}
            isSelected={(option, value) => option.value === value}
            onChange={(v: any) => queryParams.setParams({ archived: v.value })}
            label="Filter"
            value={queryParams.params.archived}
          />
        </div>
        {/* @ts-ignore */}
        <Button leftIcon={<Icon name="plus" />} onClick={() => setIsOpenCreateModal(true)}>New Interview Process</Button>
      </header>
      {loading ? <Loading /> :
        <div className={css.content}>
          <StageFlowTable
            onArchive={onArchive}
            onUnarchive={onUnarchive}
            onEdit={s => setStageFlowToEdit(s)}
            onDuplicate={s => setStageFlowToDuplicate(s)}
            onDelete={onDelete}
            stageFlows={stageFlows}
          />
          {/* <PaginationFullUncontrolled
            className={css.pagination}
            pageCount={3}
          /> */}
        </div>
      }
      {isOpenCreateModal && (
        <ModalStageFlow
          title="New Interview Process"
          submitTitle="Create"
          onSubmit={onCreate}
          createFromExistingFlows={stageFlows}
          isOpen={true}
          onClose={() => setIsOpenCreateModal(false)}
        />
      )}
      {stageFlowToEdit && (
        <ModalStageFlow
          title="Edit Interview Process"
          submitTitle="Save"
          stageFlow={stageFlowToEdit}
          onSubmit={onEdit}
          isOpen={true}
          onClose={() => setStageFlowToEdit(undefined)}
        />
      )}
      {stageFlowToDuplicate && (
        <ModalStageFlow
          title="Duplicate Interview Process"
          submitTitle="Duplicate"
          stageFlow={{
            ...stageFlowToDuplicate || {},
            name: stageFlowToDuplicate?.name + ' (copy)',
          }}
          onSubmit={onDuplicate}
          isOpen={true}
          onClose={() => setStageFlowToDuplicate(undefined)}
        />
      )}
    </div>
  );
};

export const ProfileStageFlow = withConfirm(ProfileStageFlowCore);